import React, { Component } from "react";
import "./CustomModal.css";
import PropTypes from "prop-types";
import "../../styles/type-scale-system.css";
import {
  TERMSANDCONDITIONS,
  ENTITY_RECOGNITION,
  TRANSCRIBE,
  SENTIMENT_ANALYSIS,
} from "../../utils/constants";
import { Modal, Backdrop, Fade } from "@mui/material";
import { setCookie } from "../../helpers/cookie";
import { appConfig } from "../../config";

class CustomModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.props.show,
      accepted: false,
    };

    this.handleModalVisibility = this.handleModalVisibility.bind(this);
    this.handleAcceptingTermsAndConditions =
      this.handleAcceptingTermsAndConditions.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({
        show: this.props.showModal,
      });
    }

    if (prevProps.accepted !== this.props.accepted) {
      this.setState({
        accepted: this.props.accepted,
      });
    }
  }

  handleModalVisibility() {
    this.setState((prevState) => {
      this.props.dispatch({
        type: "UPDATE_SHOW_TERMS_MODAL",
        payload: { show: !prevState.isOpen, navigation: "" },
      });
      return {
        isOpen: !prevState.isOpen,
      };
    });
  }

  handleAcceptingTermsAndConditions() {
    this.setState((prevState) => {
      return {
        accepted: !prevState.accepted,
      };
    });
  }

  handleSettingProductAcceptedTerms(productNavigation) {
    switch (productNavigation) {
      case TRANSCRIBE:
        setCookie(
          "consent_transcribe",
          "true",
          appConfig.VULAVULA_TERMS_COOKIE_EXPIRY
        );
        break;
      case ENTITY_RECOGNITION:
        setCookie(
          "consent_ner",
          "true",
          appConfig.VULAVULA_TERMS_COOKIE_EXPIRY
        );
        break;
      case SENTIMENT_ANALYSIS:
        setCookie(
          "consent_sentimental",
          "true",
          appConfig.VULAVULA_TERMS_COOKIE_EXPIRY
        );
        break;
      default:
        break;
    }

    this.props.dispatch({
      type: "UPDATE_SHOW_TERMS_MODAL",
      payload: { show: false, navigation: "" },
    });
  }

  render() {
    let { modalText, navigate, productNavigation } = this.props || {};
    const { isOpen } = this.state;
    const isProduct =
      productNavigation === ENTITY_RECOGNITION ||
      productNavigation === TRANSCRIBE ||
      productNavigation === SENTIMENT_ANALYSIS;

    return (
      <Modal
        className="custom-modal"
        open={isOpen}
        onClose={this.handleModalVisibility}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={isOpen}>
          <div className="inner-container">
            {/* TITLE */}
            {modalText.heading && (
              <div className="vulavula-card-heading">{modalText.heading}</div>
            )}

            {/* BODY */}
            {modalText.body && (
              <div
                className="vulavula-paragraph"
                style={{ textAlign: "center" }}
              >
                {modalText.body}
              </div>
            )}

            {/* SUB-BODT TEXT */}
            {modalText.checkboxSplit1 &&
              modalText.checkboxSplit2 &&
              modalText.checkboxSplit3 && (
                <div className="accept-container">
                  <div
                    className={`custom-checkbox ${
                      this.state.accepted ? "checked" : ""
                    }`}
                    onClick={this.handleAcceptingTermsAndConditions}
                  >
                    {this.state.accepted && (
                      <div className="tick-mark">&#10003;</div>
                    )}
                  </div>
                  <div className="vulavula-paragraph">
                    {modalText.checkboxSplit1}{" "}
                    <a
                      href={TERMSANDCONDITIONS}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {modalText.checkboxSplit2}
                    </a>{" "}
                    {modalText.checkboxSplit3}
                  </div>
                </div>
              )}

            {/* ACTION BUTTON */}
            {((isProduct && this.state.accepted) || !isProduct) && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="button-primary-red-larger"
                  style={{ height: 30 }}
                  onClick={() => {
                    this.handleSettingProductAcceptedTerms(productNavigation);
                    navigate(productNavigation);
                  }}
                >
                  <div className="button-text">{modalText.buttonText}</div>
                </div>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    );
  }
}

CustomModal.protoTypes = {
  show: PropTypes.bool,
  modalText: PropTypes.any,
  productNavigation: PropTypes.string,
  navigate: PropTypes.func,
  dispatch: PropTypes.func,
};

export default CustomModal;
