import React, { Component } from "react";
import "./CustomForm.css";
import "../../styles/type-scale-system.css";
import ProtoType from "prop-types";

class CustomForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputFields: this.props.inputFields,
      checkboxFields: this.props.checkboxFields,
      primaryButtons: this.props.primaryButtons,
      secondaryButtons: this.props.secondaryButtons,
      conditionalButtons: this.props.conditionalButtons,
      separator: this.props.separator,
      rowInputFields: this.props.rowInputFields,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inputFields !== this.props.inputFields) {
      this.setState({
        inputFields: this.props.inputFields,
      });
    }

    if (prevProps.checkboxFields !== this.props.checkboxFields) {
      this.setState({
        checkboxFields: this.props.checkboxFields,
      });
    }

    if (prevProps.primaryButtons !== this.props.primaryButtons) {
      this.setState({
        primaryButtons: this.props.primaryButtons,
      });
    }

    if (prevProps.secondaryButtons !== this.props.secondaryButtons) {
      this.setState({
        secondaryButtons: this.props.secondaryButtons,
      });
    }

    if (prevProps.conditionalButtons !== this.props.conditionalButtons) {
      this.setState({
        conditionalButtons: this.props.conditionalButtons,
      });
    }

    if (prevProps.separator !== this.props.separator) {
      this.setState({
        separator: this.props.separator,
      });
    }

    if (prevProps.rowInputFields !== this.props.rowInputFields) {
      this.setState({
        rowInputFields: this.props.rowInputFields,
      });
    }
  }

  render() {
    let {
      inputFields = [],
      checkboxFields = [],
      primaryButtons = [],
      secondaryButtons = [],
      conditionalButtons = [],
      separator = true,
      rowInputFields = [],
    } = this.state;

    return (
      <div className="form-container">
        {/* INPUT FIELD */}
        {inputFields.map((inputField, i) => {
          return (
            <div className="input-container" id={i} key={i}>
              <div className="input-inner-container">
                {/* TITLE */}
                {inputField.title && (
                  <div className="card-label">{inputField.title}</div>
                )}

                {/* INPUT FIELD */}
                <input
                  id={i}
                  key={i}
                  className={`standard-input vulavula-paragraph  ${
                    inputField.validate && inputField.valueErrorMsg !== ""
                      ? "error-message-border-color"
                      : ""
                  }`}
                  name={inputField.name}
                  value={inputField.value}
                  autoComplete="false"
                  type={inputField.type}
                  placeholder={inputField.placeholder}
                  onChange={inputField.handleFieldUpdate}
                />
              </div>

              {/* META INFO */}
              {inputField.metaInfo && inputField.valueErrorMsg === "" && (
                <div
                  className={"vulavula-paragraph v-w-full v-text-left v-mt-2"}
                >
                  {inputField.metaInfo}
                </div>
              )}

              {/* ERROR MESSAGE */}
              {inputField.validate && inputField.valueErrorMsg !== "" && (
                <div className="vulavula-paragraph error-message-color v-text-left">
                  {inputField.valueErrorMsg}
                </div>
              )}
            </div>
          );
        })}

        {/* ROW INPUT FIELDS */}
        {rowInputFields.length > 0 && (
          <div className="row-input-outer-container">
            {rowInputFields.map((rowInputField, i) => {
              return (
                <div className="input-container" id={i} key={i}>
                  <div className="input-inner-container">
                    {/* TITLE */}
                    {rowInputField.title && (
                      <div className="card-label">{rowInputField.title}</div>
                    )}

                    {/* INPUT FIELD */}
                    <input
                      id={i}
                      key={i}
                      className={`standard-input vulavula-paragraph  ${
                        rowInputField.validate &&
                        rowInputField.valueErrorMsg !== ""
                          ? "error-message-border-color"
                          : ""
                      }`}
                      name={rowInputField.name}
                      value={rowInputField.value}
                      autoComplete="false"
                      type={rowInputField.type}
                      placeholder={rowInputField.placeholder}
                      onChange={rowInputField.handleFieldUpdate}
                    />
                  </div>

                  {/* META INFO */}
                  {rowInputField.metaInfo &&
                    rowInputField.valueErrorMsg === "" && (
                      <div
                        className={
                          "vulavula-paragraph v-w-full v-text-left v-mt-2"
                        }
                      >
                        {rowInputField.metaInfo}
                      </div>
                    )}

                  {/* ERROR MESSAGE */}
                  {rowInputField.validate &&
                    rowInputField.valueErrorMsg !== "" && (
                      <div className="vulavula-paragraph error-message-color v-text-left">
                        {rowInputField.valueErrorMsg}
                      </div>
                    )}
                </div>
              );
            })}
          </div>
        )}

        {/* CHECKBOX */}
        {checkboxFields.map((checkbox, i) => {
          return (
            <div className="checkbox-container" id={i} key={i}>
              <div
                id={i}
                key={i}
                className={`custom-checkbox ${
                  checkbox.checked ? "checked" : ""
                }`}
                onClick={checkbox.handleCheckboxUpdate}
              >
                {checkbox.checked && <div className="tick-mark">&#10003;</div>}
              </div>

              <div
                className="vulavula-paragraph"
                dangerouslySetInnerHTML={{ __html: checkbox.checkboxText }}
              />
            </div>
          );
        })}

        {/* PRIMARY BUTTON */}
        {primaryButtons.map((primaryBtn, i) => {
          return (
            <div
              id={i}
              key={i}
              className="button-primary-red-larger form-primary-button"
              style={{
                background: primaryBtn.backgroundColor,
              }}
              onClick={primaryBtn.handleButtonAction}
            >
              <div
                className="button-text"
                style={{
                  color: primaryBtn.buttonTextColor,
                }}
              >
                {primaryBtn.text}
              </div>
            </div>
          );
        })}

        {/* CONDITIONAL BUTTONS */}
        {conditionalButtons.map((conditionalButton, i) => {
          return (
            conditionalButton.enabled && (
              <div
                id={`conditional-${i}`}
                key={`conditional-${i}`}
                className="conditional-button v-w-full v-text-center vulavula-paragraph"
              >
                <span className="vulavula-paragraph">
                  {conditionalButton.text}
                </span>
                <span
                  id={`conditional-button-${i}`}
                  onClick={conditionalButton.handleButtonAction}
                  className="button-link v-ml-1 v-font-bold v-cursor-pointer"
                  style={{ color: "#3966D7" }}
                >
                  {conditionalButton.buttonText}
                </span>
              </div>
            )
          );
        })}

        {/* SEPARATOR */}
        {separator && (
          <div
            className="vulavula-paragraph-bold or-divider"
            style={{
              color: "#ccc",
            }}
          >
            OR
          </div>
        )}

        {/* SECONDARY BUTTON */}
        {secondaryButtons.map((secondaryBtn, i) => {
          return (
            secondaryBtn.enabled && (
              <div
                id={i}
                key={i}
                className="button-primary-white-large form-secondary-button"
                onClick={secondaryBtn.handleButtonAction}
              >
                {secondaryBtn.icon && (
                  <img src={secondaryBtn.icon} alt={secondaryBtn.iconName} />
                )}
                <div className="vulavula-paragraph-bold blue-gray-color">
                  {secondaryBtn.text}
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  }
}

CustomForm.protoType = {
  inputFields: ProtoType.array,
  checkboxFields: ProtoType.array,
  primaryButtons: ProtoType.array,
  secondaryButtons: ProtoType.array,
  conditionalButtons: ProtoType.array,
};

export default CustomForm;
