import { appConfig } from "../../config";
import { ACTION_AREA } from "../../utils/constants";
import { vulaApi, handleErrorMessage } from "./apiHelper";

export const getEnableBilling = async (sessionToken, dispatch, attempt) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
      return { enable: false, error: true, fetched: true };
    }

    return vulaApi
      .get(`${appConfig.VULAVULA_BILLING_ENABLE_API_URL}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
          return { enable: false, error: true, fetched: true };
        } 
        return { enable: response.data.enable, error: false, fetched: true };
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        }else if(error.code ==="ERR_NETWORK" && attempt < 3){
          return getEnableBilling(sessionToken, dispatch, attempt++);
        } else {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
        }

        return { enable: false, error: true, fetched: true };
      });
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.BILLING
    );
    return { enable: false, error: true, fetched: true };
  }
};

export const getCustomerCurrentInvoice = async (sessionToken, dispatch) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
      return { data: null, error: true, fetched: true };
    }

    return vulaApi
      .get(`${appConfig.VULAVULA_BILLING_CUSTOMER_LATEST_INVOICE_API_URL}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
          return { data: null, error: true, fetched: true };
        }

        return { data: response.data, error: false, fetched: true };
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
        }

        return { data: null, error: true, fetched: true };
      });
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.BILLING
    );
    return { data: null, error: true, fetched: true };
  }
};

export const getCustomerInvoices = async (sessionToken, dispatch) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
      return { data: [], error: true, fetched: true };
    }

    return vulaApi
      .get(`${appConfig.VULAVULA_BILLING_CUSTOMER_INVOICES_API_URL}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
          return { data: [], error: true, fetched: true };
        }

        return { data: response.data, error: false, fetched: true };
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
        }

        return { data: [], error: true, fetched: true };
      });
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.BILLING
    );
    return { data: [], error: true, fetched: true };
  }
};

export const getCustomerInvoicesCSVData = async (sessionToken, dispatch) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
      return { data: [], error: true, fetched: true };
    }

    return vulaApi
      .get(`${appConfig.VULAVULA_BILLING_CUSTOMER_INVOICE_CSV_DATA_API_URL}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
          return { data: [], error: true, fetched: true };
        }

        return { data: response.data, error: false, fetched: true };
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
        }

        return { data: [], error: true, fetched: true };
      });
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.BILLING
    );
    return { data: [], error: true, fetched: true };
  }
};

export const getCustomerPaymentMethods = async (sessionToken, dispatch) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
      return { data: [], error: true, fetched: true };
    }

    return vulaApi
      .get(`${appConfig.VULAVULA_BILLING_CUSTOMER_PAYMENT_METHODS_API_URL}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
          return { data: [], error: true, fetched: true };
        }

        return { data: response.data, error: false, fetched: true };
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
        }

        return { data: [], error: true, fetched: true };
      });
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.BILLING
    );
    return { data: [], error: true, fetched: true };
  }
};

export const deleteCustomerPaymentMethod = async (
  sessionToken,
  id,
  dispatch
) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
      return false;
    }

    return vulaApi
      .delete(
        `${appConfig.VULAVULA_BILLING_CUSTOMER_PAYMENT_METHODS_API_URL}/${id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
          return false;
        }

        return true;
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
        }

        return false;
      });
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.BILLING
    );
    return false;
  }
};

export const getCustomerSubscriptions = async (sessionToken, dispatch) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
      return { data: [], error: true, fetched: true };
    }

    return vulaApi
      .get(`${appConfig.VULAVULA_BILLING_CUSTOMER_SUBSCRIPTIONS_API_URL}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
          return { data: [], error: true, fetched: true };
        }

        return { data: response.data, error: false, fetched: true };
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
        }

        return { data: [], error: true, fetched: true };
      });
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.BILLING
    );
    return { data: [], error: true, fetched: true };
  }
};

export const getProductPlans = async (sessionToken, dispatch) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
      return { data: null, error: true, fetched: true };
    }

    return vulaApi
      .get(`${appConfig.VULAVULA_BILLING_PLANS_API_URL}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
          return { data: null, error: true, fetched: true };
        }

        return { data: response.data, error: false, fetched: true };
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
        }

        return { data: null, error: true, fetched: true };
      });
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.BILLING
    );
    return { data: null, error: true, fetched: true };
  }
};

export const upsertPaymentMethod = async (sessionToken, data, dispatch) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
      return { data: null, error: true };
    }

    const jsonData = JSON.stringify(data);
    return vulaApi
      .post(
        `${appConfig.VULAVULA_BILLING_CUSTOMER_PAYMENT_METHODS_API_URL}`,
        jsonData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
          return { data: null, error: true };
        }

        return { data: response.data, error: false };
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
        }

        return { data: null, error: true };
      });
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.BILLING
    );
    return { data: null, error: true };
  }
};

export const createSubscription = async (sessionToken, data, dispatch) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
      return { data: null, error: true };
    }

    const jsonData = JSON.stringify(data);
    return vulaApi
      .post(
        `${appConfig.VULAVULA_BILLING_CUSTOMER_SUBSCRIPTIONS_API_URL}`,
        jsonData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
          return { data: null, error: true };
        }

        return { data: response.data, error: false };
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.BILLING);
        }

        return { data: null, error: true };
      });
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.BILLING
    );
    return { data: null, error: true };
  }
};
