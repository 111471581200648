import { appConfig } from "../../config";
import {
  ACTION_AREA
} from "../../utils/constants"
import { vulaApi, handleErrorMessage } from "./apiHelper";
import { getCookie } from "../cookie";


export const submitRating = async (
  responseText,
  rating,
  productType,
  dispatch
) => {
  const upload_id = localStorage.getItem("upload_id");

  const jsonData = JSON.stringify({
    upload_id: upload_id,
    response_text: responseText,
    rating: rating,
    product_type: productType,
  });

  const playgroundSession = localStorage.getItem("playgroundSession");
  let tokenObject = getCookie("requestClientToken");
  let requestClientToken = JSON.parse(tokenObject);

  try {
    await vulaApi
      .post(`${appConfig.VULAVULA_RATING_API_ENDPOINT}`, jsonData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${playgroundSession}`,
          "X-CLIENT-TOKEN": `${requestClientToken.token}`,
        },
      })
      .then((resp) => {
        if (resp.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.UNKNOWN);
          return;
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          handleErrorMessage(
            err.response.data.detail,
            err.response.status,
            dispatch
          );
        } else {
          handleErrorMessage(err.message, 500, dispatch, ACTION_AREA.UNKNOWN);
        }
      });
  } catch (err) {
    handleErrorMessage(err.message, 500, dispatch, ACTION_AREA.UNKNOWN);
  }
};
