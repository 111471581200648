import React, { Component, createRef } from "react";
import "./AudioVisualiser.css";
import PropTypes from "prop-types";

const TIME_OFFSET = 10;
const GRAPH_WIDTH = 2;
class AudioVisualiser extends Component {
  constructor(props) {
    super(props);
    this.canvas = createRef();

    this.drawnBars = 0;
    this.now = Number(0);
    this.graphData = [];
  }

  componentDidUpdate() {
    this.loop();
  }

  componentDidMount() {
    this.loop();
  }

  loop() {
    const canvasRef = this.canvas;
    const context = canvasRef?.current?.getContext("2d");
    const { analyser } = this.props;

    if (!analyser || !context) {
      return;
    }

    context?.clearRect(
      0,
      0,
      canvasRef?.current?.width ?? 1,
      canvasRef?.current?.height ?? 1
    );
    let maxFreq = -Infinity;
    const height = canvasRef?.current?.offsetHeight ?? 1;

    if (Number(performance.now()) > this.now) {
      this.now = performance.now() / TIME_OFFSET;

      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Float32Array(bufferLength);
      analyser.getFloatTimeDomainData(dataArray);
      maxFreq = Math.max(0, ...(dataArray ?? []));

      const freq = Math.max(1, Math.floor(maxFreq * 100));

      const barWidth = 4;
      const totalBars = analyser.frequencyBinCount;
      for (let i = 0; i < this.drawnBars; i++) {
        const startY = height;

        this.graphData.push({
          x: 0,
          y: startY / 2 - freq / 2,
          height: freq,
          width: barWidth,
        });
      }

      if (this.drawnBars < totalBars) {
        this.drawnBars++;
      }
    }

    this.draw();

    requestAnimationFrame(() => this.loop());
  }

  draw() {
    const canvasRef = this.canvas;
    const context = canvasRef?.current?.getContext("2d");
    if (!context || this.graphData.length === 0) {
      return null;
    }

    for (let i = 0; i < this.graphData.length; i++) {
      const bar = this.graphData[i];
      if (!bar) continue;
      context.fillStyle = "#FAC200";
      context.fillRect(bar.x, bar.y, bar.width, bar.height);
      bar.x = bar.x + GRAPH_WIDTH;
    }
  }

  render() {
    return (
      <div
        style={{ display: "flex", maxHeight: "100px", alignItems: "center" }}
      >
        <canvas className="wave-container" ref={this.canvas} width="450px" />
      </div>
    );
  }
}

AudioVisualiser.propTypes = {
  audioData: PropTypes.any,
  analyser: PropTypes.any,
};

export default AudioVisualiser;
