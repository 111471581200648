import PropTypes from "prop-types";

/**
 * A reusable component to display when data is missing or an error needs to be communicated.
 * Allows configuration of buttons through objects passed as props.
 *
 * @param {string} message - The message to display to the user.
 * @param {Object} primaryButton - Configuration for the primary action button.
 * @param {Object} secondaryButton - Optional configuration for a secondary action button.
 */
export const DataMissing = ({
                                message,
                                primaryButton,
                                secondaryButton
                            }) => (
    <div className={'v-align-center v-d-flex v-justify-center v-flex-column v-h-full'}>
        <div style={{padding: '20px', textAlign: 'center'}} className={"v-d-flex v-flex-column v-align-center v-w-70 v-content-space-between v-h-full "}>
            <p className={"vulavula-paragraph v-w-full v-text-left"}>{message}</p>
            <button
                className={"button-primary-red-larger v-w-full button-text"}
                onClick={primaryButton.onAction}
                disabled={primaryButton.disabled}
                style={{
                    margin: "30px 0"
                }}
            >
                {primaryButton.label}
            </button>
            {secondaryButton && (
                <button
                    className={"button-primary-white-large v-w-full blue-gray-color vulavula-paragraph-bold"}
                    onClick={secondaryButton.onAction}
                    disabled={secondaryButton.disabled}
                >
                    {secondaryButton.label}
                </button>
            )}
        </div>
    </div>
)

DataMissing.propTypes = {
    message: PropTypes.string.isRequired,
    primaryButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        onAction: PropTypes.func.isRequired,
        disabled: PropTypes.bool
    }).isRequired,
    secondaryButton: PropTypes.shape({
        label: PropTypes.string,
        onAction: PropTypes.func,
        disabled: PropTypes.bool
    })
};
