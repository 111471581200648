import React from 'react';
import { Outlet } from 'react-router-dom';
import './authLayout.css'
import {AuthBanner} from "./AuthBanner";


export const AuthLayout = () => {
  return (
    <div className="full-width v-h-full v-flex-row v-sm:block v-md:block v-lg:flex v-align-stretch">
        <AuthBanner />
      <div className="auth-forms v-lg:w-50 v-md:w-100 v-sm:w-100 v-xs:w-100 v-pt-18">
        <Outlet />
      </div>
    </div>
  );
};
