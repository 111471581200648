import React, { Component } from "react";
import "./Footer.css";
import "../../styles/type-scale-system.css";
import PropTypes from "prop-types";
import WordmarkWithLogo from "../../icons/wordmark-with-logo.svg";
import { footerDetails } from "../../cms/generalCMS";
import {FORGOT_PASSWORD, RESET_PASSWORD, SOCIALSTYPE} from "../../utils/constants";
import InstagramIcon from "../../icons/instagram-icon.svg";
import LinkedInIcon from "../../icons/linkedIn-icon.svg";
import XTwitterIcon from "../../icons/x-twitter-icon.svg";
import { SIGNIN, SIGNUP } from "../../utils/constants";

class Footer extends Component {
  getSocialImage(socialType) {
    switch (socialType) {
      case SOCIALSTYPE.INSTAGRAM:
        return InstagramIcon;
      case SOCIALSTYPE.LINKEDIN:
        return LinkedInIcon;
      case SOCIALSTYPE.X:
        return XTwitterIcon;

      default:
        return;
    }
  }

  getDisplayBasedOnRoute() {
    const pathName = window.location.pathname;

    if (pathName.includes(SIGNUP) || pathName.includes(SIGNIN) || pathName.includes(FORGOT_PASSWORD) || pathName.includes(RESET_PASSWORD)) {
      return "none";
    }

    return "flex";
  }

  render() {
    let { globalState } = this.props || {};
    const { isMobile } = globalState;

    return (
      <div
        className="footer-section"
        style={{ display: this.getDisplayBasedOnRoute() }}
      >
        <div className="footer-container">
          <div className="content-inner-container">
            {/* FOOTER IMAGE */}
            <div className="footer-image">
              <img
                src={WordmarkWithLogo}
                alt="brand wordmark with logo"
                width={isMobile ? "144px" : "300px"}
                height={isMobile ? "46px" : "100px"}
              />
            </div>

            {/* NAVIGATION LINKS */}
            {!isMobile && (
              <div className="navigation-links">
                {footerDetails.sections.map((section, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        gap: "20px",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        className="vulavula-paragraph-large"
                        style={{ color: "#fff" }}
                      >
                        {section.title}
                      </div>
                      <div className="links">
                        {section.links.map((link, j) => {
                          return (
                            <a
                              className="vulavula-paragraph"
                              style={{
                                color: "#fff",
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                              key={j}
                              href={link.navigation}
                              target={link.navigation !== "/" ? "_blank" : ""}
                              rel="noreferrer"
                            >
                              {link.name}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {/* SOCIALS */}
            <div className="socials-container">
              <div className="contact-us">
                {footerDetails.contactUsSocials.map((social, i) => {
                  return (
                    <a
                      className="social-item"
                      key={i}
                      href={social.navigation}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={this.getSocialImage(social.type)}
                        alt={social.type}
                        width="30px"
                        height="30px"
                      />
                    </a>
                  );
                })}
              </div>
              <div className="vulavula-paragraph" style={{ color: "#fff" }}>
                {footerDetails.contactUsEmail}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  globalState: PropTypes.object,
};

export default Footer;
