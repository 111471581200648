import React, { Component, useEffect } from "react";
import "../../styles/type-scale-system.css";
import {
  sentimentAnalysisPageInfo,
  progessLoaderText,
  ratingModalSentimentAnalysisInfo,
} from "../../cms/generalCMS";
import {
  triggerSentimentAnalysis
} from "../../helpers/api/sentimentAnalysis";
import { handleErrorMessage } from "../../helpers/api/apiHelper"
import { getCookie } from "../../helpers/cookie"
import { SENTIMENT, ACTION_AREA, HOME } from "../../utils/constants";
import ErrorCard from "../../components/errorCard/ErrorCard";
import ProgressBar from "../../components/progressBar/ProgressBar";
import CustomTextAreaCard from "../../components/customTextAreaCard/CustomTextAreaCard";
import PropTypes from "prop-types";

class SentimentAnalysis extends Component {
  constructor(props) {
    super(props);

    let { errorDetails, isMobile } = this.props.globalState;

    this.state = {
      entityInputValue: "",
      encodedResults: "",
      errorMessage: errorDetails.errorMessage,
      failureArea: errorDetails.failureArea,
      processProgress: 0,
      processing: false,
      doneProcessing: false,
      entityChips: [],
      isRating: false,
      isMobile: isMobile,
      cardTitle: sentimentAnalysisPageInfo.cardTitle,
    };

    this.handleInputUpdate = this.handleInputUpdate.bind(this);
    this.handleClearStates = this.handleClearStates.bind(this);
    this.handleSentimentalAnalysis = this.handleSentimentalAnalysis.bind(this);
    this.setIsRating = this.setIsRating.bind(this);
    this.handleUpdateProcessing = this.handleUpdateProcessing.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState !== this.props.globalState) {
      const { errorDetails } = this.props.globalState;
      this.setState({
        errorMessage: errorDetails.errorMessage,
        failureArea: errorDetails.failureArea,
      });
    }
  }

  handleClearStates() {
    this.setState({
      entityInputValue: "",
      encodedResults: "",
      errorMessage: "",
      failureArea: 0,
      processProgress: 0,
      processing: false,
      doneProcessing: false,
      entityChips: [],
      isRating: false,
      cardTitle: sentimentAnalysisPageInfo.cardTitle,
    });

    handleErrorMessage(
      "",
      0,
      this.props.dispatch,
      ACTION_AREA.SENTIMENT_ANALYSIS
    );
  }

  async handleSentimentalAnalysis() {
    const { entityInputValue, processProgress } = this.state;
    const playgroundSession = localStorage.getItem("playgroundSession");
    let tokenObject = getCookie("requestClientToken");
    let requestClientToken = JSON.parse(tokenObject);

    if (
      requestClientToken === null ||
      requestClientToken === "" ||
      requestClientToken === undefined
    ) {
      this.props.dispatch({
        type: "UPDATE_ERROR_MESSAGE",
        payload: {
          errorMessage: `Something went wrong, please try again.`,
          failureArea: ACTION_AREA.SENTIMENT_ANALYSIS,
        },
      });

      this.setState({ processing: false });

      return;
    }

    this.setState({ processing: true });

    const config = {
      onUploadProgress: () => {
        this.setState({
          processing: false,
          doneProcessing: true,
        });
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${playgroundSession}`,
        "X-CLIENT-TOKEN": `${requestClientToken.token}`,
      },
    };

    try {
      const totalSteps = 80;
      let currentStep = 0;

      const simulateProgress = () => {
        const progressInterval = setInterval(() => {
          currentStep += 10;
          if (currentStep >= totalSteps || processProgress === 100) {
            clearInterval(progressInterval);
          } else {
            this.setState({ processProgress: currentStep });
          }
        }, 200);
      };

      simulateProgress();

      const resp = await triggerSentimentAnalysis(
        entityInputValue,
        config,
        this.props.dispatch
      );

      if (resp != null) {
        let markedResult = entityInputValue;

        let chipDetails = [];
        let sentimentsLength = resp.length;
        for (var sentiment of resp.sentiments) {
          let sentimentColor = this.getSentimentColor(sentiment);

          markedResult = this.identifySentiment(
            markedResult,
            sentiment.text,
            sentimentColor.darkColor
          );

          const chipIndex = chipDetails.findIndex(
            (x) => x.entityName === sentiment.labels[0].label
          );
          if (chipIndex !== -1) {
            chipDetails[chipIndex].wordCount += 1;
          } else {
            chipDetails.push({
              entityName: sentiment.labels[0].label,
              wordCount: 1,
              colors: sentimentColor,
            });
          }
        }

        const sentiments = [
          SENTIMENT.POSITIVE,
          SENTIMENT.NEGATIVE,
          SENTIMENT.NEUTRAL,
        ];

        for (const senti of sentiments) {
          const chipIndex = chipDetails.findIndex(
            (x) => x.entityName === senti
          );
          if (chipIndex !== -1) {
            chipDetails[chipIndex].wordCount =
              (
                (chipDetails[chipIndex].wordCount / sentimentsLength) *
                100
              ).toFixed(2) + " %";
          }
        }

        this.setState({
          processProgress: 100,
          processing: false,
          doneProcessing: true,
          encodedResults: markedResult,
          entityChips: chipDetails,
          cardTitle: sentimentAnalysisPageInfo.resultCardTitle,
        });
      }
    } catch (err) {
      handleErrorMessage(
        err.message,
        500,
        this.props.dispatch,
        ACTION_AREA.SENTIMENT_ANALYSIS
      );
    }
  }

  identifySentiment(result, sentiment, color) {
    const styledString = result.replace(
      sentiment,
      `<span style="text-decoration: underline; color: ${color};">$&</span>`
    );

    return styledString;
  }

  getSentimentColor(sentiment) {
    switch (sentiment.labels[0].label) {
      case SENTIMENT.NEUTRAL:
        return { darkColor: "#3053C5", lightColor: "#F4F9F9" };
      case SENTIMENT.POSITIVE:
        return { darkColor: "#5E7615", lightColor: "#FAFDE8" };
      case SENTIMENT.NEGATIVE:
        return { darkColor: "#CC4A2B", lightColor: "#FFF0F2" };
      default: // LOCATION
        return { darkColor: "#838383", lightColor: "#F6F6F6" };
    }
  }

  handleInputUpdate(e) {
    this.setState({ entityInputValue: e.target.value });

    const editableDiv = document.getElementById("results");
    const resultsContainerDiv = document.getElementById("results-container");

    // Dynamically adjust the height based on content
    editableDiv.style.height = "auto"; // Reset height
    resultsContainerDiv.style.height = "auto"; // Reset height
    if (e.target.value !== "") {
      editableDiv.style.height = editableDiv.scrollHeight + "px";
      resultsContainerDiv.style.height = editableDiv.scrollHeight + "px";
    }
  }

  setIsRating() {
    this.setState({ isRating: true });
    this.props.dispatch({
      type: "UPDATE_SHOW_RATE_SERVICE_MODAL",
      payload: { show: true },
    });
  }

  handleUpdateProcessing() {
    this.setState({ processing: true });
  }

  getContent() {
    const {
      entityChips,
      entityInputValue,
      encodedResults,
      processing,
      doneProcessing,
      isRating,
      cardTitle,
    } = this.state;
    let cardContent = {
      title: cardTitle,
      resultChips: entityChips,
      encodedResults: encodedResults,
      entityInputValue: entityInputValue,
      placeholderText: sentimentAnalysisPageInfo.placeholderText,
      processing: processing,
      doneProcessing: doneProcessing,
      buttonText: sentimentAnalysisPageInfo.buttonText,
      tryAgainButtonText: sentimentAnalysisPageInfo.tryAgainButtonText,
      rateUsButtonText: sentimentAnalysisPageInfo.rateUsButtonText,
      isRating: isRating,
      modalInfo: ratingModalSentimentAnalysisInfo,
    };

    return cardContent;
  }

  render() {
    let {
      encodedResults,
      errorMessage,
      failureArea,
      processing,
      processProgress,
      doneProcessing,
      isMobile,
    } = this.state;

    return (
      <div className="entity-container page-background">
        {/* TITLE */}
        <div
          className={
            isMobile
              ? "vulavula-heading-3 text-align-center"
              : "title-container"
          }
        >
          {sentimentAnalysisPageInfo.title}
        </div>

        {/* SUB TITLE */}
        <div
          className="vulavula-paragraph text-align-center"
          dangerouslySetInnerHTML={{
            __html: sentimentAnalysisPageInfo.subtitle,
          }}
        />

        {errorMessage !== "" &&
          failureArea === ACTION_AREA.SENTIMENT_ANALYSIS ? (
          <div className="entity-error-card-container">
            <ErrorCard
              errorMessage={errorMessage}
              handleClearStates={this.handleClearStates}
            />
          </div>
        ) : (!processing && !doneProcessing) ||
          (doneProcessing && encodedResults !== "") ? (
          // ENTITY INPUT
          <CustomTextAreaCard
            dispatch={this.props.dispatch}
            globalState={this.props.globalState}
            cardContent={this.getContent()}
            handleInputUpdate={this.handleInputUpdate}
            handleInputProcess={this.handleSentimentalAnalysis}
            handleClearStates={this.handleClearStates}
            handleUpdateProcessing={this.handleUpdateProcessing}
            setIsRating={this.setIsRating}
          />
        ) : (
          <div className="process-loader-container">
            {/* processing LOADER */}
            <div
              className="vulavula-paragraph-bold flashing-text"
              style={{ color: "#E29900", paddingTop: "30px" }}
            >
              {progessLoaderText}
            </div>
            <div className="progress-bar-container">
              <ProgressBar processProgress={processProgress} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

SentimentAnalysis.propTypes = {
  navigate: PropTypes.func,
  globalState: PropTypes.object,
  dispatch: PropTypes.func,
};

const SentimentAnalysisWithEffect = (props) => {
  const nav = props.navigate;
  useEffect(() => {
    const hasAcceptedTerms = getCookie("consent_sentimental");

    if (!hasAcceptedTerms) {
      nav(HOME);
    }
  }, [nav]);

  return <SentimentAnalysis {...props} />;
};

export default SentimentAnalysisWithEffect;
