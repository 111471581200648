import React from "react";
import "../../styles/type-scale-system.css";
import "./RadioGroup.css";
import { ReactComponent as ChipDot } from "../../icons/chip-dot.svg";

export const CustomRadioGroup = ({
  options,
  selectedOption,
  setSelectedOption,
  optionName,
}) => {
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div className="radio-group">
      {options.map((option, index) => (
        <div key={index}>
          {/* MAIN OPTIONS */}
          <div
            className={`radio-group-section ${
              selectedOption === option.value ? "corner-shadow" : ""
            }`}
          >
            {/* RADIO BUTTON */}
            <div className="radio-button-container">
              <input
                type="radio"
                name={optionName}
                value={option.value}
                onChange={handleOptionChange}
                checked={selectedOption === option.value}
                disabled={option.disabled}
              />
            </div>

            <div className="radio-group-inner-section">
              <div className="radio-group-label-container">
                {/* TITLE */}
                <div
                  className="card-label-text"
                  style={{
                    color: option.disabled ? "#e7e7e7" : "",
                  }}
                >
                  {option.title}
                </div>

                {/* SUBTITLE */}
                {option.subtitle && (
                  <div
                    className="vulavula-paragraph"
                    style={{ color: "#6d6d6d" }}
                  >
                    {option.subtitle}
                  </div>
                )}
              </div>

              {/* TAG AND IMAGE */}
              <div className="radio-tag-image-container">
                {/* TAG */}
                {option.tagDetails && (
                  <div className="tag-container" style={{ height: "25px" }}>
                    <div
                      className="chip"
                      style={{
                        background: option.tagDetails.lightColor,
                        borderColor: option.tagDetails.darkColor,
                      }}
                    >
                      <div>
                        <ChipDot fill={option.tagDetails.darkColor} />
                      </div>
                      <div
                        className="vulavula-chip-text"
                        style={{ color: option.tagDetails.darkColor }}
                      >
                        {option.tagDetails.title}
                      </div>
                    </div>
                  </div>
                )}

                {/* IMAGE */}
                {option.icon && (
                  <img
                    src={option.icon}
                    alt="stripe-icon"
                    className="stripe-icon"
                    width={42}
                    height={21}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
