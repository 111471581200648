import { createTheme } from "@mui/material/styles";

export const globalTheme = createTheme({
  palette: {
    primary: {
      main: "#CC4A2B",
      contrastText: "#F6F6F6",
    },
    success: { main: "#428080", contrastText: "#F6F6F6" },
    grayscale: {
      main: "#6D6D6D", // basic 500
      basic_50: "#F6F6F6",
      basic_100: "#E7E7E7",
      basic_200: "#D1D1D1",
      basic_300: "#B0B0B0",
      basic_400: "#838383",
      basic_600: "#5D5D5D",
      basic_700: "#4F4F4F",
      basic_800: "#454545",
      basic_900: "#3D3D3D",
      basic_950: "#262626",
    },
    lelapa_yellow: {
      main: "#FFE51B", // basic 500
      basic_50: "#FFFFEA",
      basic_100: "#FEF8D9",
      basic_200: "#FDEFB4",
      basic_300: "#FFF246",
      basic_400: "#FFE51B",
      basic_600: "#E29900",
      basic_700: "#BB6D02",
      basic_800: "#985408",
      basic_900: "#7C450B",
      basic_950: "#482400",
    },
    lelapa_orange: {
      main: "#D96136", //basic 500
      basic_50: "#FDF5EF",
      basic_100: "#F9E8DB",
      basic_200: "#F2CDB6",
      basic_300: "#EAAB87",
      basic_400: "#E08057",
      basic_600: "#CC4A2B",
      basic_700: "#CC4A2B",
      basic_800: "#872E25",
      basic_900: "#6D2921",
      basic_950: "#3B120F",
    },
    lelapa_green: {
      main: "#BDDB3D", //basic 400
      basic_50: "#FAFDE8",
      basic_100: "#F3F9CE",
      basic_200: "#E6F4A2",
      basic_300: "#D4EA6C",
      basic_600: "#7C9A16",
      basic_700: "#5E7615",
      basic_800: "#4B5D17",
      basic_900: "#404F18",
      basic_950: "#212C07",
    },
    brand_orange: {
      main: "#D96136", //basic 500
      basic_50: "#FFF2ED",
      basic_100: "#FFE2D5",
      basic_200: "#FEC1AA",
      basic_300: "#FD9774",
      basic_400: "#FA5730",
      basic_600: "#E91F0D",
      basic_700: "#C1130D",
      basic_800: "#9A1213",
      basic_900: "#7C1212",
      basic_950: "#430709",
    },
    lelapa_purple: {
      main: "#FD3661", //basic 500
      basic_50: "#FFF0F2",
      basic_100: "#FFE3E6",
      basic_200: "#FFCAD3",
      basic_300: "#FF9FB0",
      basic_400: "#FF6A87",
      basic_600: "#EB134C",
      basic_700: "#C70940",
      basic_800: "#A60B3D",
      basic_900: "#8F0D3B",
      basic_950: "#50011B",
    },
    lelapa_blue: {
      main: "#5386E4", //basic 500
      basic_50: "#F1F6FD",
      basic_100: "#DFEAFA",
      basic_200: "#C5DBF8",
      basic_300: "#9EC4F2",
      basic_400: "#6FA4EB",
      basic_600: "#3966D7",
      basic_700: "#3053C5",
      basic_800: "#2D45A0",
      basic_900: "#293D7F",
      basic_950: "#1D274E",
    },
    lelapa_teal: {
      main: "#428080", //basic 500
      basic_50: "#F4F9F9",
      basic_100: "#DAEDEB",
      basic_200: "#B5DAD7",
      basic_300: "#B5DAD7",
      basic_400: "#B5DAD7",
      basic_600: "#36696B",
      basic_700: "#2E5657",
      basic_800: "#284647",
      basic_900: "#253A3C",
      basic_950: "#112022",
    },
    text_light: "#FFFFFF",
    text_disabled: "#B0B0B0",
    text_prompt: "#6D6D6D",
    text_dark: "#3B242E",
    semantic_positive: "#428080",
    semantic_warning: "#E29900",
    semantic_error: "#BC5338",
  },
});

export const Barlow20Text = {
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Barlow",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "20px",
  textTransform: "uppercase",
};
