import {
  CURRENCY_TYPE,
  CURRENCY_TYPE_SYMBOLS,
  BILLING_CYCLE_INTERVAL,
  BILLING_CYCLE_TYPE,
} from "../utils/constants";

export const chunkArray = (arr, size) =>
  arr?.length > size
    ? [arr.slice(0, size), ...chunkArray(arr?.slice(size), size)]
    : [arr];

export const createInvoicesCSV = (invoices) => {
  const headings = [
    "ID",
    "Amount Due",
    "Billing",
    "Closed",
    "Currency",
    "Customer",
    "Date (UTC)",
    "Due Date (UTC)",
    "Number",
    "Paid",
    "Subscription",
    "Subtotal",
    "Total Discount Amount",
    "Applied Coupons",
    "Tax",
    "Total",
    "Amount Paid",
    "Status",
  ];

  const rows = invoices.map((invoice) => [
    invoice.id,
    parseFloat(invoice.amount / 100).toFixed(2),
    invoice.billing,
    invoice.closed,
    invoice.currency,
    invoice.customer,
    invoice.created_on && new Date(Date.parse(invoice.created_on)),
    invoice.due_date && new Date(Date.parse(invoice.due_date)),
    invoice.number,
    invoice.paid,
    invoice.subscription,
    parseFloat(invoice.subtotal / 100).toFixed(2),
    parseFloat(invoice.total_discount_amount / 100).toFixed(2),
    invoice.applied_coupons,
    invoice.tax,
    parseFloat(invoice.total / 100).toFixed(2),
    parseFloat(invoice.amount_paid / 100).toFixed(2),
    invoice.status,
  ]);

  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent += headings.join(",") + "\n";
  rows.forEach((rowArray) => {
    let row = rowArray.join(",");
    csvContent += row + "\n";
  });

  return csvContent;
};

export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case CURRENCY_TYPE.ZAR:
      return CURRENCY_TYPE_SYMBOLS.ZAR;
    case CURRENCY_TYPE.EUR:
      return CURRENCY_TYPE_SYMBOLS.EUR;
    default:
      return CURRENCY_TYPE_SYMBOLS.USD;
  }
};

export const getBillingCyle = (billingCycle) => {
  switch (billingCycle) {
    case BILLING_CYCLE_INTERVAL.MONTH:
    case BILLING_CYCLE_INTERVAL.YEAR:
      return BILLING_CYCLE_TYPE.RECURRING;
    default:
      return BILLING_CYCLE_TYPE.ONCE_OFF;
  }
};

export const getBillingAbbreviation = (billingCycle) => {
  switch (billingCycle) {
    case BILLING_CYCLE_INTERVAL.MONTH:
      return "pm";
    case BILLING_CYCLE_INTERVAL.YEAR:
      return "py";
    default:
      return "";
  }
};

export const handleCopyValue = (value) => {
  const textarea = document.createElement("textarea");
  textarea.value = value;

  textarea.style.position = "absolute";
  textarea.style.left = "-9999px";

  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");

  document.body.removeChild(textarea);
};

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]);
    };

    reader.onerror = () => {
      reject(new Error("Failed to convert file to base64"));
    };

    reader.readAsDataURL(file);
  });
};

export const getMinimumPlanAmount = (plans) => {
  const planAmounts = plans.map((product) => {
    return product.plan_types.map((plan) => plan.plan_amount);
  });

  return Math.min(...planAmounts.flat());
};
