import React, { Component, createRef } from "react";
import "./Recorder.css";
import "../../styles/type-scale-system.css";
import { transcribePageDetails } from "../../cms/generalCMS";
import { ACTIONTYPE } from "../../utils/constants";
import RecordIcon from "../../icons/record-icon.svg";
import StopRecordIcon from "../../icons/stop-recording-icon.svg";
import SendIcon from "../../icons/send-icon.svg";
import DeleteIcon from "../../icons/delete-icon.svg";
import PlayIcon from "../../icons/play-icon.svg";
import PauseIcon from "../../icons/pause-icon.svg";
import PropTypes from "prop-types";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import AudioAnalyser from "../audioAnalyser/AudioAnalyser";
import WaveSurfer from "wavesurfer.js";

class Recorder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recording: this.props.recording,
      audio: null, // this is the audio stream
      recorder: null, // this is the RecordRTC recorder
      audioTime: 0,
      arrayBuffer: null,
      playing: false,
    };

    this.canvasRef = createRef();
    this.audioRef = createRef();
    this.waveform = null;

    this.startTime = 0;
    this.timerInterval = null;

    this.onButtonAction = this.onButtonAction.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { audioBlob, recording } = this.props;

    if (prevProps.recording !== recording) {
      this.setState({
        recording: recording,
      });
    }

    if (audioBlob !== prevProps.audioBlob) {
      this.loadAudioBlob(audioBlob);
    }

    this.drawDefaultWave();
    this.getWaveform();
  }

  componentDidMount() {
    const { audioBlob } = this.props;

    if (audioBlob) {
      this.loadAudioBlob(audioBlob);
    }

    this.drawDefaultWave();
  }

  componentWillUnmount() {
    if (this.props.audioBlob) {
      URL.revokeObjectURL(URL.createObjectURL(this.props.audioBlob));
    }
  }

  getWaveform() {
    const { audioBlob } = this.props;
    if (audioBlob && this.waveform === null) {
      this.waveform = WaveSurfer.create({
        container: "#waveform",
        waveColor: "#f7ecc4",
        progressColor: "#FAC200",
        barWidth: 2, // Adjust as needed
        barGap: 1, // Adjust as needed
        height: 100,
      });

      this.waveform.on("finish", () => {
        this.setState({ playing: false });
      });

      this.waveform.on("error", function (err) {
        console.error("WaveSurfer error:", err);
      });

      try {
        this.waveform.loadBlob(audioBlob); // Load waveform directly from the Blob
      } catch (error) {
        console.error("Error loading waveform:", error);
      }
    }
  }

  getButtonIcon() {
    let { recording } = this.state;
    let { audioBlob } = this.props;
    if (audioBlob === null && recording) {
      return StopRecordIcon;
    } else if (audioBlob !== null && !recording) {
      return SendIcon;
    } else {
      return RecordIcon;
    }
  }

  async getMicrophone() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        // this creates an instance of the mic and then in getRTCRecorder we "open" the mic creating a stream
        const audio = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        this.setState({ audio });
        this.getRTCRecorder(audio);
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    } else {
      console.error("getUserMedia is not supported in this environment");
    }
  }

  getRTCRecorder(audio) {
    let recorder = null;
    try {
      recorder = new RecordRTC(audio, {
        recorderType: StereoAudioRecorder,
        type: "audio",
        mimeType: "audio/wav",
        disableLogs: true,
        numberOfAudioChannels: 1,
        checkForInactiveTracks: true,
        bufferSize: 16384,
        desiredSampRate: 48000,
      });

      recorder.startRecording();

      this.startTime = Date.now();
      this.timerInterval = setInterval(() => {
        const currentTime = Date.now();
        const elapsedSeconds = (currentTime - this.startTime) / 1000;
        this.setState({ audioTime: elapsedSeconds });
      }, 1000);

      this.setState({ recorder });
    } catch (err) {
      //enable the record button if getUserMedia() fails
      console.error("something went wrong", err);
    }
  }

  stopMicrophone() {
    let { recorder, audio } = this.state;
    audio.getTracks().forEach((track) => track.stop());

    this.stopRTCRecorder(recorder);

    clearInterval(this.timerInterval);
    this.setState({ audioTime: 0 });
    // setting audio stream to null to close the mic
    this.setState({ audio: null });
  }

  stopRTCRecorder(recorder) {
    recorder.stopRecording(() => {
      let blob = recorder.getBlob();
      this.updatedRecordingAndBlobState(blob);
    });
  }

  onButtonAction(action) {
    switch (action) {
      case ACTIONTYPE.RECORDING:
        this.getMicrophone();

        this.setState((prevState) => {
          this.props.handleRecordingState(action, !prevState.recording, null);
          return { recording: !prevState.recording };
        });
        break;
      case ACTIONTYPE.STOP_RECORDING:
        this.stopMicrophone();
        break;
      case ACTIONTYPE.TRANSCRIBE:
        this.props.handleRecordingState(action, true, null);
        this.props.handleTranscribing();
        break;
      default: // trancribing process
        break;
    }
  }

  updatedRecordingAndBlobState = (blob) => {
    this.setState((prevState) => {
      this.props.handleRecordingState(
        ACTIONTYPE.STOP_RECORDING,
        !prevState.recording,
        blob
      );
      return { recording: !prevState.recording };
    });
  };

  drawDefaultWave() {
    const canvas = this.canvasRef.current;
    let { recording } = this.state;
    let { audioBlob } = this.props;

    if (!recording && audioBlob === null) {
      const height = canvas.height;
      const canvasCtx = canvas.getContext("2d");
      const width = canvas.width;
      const centerY = canvas.height / 2;
      const lineWidth = 4;
      const lineSpacing = 3;

      canvasCtx.beginPath();
      canvasCtx.strokeStyle = "#FAC200";
      canvasCtx.lineWidth = 2;
      canvasCtx.lineHeight = height;
      canvasCtx.setLineDash([lineWidth, lineSpacing]);
      canvasCtx.moveTo(0, centerY - 1);
      canvasCtx.lineTo(width, centerY - 1);
      canvasCtx.stroke();
    }
  }

  onDeleteFile() {
    this.setState({
      audioTime: 0.0,
      recording: false,
    });
    this.props.handleClearStates();
    this.waveform = null;
  }

  onPlayFile() {
    this.waveform.playPause();
    this.setState((prevState) => {
      return { playing: !prevState.playing };
    });
  }

  loadAudioBlob(audioBlob) {
    const audioElement = this.audioRef.current;

    if (audioElement) {
      // Create a Blob URL for the audio blob
      const blobUrl = URL.createObjectURL(audioBlob);
      audioElement.src = blobUrl;

      // Load metadata to get the duration
      audioElement.addEventListener("loadedmetadata", () => {
        const duration = audioElement.duration;
        this.setState({ audioTime: duration });
      });
    }
  }

  formatDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60);
    return `${minutes}.${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }

  render() {
    let { recording, audio, audioTime, arrayBuffer, playing } = this.state;
    let { audioBlob } = this.props;

    return (
      <div className="control-recording-container">
        {/* Audio wave UI */}
        <div className="audio-wave-container">
          <div className="wave-time">
            <div
              className={`${
                audioBlob ? "recorded-wave-playback" : "wave-playback"
              }`}
              style={{ color: "#FAC200" }}
            >
              {/* DELETE BUTTON */}
              {audioBlob !== null && !recording ? (
                <div
                  style={{ display: "flex" }}
                  onClick={() => this.onDeleteFile()}
                >
                  <img src={DeleteIcon} alt="delete icon" />
                </div>
              ) : (
                ""
              )}

              {/* WAVE UI */}
              {!audio && audioBlob === null ? (
                <canvas ref={this.canvasRef} className="wave-container" />
              ) : audio && audioBlob === null ? (
                <AudioAnalyser
                  audio={this.state.audio}
                  arrayBuffer={arrayBuffer}
                />
              ) : audioBlob ? (
                <div className="waveform-container">
                  <div id="waveform" />
                  <audio ref={this.audioRef} />
                </div>
              ) : (
                ""
              )}

              {/* PLAY BUTTON */}
              {audioBlob !== null && !recording ? (
                <div
                  style={{ display: "flex" }}
                  onClick={() => this.onPlayFile()}
                >
                  <img src={playing ? PauseIcon : PlayIcon} alt="play icon" />
                </div>
              ) : (
                ""
              )}
            </div>

            {/* AUDIO TIME */}
            <div className="vulavula-paragraph" style={{ color: "#FAC200" }}>
              {"<" + this.formatDuration(audioTime) + ">"}
            </div>
          </div>
        </div>

        {/* RECORD BUTTON */}
        <div
          className="button-primary-red-larger"
          onClick={() =>
            this.onButtonAction(
              audioBlob === null && recording
                ? ACTIONTYPE.STOP_RECORDING
                : audioBlob !== null && !recording
                ? ACTIONTYPE.TRANSCRIBE
                : ACTIONTYPE.RECORDING
            )
          }
        >
          <div className="record-button-text">
            {audioBlob === null && recording
              ? transcribePageDetails.stopRecordingButtonText
              : audioBlob !== null && !recording
              ? transcribePageDetails.transcribeButtonText
              : transcribePageDetails.recordButtonText}
          </div>
          <img src={this.getButtonIcon()} alt="button-icon" />
        </div>
      </div>
    );
  }
}

Recorder.propTypes = {
  recording: PropTypes.bool,
  handleRecordingState: PropTypes.func,
  handleClearStates: PropTypes.func,
  handleTranscribing: PropTypes.func,
  audioBlob: PropTypes.object,
};

export default Recorder;
