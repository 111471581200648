import React, { Component } from "react";
import "./ErrorCard.css";
import "../../styles/type-scale-system.css";
import PropTypes from "prop-types";
import { ReactComponent as ErrorIcon } from "../../icons/error-icon.svg";
import RuleSettingsIcon from "../../icons/rule-settings.svg";
import { improveCardInfo } from "../../cms/generalCMS";

class ErrorCard extends Component {
  render() {
    return (
      <div className="error-content-container">
        {/* ERROR MESSAGE CONTENT */}
        <div className="error-container">
          <div className="error-inner-container">
            <div className="vulavula-paragraph" style={{ color: "#FFFFFF" }}>
              {this.props.errorMessage}
            </div>
            <ErrorIcon className="error-icon" fill="#F6F6F6" />
          </div>
        </div>

        {/* ACTION BUTTON */}
        <div className="error-controls">
          <div
            className="result-button"
            style={{
              color: "#3A2730",
              border: "1px solid #3a2730",
            }}
            onClick={this.props.handleClearStates}
          >
            <div className="result-button-text" style={{ color: "#3A2730" }}>
              {improveCardInfo.tryAgainButtonText}
            </div>
            <img src={RuleSettingsIcon} alt="rule setting" />
          </div>
        </div>
      </div>
    );
  }
}

ErrorCard.propTypes = {
  errorMessage: PropTypes.string,
  handleClearStates: PropTypes.func,
};

export default ErrorCard;
