import React, { Component } from "react";
import "./RatingModal.css";
import "../../styles/type-scale-system.css";
import PropTypes from "prop-types";
import { ReactComponent as EmptyStarIcon } from "../../icons/empty-star-icon.svg";
import { ReactComponent as ChipDot } from "../../icons/chip-dot.svg";
import { submitRating } from "../../helpers/api/rating";
import { TRANSCRIBE, ENTITY_RECOGNITION, PRODUCTTYPE, SENTIMENT_ANALYSIS } from "../../utils/constants";
import { Modal, Backdrop, Fade } from "@mui/material";

class RatingModal extends Component {
  constructor(props) {
    super(props);

    const { globalState } = this.props;
    const { showRateServiceModal } = globalState;
    this.state = {
      rating: 0,
      selectedTagsMap: {},
      responseText: "",
      isOpen: showRateServiceModal.show,
    };

    this.handleResponsetextChange = this.handleResponsetextChange.bind(this);
    this.handleRating = this.handleRating.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState !== this.props.globalState) {
      const { globalState } = this.props;
      const { showRateServiceModal } = globalState;
      this.setState({ isOpen: showRateServiceModal.show });
    }
  }

  handleStarClick = (starValue) => {
    this.setState({ rating: starValue });

    if (starValue > 2 && Object.keys(this.state.selectedTagsMap).length > 0) {
      this.setState({ selectedTagsMap: {} });
    } else if (starValue <= 2 && this.state.responseText !== "") {
      this.setState({ responseText: "" });
    }
  };

  handleSelectTag = (index, value) => {
    let { selectedTagsMap } = this.state;
    let inMap = index in selectedTagsMap;

    if (inMap) {
      delete selectedTagsMap[index];
    } else {
      selectedTagsMap[index] = value;
    }

    this.setState({ selectedTagsMap });
  };

  handleResponsetextChange(event) {
    this.setState({ responseText: event.target.value });
  }

  getChipColor(index) {
    let { selectedTagsMap } = this.state;

    if (index in selectedTagsMap) {
      return { lightColor: "#FAFDE8", darkColor: "#5E7615" };
    } else {
      return {
        darkColor: "#BB6D02",
        lightColor: "#FEF8D9",
      };
    }
  }

  async handleRating() {
    let { responseText, selectedTagsMap, rating } = this.state;
    const { modalInfo } = this.props;

    let tags = [];
    for (const key of Object.keys(selectedTagsMap)) {
      tags.push(selectedTagsMap[key]);
    }

    let text = "";
    if (tags.length > 0) {
      const lastOption =
        modalInfo.tagsOptions[modalInfo.tagsOptions?.length - 1];

      if (tags.includes(lastOption) && responseText !== "") {
        tags = tags.filter((a) => a !== lastOption);
        tags.push(responseText);
      }
      text = tags.join(",");
    } else {
      text = responseText;
    }

    let productType;
    switch (window.location.pathname) {
      case TRANSCRIBE:
        productType = PRODUCTTYPE.TRANSCRIBE;
        break;
      case ENTITY_RECOGNITION:
        productType = PRODUCTTYPE.ENTITY_RECOGNITION;
        break;
      case SENTIMENT_ANALYSIS:
        productType = PRODUCTTYPE.SENTIMENTAL_ANALYSIS
        break;

      default:
        productType = PRODUCTTYPE.UNKOWN;
        break;
    }

    await submitRating(text, rating, productType, this.props.dispatch);

    this.props.handleClearStates();
  }

  handleClose() {
    this.setState((prevState) => {
      this.props.dispatch({
        type: "UPDATE_SHOW_RATE_SERVICE_MODAL",
        payload: { show: !prevState.isOpen },
      });
    });
  }

  render() {
    const { rating, selectedTagsMap, responseText, isOpen } = this.state;
    const { modalInfo } = this.props;

    return (
      <Modal
        className="custom-modal"
        open={isOpen}
        onClose={this.handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={isOpen}>
          <div className="rating-outer-container">
            <div className="rating-inner-container">
              <div className="title-content">
                {/* HEADING */}
                <div className="vulavula-card-heading text-align-center">
                  {modalInfo.heading}
                </div>

                {/* TITLE */}
                <div className="vulavula-paragraph text-align-center">
                  {modalInfo.title}
                </div>

                {/* STARTS CONTAINER */}
                <div className="star-container">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <EmptyStarIcon
                      key={star}
                      onClick={() => this.handleStarClick(star)}
                      style={{
                        cursor: "pointer",
                        width: "30px",
                        height: "30px",
                        fill: star <= rating ? "#FAC200" : "",
                        stroke: star <= rating ? "#FAC200" : "black",
                      }}
                    />
                  ))}
                </div>
              </div>

              {/* RATING RESPONSE */}
              {rating !== 0 ? (
                <div className="response-container">
                  {/* SUBTITLE */}
                  <div className="vulavula-paragraph-large">
                    {modalInfo.subtitle}
                  </div>

                  {/* RESPONSE */}
                  {rating <= 2 &&
                    rating !== 0 &&
                    modalInfo.tagsOptions?.length > 0 ? (
                    <div className="tags-container">
                      {modalInfo.tagsOptions.map((tag, i) => {
                        let chipColors = this.getChipColor(i);
                        return (
                          <div
                            className="chip"
                            key={i}
                            style={{
                              background: chipColors.lightColor,
                              borderColor: chipColors.darkColor,
                            }}
                            onClick={() => this.handleSelectTag(i, tag)}
                          >
                            <div>
                              <ChipDot fill={chipColors.darkColor} />
                            </div>
                            <div
                              className="vulavula-chip-text"
                              style={{ color: chipColors.darkColor }}
                            >
                              {tag}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <input
                      className="response-text vulavula-paragraph"
                      type="text"
                      placeholder={modalInfo.inputPlaceholder}
                      onInput={this.handleResponsetextChange}
                    />
                  )}

                  {modalInfo.tagsOptions[modalInfo.tagsOptions?.length - 1] ===
                    "Other" &&
                    Object.values(selectedTagsMap).includes(
                      modalInfo.tagsOptions[modalInfo.tagsOptions?.length - 1]
                    ) && (
                      <div className="input-container">
                        <input
                          className="response-text"
                          name="Other"
                          type="text"
                          placeholder={modalInfo.inputPlaceholder}
                          onInput={this.handleResponsetextChange}
                        />
                      </div>
                    )}

                  {/* SUBMIT BUTTON */}
                  <div
                    className="submit-button"
                    style={{
                      cursor: "pointer",
                      background:
                        responseText === "" &&
                          (Object.keys(selectedTagsMap).length === 0 ||
                            (Object.values(selectedTagsMap).includes(
                              modalInfo.tagsOptions[
                              modalInfo.tagsOptions?.length - 1
                              ]
                            ) &&
                              responseText === ""))
                          ? "#B0B0B0"
                          : "#cc4a2b",
                    }}
                    onClick={
                      responseText === "" &&
                        (Object.keys(selectedTagsMap).length === 0 ||
                          (Object.values(selectedTagsMap).includes(
                            modalInfo.tagsOptions[
                            modalInfo.tagsOptions?.length - 1
                            ]
                          ) &&
                            responseText === ""))
                        ? undefined
                        : this.handleRating
                    }
                  >
                    <div className="submit-button-text">
                      {modalInfo.submitButtonText}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

RatingModal.propType = {
  handleClearStates: PropTypes.func,
  dispatch: PropTypes.func,
  modalInfo: PropTypes.object,
  globalState: PropTypes.object,
};

export default RatingModal;
