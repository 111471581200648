import { forgotPasswordEmailSentInfo } from "../../../cms/generalCMS";
import React from "react";
import CustomForm from "../../../components/form/CustomForm";
import { FORGOT_PASSWORD, RESET_PASSWORD_CODE } from "../../../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PasswordResetMissingDetails } from "../../../components/auth";
import { isValidEmail } from "../../../utils";

export const ForgotPasswordEmailSent = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get("email");
  const email = emailParam ? decodeURIComponent(emailParam) : '';

  if (!email || !isValidEmail(email)) {
    return <PasswordResetMissingDetails
      message={forgotPasswordEmailSentInfo.missingDetailsText}
    />;
  }

  const handleResetCode = () => {
    navigate(`${RESET_PASSWORD_CODE}?email=${encodeURIComponent(email)}`);
  }

  const getForgotPasswordDetails = () => {

    let primaryButtons = [
      {
        backgroundColor: "#cc4a2b",
        text: forgotPasswordEmailSentInfo.forgotPasswordEmailSentButtonText,
        handleButtonAction: handleResetCode,
      },
    ];

    let secondaryButtons = [
      {
        enabled: true,
        text: forgotPasswordEmailSentInfo.backButtonText,
        handleButtonAction: () => navigate(FORGOT_PASSWORD),
      },
    ];

    return { primaryButtons, secondaryButtons };
  }

  const details = getForgotPasswordDetails();
  return (
    <div className="v-w-full v-d-flex  v-justify-center v-align-center">
      <div className="login-container v-d-flex v-w-70 v-text-center">
        <div className="v-d-flex v-flex-column v-mb-5">
          <div className="vulavula-card-heading v-mb-3">{forgotPasswordEmailSentInfo.title}</div>
          <div className="vulavula-card-subheading">{forgotPasswordEmailSentInfo.subtitle}</div>
        </div>

        <div className="login-content">
          <CustomForm
            primaryButtons={details.primaryButtons}
            secondaryButtons={details.secondaryButtons}
          />
        </div>
      </div>
    </div>
  )
}