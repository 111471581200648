import React, { useState, useEffect, useRef } from "react";
import "../../../styles/type-scale-system.css";
import "./SubscribePage.css";
import DownwardArrowIcon from "../../../icons/downwards-arrow-icon.svg";
import { SubscribePageInfo, BillingPageInfo } from "../../../cms/generalCMS";
import Pattern1 from "../../../icons/pattern-1.svg";
import CustomCard from "../../../components/customCard/CustomCard";
import { BILLING, HOME } from "../../../utils/constants";
import { CustomRadioGroup } from "../../../components/radioGroup/RadioGroup";
import ProgressBar from "../../../components/progressBar/ProgressBar";
import StripeIcon from "../../../icons/stripe-icon.svg";
import CircleCheckIcon from "../../../icons/green-circle-check-icon.svg";
import CircleErrorIcon from "../../../icons/circle-error-icon.svg";
import {
  getBillingAbbreviation,
  getCurrencySymbol,
  getMinimumPlanAmount,
} from "../../../helpers/utils";
import {
  upsertPaymentMethod,
  createSubscription,
} from "../../../helpers/api/billing";
import { getCookie } from "../../../helpers/cookie";
import PaymentForm from "../../../components/billing/PaymentForm";
import { vulaApi } from "../../../helpers/api/apiHelper";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const SubscribePage = ({ globalState, dispatch, navigate }) => {
  const {
    addingPaymentMethod,
    productPlans,
    sessionToken,
    userPaymentMethods,
    userSubscriptions,
  } = globalState;
  const [currentStep, setCurrentStep] = useState(addingPaymentMethod ? 3 : 0);
  const [stepContent, setStepContent] = useState(
    SubscribePageInfo.steppers[currentStep]
  );
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanPrice, setSelectedPlanPrice] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    addingPaymentMethod ? "new-card" : null
  );
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [paymentMethodResponse, setPaymentMethodResponse] = useState({
    completed: false,
    success: false,
    error: "",
  });

  // Stripe React SDK
  const [stripePromise, setStripePromise] = useState(null);
  const [stripeClientSecret, setStripeClientSecret] = useState(null);

  useEffect(() => {
    // get stripe publishable key from the backend
    vulaApi.get("billing/config", { headers: { "Authorization": `Bearer ${sessionToken}`} })
      .then((response) => {
        console.log("response", response);
        setStripePromise(loadStripe(response.data.publishableKey));
      })
    }, [setStripePromise, sessionToken]);
  
  // For PaymentForm child component to set when form is completed
  const [paymentFormCompleted, setPaymentFormCompleted] = useState(false);

  // Callback function set from PaymentForm child component to submit form
  const [submitHandler, setSubmitHandler] = useState(null);

  const handleSubmitFromSubscribePage = () => {
    console.log("parent submit", submitHandler);
    if (submitHandler) {
      console.log("parent submit exists", submitHandler);
      submitHandler();
    }
  };


  const getStepper = () => {
    return (
      <div className="step-counter-container">
        {SubscribePageInfo.steppers.map((_, index) => {
          let stepCompleted = false;

          if (currentStep === 0 && currentStep === index) {
            stepCompleted = selectedPlan !== null;
          } else if (currentStep === 1 && currentStep === index) {
            stepCompleted = selectedPlanPrice !== null;
          } else if (currentStep === 2 && currentStep === index) {
            stepCompleted = selectedPaymentMethod !== null;
          } else if (currentStep === 3 && currentStep === index) {
            stepCompleted = paymentFormCompleted;
          }

          return (
            <div key={index} className="stepper-inner-container">
              <div
                className={`stepper-circle vulavula-paragraph-large-light ${currentStep > index || stepCompleted
                  ? "stepper-circle-active"
                  : ""
                  }`}
              >
                {currentStep > index || stepCompleted ? (
                  <div
                    className="custom-checkbox checked"
                    style={{
                      backgroundColor: "#fac200",
                    }}
                  >
                    <div className="tick-mark">&#10003;</div>
                  </div>
                ) : (
                  index + 1
                )}
              </div>
              {index !== SubscribePageInfo.steppers.length - 1 && (
                <div
                  className="stepper-separator"
                  style={currentStep > index ? { background: "#fac200" } : {}}
                ></div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const getCardDetails = () => {
    let cardDetails = {};
    const currencySymbol = getCurrencySymbol(productPlans.data?.currency);
    // const amount = productPlans.data?.minimum_price; // commented out for now
    const amount = productPlans.data?.products
      ? getMinimumPlanAmount(productPlans.data?.products)
      : 0; // requested change for now, change to use minimumAmount in state

    BillingPageInfo.BillingSection.cards.map((card) => {
      let isAPICard = card.title.includes("API");
      if (isAPICard) {
        return (cardDetails = {
          image: {
            url: Pattern1,
            position: "flex-start",
            imageStyle: {
              display: "flex",
              width: "48px",
              height: "41px",
            },
          },
          tag: {},
          title: {
            text: card.title,
            style: {
              color: "#3a2730",
            },
          },
          subtitle: {
            text: card.subtitle,
            style: {},
          },
          body: {
            title: isAPICard
              ? `<div class='card-heading-2'>${currencySymbol}${amount}</div> ${card.body.title}`
              : card.body.title,
            text: card.body.text,
            bodyStyle: { alignItems: "flex-end" },
            textStyle: { height: "150px" },
          },
          primaryButton: null,
          secondaryButton: null,
          cardStyle: {
            boxShadow:
              "0 10px 22px 0 rgba(0, 0, 0, 0.1), 0 39px 39px 0 rgba(0, 0, 0, 0.09), 0 89px 53px 0 rgba(0, 0, 0, 0.05), 0 80px 63px 0 rgba(0, 0, 0, 0.01)",
            borderRadius: "16px",
            border: "solid 3px #d96136",
            backgroundImage:
              "linear-gradient(157deg, #fff 62%, #eaab87 141%), linear-gradient(to bottom, #fff, #fff)",
          },
        });
      }
      return null;
    });

    return cardDetails;
  };

  const getFeatureContent = () => {
    const plansData = productPlans.data?.products;
    if (!plansData) {
      return;
    }

    let options = [];
    let optionName = "";
    let selectedOption = null;
    let setSelectedOption = null;
    if (currentStep === 0) {
      optionName = "subscription-plan";
      selectedOption = selectedPlan;
      setSelectedOption = setSelectedPlan;

      plansData.forEach((plan) => {
        options.push({
          value: plan.product_id,
          title: `${plan.product_name} ${plan.product_description ? `- ${plan.product_description}` : ""
            }`,
          tagDetails: plan.usage_type === "metered" && {
            lightColor: "#FAFDE8",
            darkColor: "#5e7615",
            title: `${getCurrencySymbol(plan.plan_types[0]?.plan_currency) +
              plan.plan_types[0]?.plan_overage_amount
              } / call`,
          },
          icon: StripeIcon,
        });
      });
    } else if (currentStep === 1) {
      optionName = "subscription-plan-price";
      selectedOption = selectedPlanPrice;
      setSelectedOption = setSelectedPlanPrice;

      if (selectedPlan) {
        const plan = plansData.find((plan) => plan.product_id === selectedPlan);
        if (plan) {
          plan.plan_types.forEach((planType) => {
            // check if the user has a subscription for the current plan
            const userSubscription = userSubscriptions.data.find(
              (subscription) =>
                subscription.plan_price.price_id === planType.plan_id
            );

            options.push({
              value: planType.plan_id,
              title: `${getCurrencySymbol(planType.plan_currency) + planType.plan_amount
                } per ${planType.plan_interval} ${userSubscription ? "(Current Plan)" : ""
                }`,
              disabled: userSubscription !== undefined,
              icon: StripeIcon,
            });
          });
        }
      }
    }

    return (
      <div className="feature-section-content">
        <CustomRadioGroup
          options={options}
          optionName={optionName}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </div>
    );
  };

  const getSelectPaymentContent = () => {
    let options = [
      {
        value: "new-card",
        title: SubscribePageInfo.creditCardText,
        subtitle: SubscribePageInfo.creditCardSubtitle,
        icon: StripeIcon,
      },
    ];

    userPaymentMethods.data.forEach((paymentMethodCard) => {
      options.push({
        value: paymentMethodCard.id,
        title: `${paymentMethodCard.brand[0].toUpperCase() +
          paymentMethodCard.brand.slice(1)
          } ending in ${paymentMethodCard.last4}`,
        subtitle: `Expires ${paymentMethodCard.exp_month}/${paymentMethodCard.exp_year}`,
        icon: StripeIcon,
      });
    });

    return (
      <CustomRadioGroup
        options={options}
        selectedOption={selectedPaymentMethod}
        setSelectedOption={setSelectedPaymentMethod}
      />
    );
  };

  const enableNextButton = () => {
    if (currentStep === 0) {
      return selectedPlan !== null;
    } else if (currentStep === 1) {
      return selectedPlanPrice !== null;
    } else if (currentStep === 2) {
      return selectedPaymentMethod !== null;
    } else {
      if (
        paymentFormCompleted &&
        currentStep === 3 &&
        stepContent !== SubscribePageInfo.payNowTitles
      ) {
        setStepContent(SubscribePageInfo.payNowTitles);
      } else if (
        !paymentFormCompleted &&
        currentStep === 3 &&
        stepContent !== SubscribePageInfo.steppers[currentStep]
      ) {
        setStepContent(SubscribePageInfo.steppers[currentStep]);
      }
      return paymentFormCompleted;
    }
  };

  const handleNextStep = () => {
    console.log("handleNextStep", currentStep, SubscribePageInfo.steppers.length);
    if (currentStep <= SubscribePageInfo.steppers.length - 1) {
      setCurrentStep(currentStep + 1);
      setStepContent(SubscribePageInfo.steppers[currentStep + 1]);
    }
  };

  const handleBackButton = () => {
    if (addingPaymentMethod && currentStep === 3) {
      // navigate to the previous page
      navigate(BILLING);
      dispatch({ type: "UPDATE_ADDING_PAYMENT_METHOD", payload: false });
    } else if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setStepContent(SubscribePageInfo.steppers[currentStep - 1]);
    } else {
      navigate(BILLING);
    }
  };

  const handlePaymentProcess = () => {
    // setLoading(true); TODO: fix this render hook issue later

    // simulate a loading state
    const totalProgress = 70;
    let currentProgress = progress;

    const simulateProgress = () => {
      const progressInterval = setInterval(() => {
        currentProgress += 20;

        // update the progress bar and clear the interval when 100%
        if (currentProgress >= totalProgress) {
          clearInterval(progressInterval);
        } else {
          setProgress(currentProgress);
        }
      }, 200);
    };

    simulateProgress();

    // if the user is adding a new payment method, call the add payment method endpoint
    if (selectedPaymentMethod === "new-card") {
      handleAddPaymentMethod();
    }

    if (!addingPaymentMethod) {
      handleCreateSubscription();
    }
  };

  const handleAddPaymentMethod = async () => {
    const data = {
      id: "",
      default: userPaymentMethods.length > 0,
      card_number: paymentMethodDetails.cardNumber.replace(/\s/g, ""),
      expiry_month: paymentMethodDetails.expiryDate.split("/")[0],
      expiry_year: paymentMethodDetails.expiryDate.split("/")[1],
      cvv: paymentMethodDetails.cvv,
    };

    await upsertPaymentMethod(sessionToken, data, dispatch).then((resp) => {
      if (!resp.error) {
        if (addingPaymentMethod) {
          setLoading(false);
          setProgress(100);
          setPaymentMethodResponse({
            completed: true,
            success: true,
            error: "",
          });
          dispatch({ type: "UPDATE_ADDING_PAYMENT_METHOD", payload: false });
          dispatch({
            type: "UPDATE_USER_PAYMENT_METHODS",
            payload: { data: [], error: false, fetched: false },
          });
        } else {
          setSelectedPaymentMethod(resp.data["payment_method_id"]);
          setProgress(80);
          handleCreateSubscription();
        }
      } else {
        setLoading(false);
        setProgress(100);
        setPaymentMethodResponse({
          completed: true,
          success: false,
          error: "Failed to add payment method",
        });
      }
    });
  };

  const handleCreateSubscription = async () => {
    const userSubscription = userSubscriptions.data.find(
      (subscription) => subscription.plan_price.product_id === selectedPlan
    );

    const data = {
      id: userSubscription ? userSubscription.id : "",
      item_id: userSubscription ? userSubscription.plan_price.id : "",
      price_id: selectedPlanPrice,
      payment_method_id: selectedPaymentMethod,
    };

    await createSubscription(sessionToken, data, dispatch).then((resp) => {
      setLoading(false);
      setProgress(100);

      if (!resp.error) {
        setPaymentMethodResponse({
          completed: true,
          success: true,
          error: "",
        });

        dispatch({
          type: "UPDATE_USER_SUBSCRIPTIONS",
          payload: { data: [], error: false, fetched: false },
        });
        dispatch({
          type: "UPDATE_USER_PAYMENT_METHODS",
          payload: { data: [], error: false, fetched: false },
        });
        dispatch({
          type: "UPDATE_USER_INVOICES",
          payload: { data: [], error: false, fetched: false },
        });
        dispatch({
          type: "UPDATE_CURRENT_USER_INVOICE",
          payload: { data: null, error: false, fetched: false },
        });
      } else {
        setPaymentMethodResponse({
          completed: true,
          success: false,
          error: "Failed to create subscription",
        });
      }
    });
  };

  const handleProcessCompleteNavigation = () => {
    if (addingPaymentMethod) {
      dispatch({ type: "UPDATE_ADDING_PAYMENT_METHOD", payload: false });
    }

    navigate(BILLING);
  };

  const getSelectedPlanDetails = () => {
    const plansData = productPlans.data?.products;
    if (!plansData) {
      return;
    }

    let selectedPlanDetails = null;
    plansData.forEach((plan) => {
      if (plan.product_id === selectedPlan) {
        selectedPlanDetails = plan;
      }
    });

    return selectedPlanDetails;
  };

  const getSelectedPlanPriceDetails = (selectedPlanDetails) => {
    if (!selectedPlanDetails) {
      return;
    }

    let selectedPlanPriceDetails = null;
    selectedPlanDetails.plan_types.forEach((planType) => {
      if (planType.plan_id === selectedPlanPrice) {
        selectedPlanPriceDetails = planType;
      }
    });

    return selectedPlanPriceDetails;
  };

  const getPaymentCompleteContent = () => {
    const selectedPlanDetails = getSelectedPlanDetails();
    const selectedPlanPriceDetails =
      getSelectedPlanPriceDetails(selectedPlanDetails);

    return (
      <div className="payment-complete-container">
        {/* CONTENT */}
        <div className="payment-complete-content">
          {/* CHECK */}
          <div className="payment-complete-check">
            <img
              src={
                paymentMethodResponse.success
                  ? CircleCheckIcon
                  : CircleErrorIcon
              }
              alt="check-icon"
              width={20}
              height={20}
            />
          </div>

          {/* TITLES */}
          <div className="payment-complete-titles">
            {/* TITLE */}
            <div
              className="vulavula-card-heading-light"
              style={{ fontWeight: "bold" }}
            >
              {paymentMethodResponse.success
                ? SubscribePageInfo.paymentSuccessTitles.title
                : SubscribePageInfo.paymentFailureTitles.title}
            </div>

            {/* SUBTITLE */}
            <div className="vulavula-paragraph">
              {paymentMethodResponse.success
                ? SubscribePageInfo.paymentSuccessTitles.subtitle
                : SubscribePageInfo.paymentFailureTitles.subtitle}
            </div>

            {/* BILLING PLAN */}
            <div className="payment-complete-details">
              <div className="card-label">
                {SubscribePageInfo.paymentConfirmationInfo.billingPlanTitle}
              </div>
              <div className="vulavula-paragraph">
                {selectedPlanDetails.product_name}
              </div>
            </div>

            {/* BILLING CYCLE */}
            <div className="payment-complete-details">
              <div className="card-label">
                {SubscribePageInfo.paymentConfirmationInfo.billingCycleTitle}
              </div>
              <div className="vulavula-paragraph">
                {/* {getBillingCyle(selectedPlanPriceDetails.plan_interval)} */}
                {selectedPlanPriceDetails.plan_interval[0].toUpperCase() +
                  selectedPlanPriceDetails.plan_interval.slice(1) +
                  "ly "}
                {getCurrencySymbol(selectedPlanPriceDetails.plan_currency) +
                  selectedPlanPriceDetails.plan_amount +
                  " " +
                  getBillingAbbreviation(
                    selectedPlanPriceDetails.plan_interval
                  )}
                {selectedPlanPriceDetails.units_count > 0
                  ? " - " +
                  selectedPlanPriceDetails.units_count +
                  " calls / overage"
                  : ""}
              </div>
            </div>

            {/* SUBTOTAL */}
            <div className="payment-complete-details">
              <div className="card-label">
                {SubscribePageInfo.paymentConfirmationInfo.subtotalTitle}
              </div>
              <div className="subtotal-text-container">
                <div className="vulavula-card-subheading">
                  {getCurrencySymbol(selectedPlanPriceDetails.plan_currency) +
                    selectedPlanPriceDetails.plan_amount}
                </div>
                <div
                  className="vulavula-paragraph"
                  style={{ fontWeight: "bold" }}
                >
                  {getBillingAbbreviation(
                    selectedPlanPriceDetails.plan_interval
                  ) +
                    " / " +
                    getCurrencySymbol(selectedPlanPriceDetails.plan_currency) +
                    selectedPlanPriceDetails.plan_overage_amount +
                    " per call overage"}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ACTION BUTTON */}
        <div
          className="button-primary-red-small"
          style={{ cursor: "pointer" }}
          onClick={() => handleProcessCompleteNavigation()} // TODO: Change to Product page
        >
          <div className="button-text">
            {SubscribePageInfo.backToBillingButtonText}
          </div>
        </div>
      </div>
    );
  };

  const card = getCardDetails();
  const titles =
    currentStep === 0
      ? SubscribePageInfo.featureTitle
      : currentStep === 1
        ? SubscribePageInfo.billingCycleTitle
        : currentStep === 2
          ? SubscribePageInfo.sectionTitle
          : null;
  const enableNext = enableNextButton();

  const NextButton = (enabled) => {
    console.log("nextButton initialised", enabled, currentStep, handleSubmitFromSubscribePage);
    const button_text = paymentFormCompleted && currentStep === 3 ? SubscribePageInfo.payNowButtonText : SubscribePageInfo.nextButtonText;
    const nextFn = paymentFormCompleted && (currentStep === 3 || currentStep === 2) ? handleSubmitFromSubscribePage : handleNextStep

    if (enabled) {
      return (
        <div class="button-primary-red-larger" style={{ height: "auto" }} onClick={nextFn} >
          <div class="button-text" style={{ color: "#FFFFFF" }} >
            {button_text}
          </div>
        </div>
      )
    }
    else {
      return (
        <div class="button-primary-white-no-border-large" style={{ height: "auto" }} onClick={nextFn} >
          <div class="button-text" style={{ color: "#D1D1D1" }} >
            {button_text}
          </div>
        </div>
      )
    }
  };

  return (
    <div className="subscribe-outer-container page-background">
      {!loading && !paymentMethodResponse.completed && (
        <div className="subscribe-title-container">
          {/* BACK BUTTON */}
          <div className="back-button-container" onClick={handleBackButton}>
            <img
              className="back-button-icon"
              src={DownwardArrowIcon}
              alt="back-button-icon"
            />
            <div className="filter-button-text" style={{ fontWeight: "bold" }}>
              {SubscribePageInfo.backButtonText}
            </div>
          </div>

          <div className="subscribe-inner-container">
            {/* TITLES */}
            <div className="subscribe-titles">
              <div className="card-title-content">{stepContent?.title}</div>
              <div className="vulavula-paragraph">{stepContent?.subtitle}</div>
            </div>

            {/* STEP INDICATOR */}
            {getStepper()}
          </div>
        </div>
      )}

      {
        <div className="subscribe-content-container">
          {/* CARD */}
          <CustomCard cardDetails={card} cardSize="large" index={0} />

          {loading && ( // PROGRESS BAR
            <div className="subscribe-progress-bar-container">
              <div className="subscribe-main-progress-bar">
                <ProgressBar processProgress={progress} />
              </div>
            </div>
          )}

          {!paymentMethodResponse.completed && !loading && (
            // FEATURE SECTION
            <div className="feature-section-container">
              {/* TITLES */}
              {titles && (
                <div className="feature-section-titles">
                  {titles.title && (
                    <div
                      className="vulavula-card-heading-light"
                      style={{ fontWeight: "bold" }}
                    >
                      {titles.title}
                    </div>
                  )}

                  {titles.subtitle && (
                    <div className="vulavula-paragraph">{titles.subtitle}</div>
                  )}
                </div>
              )}

              {/* CONTENT */}
              {currentStep === 0 || currentStep === 1 ? getFeatureContent() : null}

              {/* PAYMENT METHOD */}
              {currentStep === 2 && getSelectPaymentContent()}
              
              {/* PAYMENT FORM */}
              <Elements stripe={stripePromise}>
                <PaymentForm 
                  onSubmit={setSubmitHandler} 
                  showForm={currentStep === 3} 
                  sessionToken={sessionToken} 
                  selectedDetails={{selectedPlan, selectedPlanPrice, selectedPaymentMethod}} 
                  setPaymentFormCompleted={setPaymentFormCompleted} 
                  nextFn={handleNextStep}
                  setPaymentMethodResponse={setPaymentMethodResponse}
                  />
              </Elements>

              {/* ACTION BUTTON */}
              { ( currentStep < 3 ) ? NextButton(enableNext, currentStep) : null }
            </div>
          )}

          {/* PAYMENT METHOD RESPONSE */}
          {paymentMethodResponse.completed && getPaymentCompleteContent()}
        </div>
      }
    </div>
  );
};

const SubscribePageWithEffect = (props) => {
  const { globalState, navigate } = props;
  const { enableBilling } = globalState;
  useEffect(() => {
    const sessionToken = getCookie("sessionToken");
    if (!enableBilling.enable || !sessionToken) {
      navigate(HOME);
    }
  }, [navigate, enableBilling.enable]);

  return <SubscribePage {...props} />;
};

export default SubscribePageWithEffect;
