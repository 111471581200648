import React, { Component } from "react";
import "./WelcomeBackground.css";
import "../../styles/type-scale-system.css";
import { WelcomeInfo } from "../../cms/generalCMS";
import Star from "../../icons/star.svg";
import GreenPlanet from "../../icons/green-planet.svg";
import RedPlanet from "../../icons/red-planet.svg";
import YellowPlanet from "../../icons/yellow-planet.svg";
import LelapaTreeNoPlanets from "../../icons/lelapa-tree-no-planets.svg";
import { SIGNUP } from "../../utils/constants";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { Barlow20Text } from "../../styles/global-style";

class WelcomeBackground extends Component {
  render() {
    let { isLoggedIn, isMobile } = this.props.globalState;

    return (
      <div className="welcome-container">
        {isMobile ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            {/* TITLES */}
            <div className="welcome-title-container">
              <div className="yellow-planet-star-container">
                <img
                  className="star-planet-container"
                  src={Star}
                  alt="star"
                  height="50px"
                  width="50px"
                />
                <img
                  className="yellow-planet-container"
                  src={YellowPlanet}
                  alt="yellow-planet"
                  width="250px"
                />
              </div>
              <div className="title-image-container">
                <div
                  className="vulavula-heading-3"
                  style={{ textAlign: "center", width: "88%" }}
                >
                  {WelcomeInfo.title}
                </div>
              </div>

              <div className="subtitle-container">
                <div
                  className="vulavula-mobile-paragraph"
                  style={{ textAlign: "center", width: "90%" }}
                >
                  {WelcomeInfo.subtitle}
                </div>
              </div>

              <div className="red-planet-star-container">
                <img
                  className="red-planet-container"
                  src={RedPlanet}
                  alt="red planet"
                  width="100px"
                  height="100px"
                />
              </div>
            </div>

            {/* IMAGES */}
            <div className="illustrations-container">
              <img
                className="tree-illustration-container"
                src={LelapaTreeNoPlanets}
                alt="lelapa tree no planet"
              />
            </div>

            {/* CTA */}
            <div className="welcome-button-container">
              <Button
                className="button-primary-red-larger full-width"
                variant="contained"
                sx={Barlow20Text}
                onClick={() =>
                  isLoggedIn
                    ? this.props.handleFocusOnAPIKeyCard()
                    : this.props.navigate(SIGNUP)
                }
              >
                {isLoggedIn
                  ? WelcomeInfo.getAPIKeyButtonText
                  : WelcomeInfo.signUpButtonText}
              </Button>
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", width: "100%" }}>
            {/* TITLES */}
            <div className="welcome-title-container">
              <div className="title-image-container">
                <div className="vulavula-heading-1">{WelcomeInfo.title}</div>
                <div className="yellow-planet-star-container">
                  <img
                    className="star-planet-container"
                    src={Star}
                    alt="star"
                    height="70px"
                    width="100px"
                  />
                  <img
                    className="yellow-planet-container"
                    src={YellowPlanet}
                    alt="yellow-planet"
                    width="290px"
                  />
                </div>
              </div>

              <div className="vulavula-card-heading">
                {WelcomeInfo.subtitle}
              </div>

              <div className="welcome-button-container">
                <div
                  className="button-primary-red-larger barlow-20-light"
                  onClick={() =>
                    isLoggedIn
                      ? this.props.handleFocusOnAPIKeyCard()
                      : this.props.navigate(SIGNUP)
                  }
                >
                  {isLoggedIn
                    ? WelcomeInfo.getAPIKeyButtonText
                    : WelcomeInfo.signUpButtonText}
                </div>
              </div>
            </div>

            {/* IMAGES */}
            <div className="illutstaration-outer-container">
              <div className="illustrations-container">
                <img
                  className="tree-illustration-container"
                  src={LelapaTreeNoPlanets}
                  alt="lelapa tree no planet"
                  height="400px"
                />
                <div className="red-planet-star-container">
                  <img
                    className="red-planet-container"
                    src={RedPlanet}
                    alt="red planet"
                    width="100px"
                    height="100px"
                  />
                  <img
                    className="red-planet-star"
                    src={Star}
                    alt="second star"
                    width="50px"
                    height="50px"
                  />
                </div>
              </div>

              <div className="green-planet-container">
                <img src={GreenPlanet} alt="green planet" width="260px" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

WelcomeBackground.propTypes = {
  navigate: PropTypes.func,
  globalState: PropTypes.object,
  handleFocusOnAPIKeyCard: PropTypes.func,
};

export default WelcomeBackground;
