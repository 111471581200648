import { appConfig } from "../../config";
import { kycInfo } from "../../cms/generalCMS";
import { vulaApi, handleErrorMessage } from "./apiHelper";
import { ACTION_AREA } from "../../utils/constants";


export const handleGetKYCStatus = (sessionToken, dispatch) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.LOGIN);
      return false;
    }

    return vulaApi
      .get(`${appConfig.VULAVULA_KYC_GET_API_URL}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.LOGIN);
          return [];
        }

        return response.data === null;
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.LOGIN);
        }

        return true;
      });
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.LOGIN
    );
    return false;
  }
};

export const handleKYCSubmit = (results, sessionToken, dispatch) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.LOGIN);
      return;
    }

    let data = {};
    for (let i = 0; i < kycInfo.questions.length; i++) {
      const question = kycInfo.questions[i];
      const questionAnswers = results[i];
      data[question.heading] = questionAnswers;
    }

    return vulaApi
      .post(
        `${appConfig.VULAVULA_KYC_CREATE_API_URL}`,
        JSON.stringify({ data: data }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.LOGIN);
          return;
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage(error.message, 500, dispatch, ACTION_AREA.LOGIN);
        }

        return;
      });
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.LOGIN
    );
  }
};