import React, { Component } from "react";
import PropTypes from "prop-types";
import AudioVisualiser from "../audioVisualiser/AudioVisualiser";

class AudioAnalyser extends Component {
  constructor(props) {
    super(props);
    this.state = { audioData: new Uint8Array(0) };
    this.tick = this.tick.bind(this);
  }

  componentDidMount() {
    this.audioContext = new (window.AudioContext ||
      window.webkitAudioContext)(); // using webkitAudioContext because safari does not recognize plain audio context
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 128;
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    this.source = this.audioContext.createMediaStreamSource(this.props.audio);
    this.source.connect(this.analyser);
    this.rafId = requestAnimationFrame(this.tick);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.rafId);
    this.analyser.disconnect();
    this.source.disconnect();
  }

  tick() {
    this.analyser.getByteTimeDomainData(this.dataArray);
    this.setState({ audioData: this.dataArray });
    this.rafId = requestAnimationFrame(this.tick);
  }

  render() {
    return (
      <AudioVisualiser
        audioData={this.state.audioData}
        analyser={this.analyser}
      />
    );
  }
}

AudioAnalyser.propTypes = {
  audio: PropTypes.any,
  canvasRef: PropTypes.any,
};
export default AudioAnalyser;
