import React, { createContext, useReducer, useContext } from "react";
import { getCookie } from "./cookie";

const initialState = {
  playgroundSession: { data: "", fetched: false },
  sessionToken: "",
  isLoggedIn: false,
  requestClientToken: {
    id: 0,
    token: "",
    expiration_time: "",
    fetched: false,
    error: false,
  },
  userApiKeys: { data: [], fetched: false },
  errorDetails: { errorMessage: "", failureArea: "unknown" },
  showTermsModal: { show: false, navigation: "/" },
  isMobile: false,
  showRateServiceModal: { show: false },
  recentlyRegistered: false,
  cookieConsent: getCookie("cookie_consent"),
  currentUserInvoice: { data: null, error: false, fetched: false },
  userInvoices: { data: [], error: false, fetched: false },
  userPaymentMethods: { data: [], error: false, fetched: false },
  userSubscriptions: { data: [], error: false, fetched: false },
  productPlans: { data: null, error: false, fetched: false },
  addingPaymentMethod: false,
  enableBilling: { enable: false, error: false, fetched: false },
};

const GlobalStateContext = createContext();
const GlobalDispatchContext = createContext();

const globalReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_PLAYGROUND_SESSION_TOKEN":
      return { ...state, playgroundSession: action.payload };
    case "UPDATE_SESSION_TOKEN":
      return {
        ...state,
        sessionToken: action.payload,
        isLoggedIn: action.payload !== "" && action.payload !== null,
        requestClientToken: state.requestClientToken?.id !== 0 && {
          id: 0,
          token: "",
          expiration_time: "",
        },
      };
    case "UPDATE_REQUEST_CLIENT_TOKEN":
      return { ...state, requestClientToken: action.payload };
    case "UPDATE_USER_API_KEYS":
      return { ...state, userApiKeys: action.payload };
    case "UPDATE_ERROR_MESSAGE":
      return { ...state, errorDetails: action.payload };
    case "UPDATE_SHOW_TERMS_MODAL":
      return { ...state, showTermsModal: action.payload };
    case "UPDATE_IS_MOBILE":
      return { ...state, isMobile: action.payload };
    case "UPDATE_SHOW_RATE_SERVICE_MODAL":
      return { ...state, showRateServiceModal: action.payload };
    case "UPDATE_REGISTRATION_STATUS":
      return { ...state, recentlyRegistered: action.payload };
    case "UPDATE_COOKIE_CONSENT":
      return { ...state, cookieConsent: action.payload };
    case "UPDATE_CURRENT_USER_INVOICE":
      return { ...state, currentUserInvoice: action.payload };
    case "UPDATE_USER_INVOICES":
      return { ...state, userInvoices: action.payload };
    case "UPDATE_USER_PAYMENT_METHODS":
      return { ...state, userPaymentMethods: action.payload };
    case "UPDATE_USER_SUBSCRIPTIONS":
      return { ...state, userSubscriptions: action.payload };
    case "UPDATE_ADDING_PAYMENT_METHOD":
      return { ...state, addingPaymentMethod: action.payload };
    case "UPDATE_PRODUCT_PLANS":
      return { ...state, productPlans: action.payload };
    case "UPDATE_ENABLE_BILLING":
      return { ...state, enableBilling: action.payload };
    default:
      return state;
  }
};

// Create context provider
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

// Custom hook to access state
export const useGlobalState = () => {
  return useContext(GlobalStateContext);
};

// Custom hook to access dispatch
export const useGlobalDispatch = () => {
  return useContext(GlobalDispatchContext);
};
