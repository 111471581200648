import CustomForm from "../../../components/form/CustomForm";
import React, { useState } from "react";
import { generalErrors, resetPasswordInfo } from "../../../cms/generalCMS";
import { handleErrorMessage } from "../../../helpers/api/apiHelper";
import { submitPasswordReset } from "../../../helpers/api/auth"
import { ACTION_AREA, RESET_PASSWORD_SUCCESS, SIGNIN } from "../../../utils/constants";
import { isValidEmail, validatePassword } from "../../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PasswordResetMissingDetails } from "../../../components/auth";

export const ResetPassword = ({ globalState: { errorDetails }, dispatch }) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    password: "",
    confirmationPassword: ""
  })
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("")
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState("")
  const [validate, setValidate] = useState(false)
  const [errorMessage, setErrorMessage] = useState(errorDetails.errorMessage);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get("email");
  const tokenParam = searchParams.get("token");
  const email = emailParam ? decodeURIComponent(emailParam) : '';
  const token = emailParam ? decodeURIComponent(tokenParam) : '';

  if (!email || !isValidEmail(email) || !token) {
    return <PasswordResetMissingDetails
      message={resetPasswordInfo.missingDetailsText}
    />;
  }

  const handlePasswordUpdate = (e) => {
    const { name, value } = e.target;
    setInputs(prev => ({ ...prev, [name]: value }));

    if (errorMessage !== "") {
      setErrorMessage("");
      handleErrorMessage("", 0, dispatch, ACTION_AREA.RESET_PASSWORD); //clear error message when editing password
    }

    if (name === "password") {
      const errors = validatePassword(value);
      if (errors.length > 0) {
        setPasswordErrorMsg(errors.join(", "));
        setValidate(true);
      } else {
        setPasswordErrorMsg("");
      }
    } else if (name === "confirmationPassword" && inputs.password !== value) {
      setConfirmPasswordErrorMsg(resetPasswordInfo.passwordMismatchError);
      setValidate(true);
    } else {
      setConfirmPasswordErrorMsg("");
      setValidate(false);
    }
  }

  const handleValidatePasswords = () => {
    if (inputs.password !== inputs.confirmationPassword) {
      setConfirmPasswordErrorMsg(resetPasswordInfo.passwordMismatchError);
      setValidate(true);
      return false;
    }
    const errors = validatePassword(inputs.password);
    if (errors.length > 0) {
      setPasswordErrorMsg(errors.join(" "));
      setValidate(true);
      return false
    }

    setPasswordErrorMsg("");
    setValidate(false);
    return true
  }

  const handleResetPassword = async () => {
    if (!handleValidatePasswords()) {
      return false;
    }
    setIsLoading(true);
    try {
      const response = await submitPasswordReset(token, inputs.password)
      if (response) {
        navigate(RESET_PASSWORD_SUCCESS);
      }
    } catch (error) {
      setPasswordErrorMsg(generalErrors.apiSyntax);
    } finally {
      setIsLoading(false);
    }
  }

  const getResetPasswordDetails = () => {
    let inputFields = [
      {
        validate: validate,
        value: inputs.password,
        placeholder: "Password",
        type: "password",
        name: "password",
        valueErrorMsg: passwordErrorMsg,
        handleFieldUpdate: handlePasswordUpdate,
        metaInfo: resetPasswordInfo.passwordMetaInfo
      },
      {
        validate: validate,
        value: inputs.confirmationPassword,
        placeholder: "Confirm Password",
        type: "password",
        name: "confirmationPassword",
        valueErrorMsg: confirmPasswordErrorMsg,
        handleFieldUpdate: handlePasswordUpdate,
        metaInfo: resetPasswordInfo.confirmationPasswordMetaInfo
      },
    ];

    let primaryButtons = [
      {
        buttonTextColor: inputs.password === "" || validate ? "#B0B0B0" : '#fff',
        backgroundColor:
          inputs.password === "" || validate ? "transparent" : "#cc4a2b",
        text: isLoading ? resetPasswordInfo.resetPasswordButtonLoadingText : resetPasswordInfo.resetPasswordButtonText,
        handleButtonAction: handleResetPassword,
      },
    ];

    let secondaryButtons = [
      {
        enabled: true,
        text: resetPasswordInfo.signInButtonText,
        handleButtonAction: () => navigate(SIGNIN),
      },
    ];

    return { inputFields, primaryButtons, secondaryButtons };
  }

  const details = getResetPasswordDetails();

  return (
    <div className="v-w-full v-d-flex  v-justify-center v-align-center">
      <div className="login-container v-w-70 v-text-center">
        <div className="v-d-flex v-flex-column v-mb-5">
          <div className="vulavula-card-heading v-mb-3">{resetPasswordInfo.title}</div>
          <div className="vulavula-card-subheading">{resetPasswordInfo.subtitle}</div>
        </div>

        <div className="login-content">
          <CustomForm
            inputFields={details.inputFields}
            primaryButtons={details.primaryButtons}
            secondaryButtons={details.secondaryButtons}
          />
        </div>
      </div>
    </div>
  )
}