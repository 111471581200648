export const HOME = "/";
export const TERMSANDCONDITIONS = "https://lelapa.ai/privacy-policy/";
export const DASHBOARD = "/dashboard";
export const APIKEYS = "/api-keys";
export const SIGNUP = "/sign-up";
export const TRANSCRIBE = "/transcribe";
export const SIGNIN = "/sign-in";
export const FORGOT_PASSWORD = "/forgot-password";
export const FORGOT_PASSWORD_EMAIL_SENT = "/forgot-password-email-sent";
export const RESET_PASSWORD_CODE = "/reset-password-code";
export const RESET_PASSWORD = "/reset-password";
export const RESET_PASSWORD_SUCCESS = "/reset-password-success";
export const KYC = "/sign-up/kyc";
export const ENTITY_RECOGNITION = "/entity-recognition";
export const SENTIMENT_ANALYSIS = "/sentiment-analysis";
export const BILLING = "/billing";
export const BILLING_PROCESS = "/billing/subscribe-process";
export const STRIPE_PLANS_PAGE = "/billing/stripe-plans";

export const API_KEYS_TABLE_MAX_ITEMS = 3;

export const SOCIALSTYPE = {
  INSTAGRAM: "instagram",
  LINKEDIN: "linkedIn",
  X: "X/Twitter",
};

export const ACTIONTYPE = {
  RECORDING: "recording",
  STOP_RECORDING: "stop_recording",
  TRANSCRIBE: "transcribe",
  TRANSCRIBING: "transcribing",
};

export const LANGUAGETYPE = {
  ISIZULU: "IsiZulu",
  ENGLISH: "English",
  AFRIKAANS: "Afrikaans",
  SESOTHO: "SeSotho",
  SETSWANA: "Setswana",
};

export const LANGUAGE_ID_TYPE = {
  ISIZULU: "zul",
  ENGLISH: "eng",
  AFRIKAANS: "afr",
  SESOTHO: "sot",
  SETSWANA: "set",
};

export const DEFAULT_ERROR_MESSAGE = {
  REFRESH: "Please refresh page.",
  SOMETHING_WENT_WRONG: "Something went wrong, please try again",
  RETRY: "Please try again later",
  MODEL_ASLEEP:
    "Our models are waking up from a quick snooze, sorry for the wait! Your next request will be faster, we promise.",
};

export const PRODUCTTYPE = {
  UNKOWN: "Unknown",
  TRANSCRIBE: "Transcribe",
  ENTITY_RECOGNITION: "Entity_recognition",
  SENTIMENTAL_ANALYSIS: "Sentimental_analysis",
};

export const API_KEY_STATUS = {
  ACTIVE: "Active",
  EXPIRED: "Expired",
};

export const MONTHS_SHORT_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const SORT_DIRECTION = {
  ASCENDING: "Ascending",
  DESCENDING: "Descending",
};

export const ENTITY_TYPE = {
  ORGANISATION: "organisation",
  LOCATION: "location",
  PERSON: "person",
  DATE: "date",
};

export const SENTIMENT = {
  POSITIVE: "positive",
  NEGATIVE: "negative",
  NEUTRAL: "neutral",
};

export const ACTION_AREA = {
  UNKNOWN: "unknown",
  TRANSCRIBE: "transcribe",
  ENTITY_RECOGNITION: "entity_recognition",
  SENTIMENT_ANALYSIS: "sentiment_analysis",
  LOGIN: "login",
  SIGNUP: "sign_up",
  FORGOT_PASSWORD: "forgot_password",
  FORGOT_PASSWORD_CODE: "forgot_password_code",
  RESET_PASSWORD: "reset_password",
  DASHBOARD: "dashboard",
  BILLING: "billing",
};

export const MOBILE_MAX_SIZE = 1007;

export const MIXED_SENTIMENT_TEXT = "Mixed Sentiment";

export const TABLE_MAX_ITEMS = 3;
export const BILLING_INVOICE_STATUS = {
  OPEN: "open",
  PAID: "paid",
  OVER_DUE: "overdue",
};

export const CURRENCY_TYPE_SYMBOLS = {
  ZAR: "R",
  USD: "$",
  EUR: "€",
};

export const CURRENCY_TYPE = {
  ZAR: "zar",
  USD: "usd",
  EUR: "eur",
};

export const BILLING_CYCLE_INTERVAL = {
  MONTH: "month",
  YEAR: "year",
  ONE_TIME: "one-time",
};

export const BILLING_CYCLE_TYPE = {
  RECURRING: "Recurring",
  ONCE_OFF: "Once-off",
};
