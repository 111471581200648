import React, { Component, createRef } from "react";
import "../../styles/type-scale-system.css";
import "./LandingPage.css";
import WelcomeBackground from "../../components/welcomeBackground/WelcomeBackground";
import {
  landingParagraph,
  transcribeCardText,
  entityRecognitionText,
  sentimentAnalysisText,
  apiKeyGenCardDetails,
} from "../../cms/generalCMS";
import Paragraph from "../../components/paragraph/Paragraph";
import Tile from "../../components/tile/Tile";
import Pattern1 from "../../icons/pattern-1.svg";
import Pattern2 from "../../icons/pattern-2.svg";
import Pattern4 from "../../icons/pattern-4.svg";
import Card from "../../components/card/Card";
import PropTypes from "prop-types";
import {
  DASHBOARD,
  SIGNUP,
  TRANSCRIBE,
  ENTITY_RECOGNITION,
  SENTIMENT_ANALYSIS,
} from "../../utils/constants";
import {
  handleCopyValue,
} from "../../helpers/utils";
import { createClientToken } from "../../helpers/api/auth"
import { getCookie } from "../../helpers/cookie"
import { appConfig } from "../../config";
import YellowGreenPlanet from "../../icons/yellow-green-planet.svg";
import Star from "../../icons/star.svg";
import LightGreenPlanet from "../../icons/light-green-planet.svg";
import GreenPlanet from "../../icons/green-planet.svg";

class LandingPage extends Component {
  constructor(props) {
    super(props);

    const { userApiKeys, isLoggedIn, showTermsModal, isMobile } =
      this.props.globalState;

    this.state = {
      showModal: showTermsModal.show,
      isLoggedIn: isLoggedIn,
      hasHistory: false,
      generatedKey: "",
      userApiKeys: userApiKeys.data,
      isMobile: isMobile,
    };
    this.apiKeyCardRef = createRef();

    this.handleAPIKeyCardActionButton =
      this.handleAPIKeyCardActionButton.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState !== this.props.globalState) {
      const { userApiKeys, isLoggedIn, showTermsModal, isMobile } =
        this.props.globalState;
      this.setState({
        isLoggedIn: isLoggedIn,
        userApiKeys: userApiKeys.data,
        showModal: showTermsModal.show,
        isMobile: isMobile,
      });
    }
  }

  async handleGenerateApiKey() {
    const sessionToken = getCookie("sessionToken");
    await createClientToken(sessionToken, this.props.dispatch, false).then(
      (resp) => {
        if (resp !== "") {
          this.setState({
            generatedKey: resp.token,
            hasHistory: true,
          });
        }
      }
    );
  }

  handleAPIKeyCardActionButton() {
    let { navigate } = this.props || {};

    if (this.state.isLoggedIn && this.state.hasHistory) {
      navigate(DASHBOARD);
    } else if (this.state.isLoggedIn) {
      this.handleGenerateApiKey();
    } else {
      navigate(SIGNUP);
    }
  }

  getAPIKeyCardDetails() {
    const { generatedKey, isLoggedIn, hasHistory, userApiKeys } = this.state;
    let details = {
      heading: "",
      body: "",
      buttonText: "",
      apiKeyPlaceholder: apiKeyGenCardDetails.apiKeyPlaceholder,
      newlyGeneratedKey: generatedKey,
      warningChip: null,
      copiedText: apiKeyGenCardDetails.copiedText,
    };

    if (isLoggedIn) {
      details.heading = apiKeyGenCardDetails.headingSignedIn;
      details.body = apiKeyGenCardDetails.bodySignedIn;
      details.buttonText = apiKeyGenCardDetails.generateButtonText;

      if (userApiKeys?.length >= appConfig.MAX_API_KEYS) {
        details.warningChip = apiKeyGenCardDetails.warningChipText;
      }
      if (hasHistory) {
        details.buttonText = apiKeyGenCardDetails.viewHistoryButtonText;
      }
    } else {
      details.heading = apiKeyGenCardDetails.headingSignedOut;
      details.body = apiKeyGenCardDetails.bodySignedOut;
      details.buttonText = apiKeyGenCardDetails.signupButtonText;
    }

    return details;
  }

  getCardTilesInfo() {
    let cardInfos = [
      {
        id: 1,
        icon: Pattern1,
        title: transcribeCardText.heading,
        subtitle: transcribeCardText.subtitle,
        buttonText: transcribeCardText.buttonText,
        buttonAction: "toggle-modal",
        navigation: TRANSCRIBE,
        enabled: true,
      },
      {
        id: 2,
        icon: Pattern2,
        title: entityRecognitionText.heading,
        subtitle: entityRecognitionText.subtitle,
        buttonText: entityRecognitionText.buttonText,
        buttonAction: "toggle-modal",
        navigation: ENTITY_RECOGNITION,
        enabled: true,
      },
      {
        id: 3,
        icon: Pattern4,
        title: sentimentAnalysisText.heading,
        subtitle: sentimentAnalysisText.subtitle,
        buttonText: sentimentAnalysisText.buttonText,
        buttonAction: "toggle-modal",
        navigation: SENTIMENT_ANALYSIS,
        enabled: true,
      },
    ];

    return cardInfos;
  }

  handleCopyToken = () => {
    const { generatedKey } = this.state;

    if (generatedKey !== "") {
      var keyDiv = document.getElementById("copied-text");
      keyDiv.className = "vulavula-paragraph show-copy-text";
      setTimeout(function () {
        keyDiv.className = "hide-copy-text";
      }, 3000);

      handleCopyValue(generatedKey);
    }
  };

  handleFocusOnAPIKeyCard = () => {
    this.apiKeyCardRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    let { showModal, isLoggedIn, hasHistory, isMobile } = this.state;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <WelcomeBackground
          navigate={this.props.navigate}
          globalState={this.props.globalState}
          handleFocusOnAPIKeyCard={this.handleFocusOnAPIKeyCard}
        />
        <div
          style={{
            display: "flex",
            maxWidth: "1500px",
            flexDirection: "column",
          }}
        >
          <Paragraph
            text={landingParagraph.text}
            styleClassName="vulavula-paragraph-large-light"
          />
          {isMobile && (
            <div className="planet-green-container">
              <img src={GreenPlanet} alt="green planet" width="100px" />
            </div>
          )}
          <div className="stars-yellow-planet-container">
            <img
              className="star-1-container"
              src={Star}
              alt="star 1"
              width="30px"
            />
            <img
              className="yellow-green-planet-container"
              src={YellowGreenPlanet}
              alt="yellow green planet"
            />

            {!isMobile && (
              <img
                className="star-2-container"
                src={Star}
                alt="star 2"
                width="40px"
              />
            )}
          </div>

          <div
            className="tiles-card-container"
            style={
              isLoggedIn
                ? { flexDirection: "column-reverse" }
                : { flexDirection: "column" }
            }
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tile
                items={this.getCardTilesInfo()}
                showModal={showModal}
                dispatch={this.props.dispatch}
                navigate={this.props.navigate}
              />
            </div>

            <div
              ref={this.apiKeyCardRef}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                cardDetails={this.getAPIKeyCardDetails()}
                isLoggedIn={isLoggedIn}
                hasHistory={hasHistory}
                buttonAction={this.handleAPIKeyCardActionButton}
                globalState={this.props.globalState}
                copyAction={this.handleCopyToken}
                isMobile={isMobile}
              />
            </div>
          </div>
          <div className="light-green-planet-container">
            <img
              className="light-green-planet"
              src={LightGreenPlanet}
              alt="light green planent"
              width={isMobile ? "50px" : "180px"}
              height={isMobile ? "50px" : "180px"}
            />

            {!isMobile && (
              <img
                className="star-3-container"
                src={Star}
                alt="star 3"
                width="60px"
                height="60px"
              />
            )}
            {!isMobile && (
              <img
                className="star-4-container"
                src={Star}
                alt="star 4"
                width="80px"
                height="80px"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

LandingPage.propTypes = {
  navigate: PropTypes.func,
  globalState: PropTypes.object,
  dispatch: PropTypes.func,
};

export default LandingPage;
