import {
  HOME,
  SOCIALSTYPE,
  TRANSCRIBE,
  ENTITY_RECOGNITION,
  DASHBOARD,
  SENTIMENT_ANALYSIS,
} from "../utils/constants";
import Pattern1Grey from "../icons/pattern-1-grey.svg";
import Pattern4Grey from "../icons/pattern-4-grey.svg";
import Pattern5 from "../icons/pattern-5.svg";
import Pattern4LightGrey from "../icons/pattern-4-light-grey.svg";
import DownwardArrowIcon from "../icons/downwards-arrow-icon.svg";
import HomeIcon from "../icons/home-icon.svg";
import LinkIcon from "../icons/link-icon.svg";
import SoundIcon from "../icons/sound-icon.svg";
import SmileIcon from "../icons/smile-icon.svg";
import LightbulbIcon from "../icons/lightbulb-icon.svg";
import KeyIcon from "../icons/key-icon.svg";
import {appConfig} from "../config";

export const headerInfo = {
  brandName: "VULAVULA",
  poweredTagText: "powered by",
  playgroundText: "PLAYGROUND",
  apiKeysText: "API KEYS",
  logoutText: "LOGOUT",
  loginText: "LOGIN",
  signupText: "SIGN UP",
  billingText: "BILLING",
  playgroundOptions: [
    { name: "transcription", navigation: "/transcribe" },
    { name: "entity recognition", navigation: "/entity-recognition" },
    { name: "sentiment analysis", navigation: "/sentiment-analysis" },
  ],
  burgerMenuOptions: [
    { icon: HomeIcon, title: "Home", navigation: HOME, enabled: true },
    {
      icon: LinkIcon,
      title: "Transcription",
      navigation: TRANSCRIBE,
      enabled: true,
    },
    {
      icon: SoundIcon,
      title: "Entity recognition",
      navigation: ENTITY_RECOGNITION,
      enabled: true,
    },
    {
      icon: SmileIcon,
      title: "Sentimenal analysis",
      navigation: SENTIMENT_ANALYSIS,
      enabled: true,
    },
    {
      icon: KeyIcon,
      title: "API Keygen",
      navigation: DASHBOARD,
      enabled: true,
    },
    {
      icon: LightbulbIcon,
      title: "Learn more",
      navigation: "/", // TODO: replace with correct path
      enabled: true,
    },
  ],
};

export const WelcomeInfo = {
  title: "Unlock the World with VulaVula!",
  subtitle:
    "Dive deep into the realm of Natural Language Processing, tailored uniquely for under-represented languages.",
  signUpButtonText: "SIGN ME UP",
  getAPIKeyButtonText: "GET YOUR API KEY",
};

export const landingParagraph = {
  text: "Vulavula - Africa’s best language models! We’ve built an experiment. You should try it, it’s free. <br> <br> Africa’s most popular languages. Instantly transcribed and analysed for easy understanding. Seamlessly integrated for nuanced chatbot conversations. Currently available in <b>English, Afrikaans, isiZulu and Sesotho</b>. <br> Other official South African languages and a bunch of Sub-Saharan languages coming soon.<br> If you find any goggos (bugs) please pop us a mail at <a  href='mailto:vulavulasupport@lelapa.ai'>vulavulasupport@lelapa.ai</a> <br> <b> BEST VIEWED ON DESKTOP</b>",
};
export const transcribeCardText = {
  heading: "Transcription",
  subtitle:
    "Easily Convert South African Voice Into Written Text. <br> <b>isiZulu, Sesotho, Afrikaans, South African English</b>",
  buttonText: "TRY NOW",
};
export const entityRecognitionText = {
  heading: "Entity Recognition",
  subtitle:
    "Quickly spot important people, places, brands, values and more. <br> <b>Multilingual</b>",
  buttonText: "TRY NOW",
};
export const sentimentAnalysisText = {
  heading: "Sentiment Analysis",
  subtitle:
    "Quickly understand whether the emotional tone of isiZulu text is positive, negative, or neutral. <br> <b>isiZulu</b>",
  buttonText: "TRY NOW",
};

export const termsAndConditionsModalText = {
  heading: "Lets get started",
  body: "Simply click 'I accept' after reviewing our privacy policy to responsibly enjoy the Vulavula Playground",
  checkboxSplit1: "I accept the ",
  checkboxSplit2: "Privacy Policy",
  checkboxSplit3: " of Vulavula",
  buttonText: "START PLAYING",
};

// TODO: MOVE THIS TO THE DB.
export const termsAndConditionsPageDetails = {
  heading: "TERMS OF USE FOR THE VULAVULA PLAYGROUND",
  sections: [
    {
      body:
        "These terms and conditions and any documents made reference to herein (<b>“Terms of Use”</b>) orm a legal agreement between you, (<b>“you”</b> and <b>“your”</b>), and Lelapa AI (Pty) Ltd. (<b>“Lelapa AI”</b>, <b>“we”</b>, <b>“us”</b> and <b>“our”</b>) and our affiliates and group companies. The Terms of Use apply to the demonstration services available on the website where these Terms of Use are presented, including any Lelapa AI Intellectual Property (as defined below) therein (the <b>“Vulavula Playground”</b>) <br> <br>" +
        "We propose that we provide you with the opportunity to try our services including our AI enabled transcription tool, entity recognition tool, and sentiment analysis tool. The access alternatively the use of the Vulavula Playground further alternatively confirmation of acceptance or consent to these Terms of Use will constitute confirmation that you have read, understood and consented to be bound by the aforementioned Terms of Use. Should you refuse to consent to the aforementioned Terms of Use, you will be refused access to the use and enjoyment of the Vulavula Playground. You may make use of the Vulavula Playground in two manners: <br>" +
        "<ol type='A'>" +
        "<li>Online use via the website, which use will not require you to log in. The hyperlink is as follows <a href='https://www.lelapa.ai' target='_blank' rel='noreferrer'>https://lelapa.ai</a> ; or</li>" +
        " <li>Use of the rate limited free trial, which shall provide you with use and enjoyment of our API. This will require you to log in.</li>" +
        " </ol>" +
        "Any and all Personal Information collected through the use of the Vulavula Playground in respect of yourselves alternatively any other data subjects will be subject to the Privacy Policy hereby incorporated into these Terms of Use. The online use of the Vulavula Playground does not directly result in the collection of Personal Information in respect of you alternatively other data subjects by Lelapa AI however, there is a possibility that Personal Information may be contained in your inputs when making use of the tools offered in these Terms of Use. Personal Information in relation to your use of the website will be collected via cookies. The Cookie Policy is incorporated into these Terms of Use by reference. Should you fail to consent to these Terms of Use alternatively the Cookie Policy further alternatively the Privacy Policy, you may not access and/or use the Vulavula Playground and/or the API. <br><br>" +
        "Lelapa AI reserves the right to change alternatively remove the Vulavula  Playground in part or in whole on the website or API at their sole discretion without further notice to you. Further to this, Lelapa AI reserves the right to restrict access to all alternatively part of the Vulavula Playground and API to a subset of users. Where all alternatively any part of the Vulavula Playground and/or the API is unavailable at any time, for any period, Lelapa AI will not be held responsible and/or liable for any consequences stemming therefrom. <br><br" +
        "Use and enjoyment of the Vulavula Playground and/or API is restricted to the exclusive testing and evaluation of the Lelapa AI products and services for non-commercial purposes. <br><br>" +
        "Use of the Vulavula Playground and/or API is conditional upon providing certain registration details and/or other information. Accordingly, you warrant that all information requested and provided by yourself is correct, current, completed and that you have the necessary rights thereto. <br><br>" +
        "You are solely responsible to Lelapa AI for all activities engaged in when accessing and registering the Vulavula Playground and/or API. Lelapa AI reserves the unconditional right to disable, at any time, any username and/or password for any reason including but not limited to failure to comply with any provision of these Terms of Use. Such unconditional right to disable on the basis of failure to comply with these Terms of Use alternatively any other reason will be at the sole discretion of Lelapa AI. ",
    },
    {
      title: "RESTRICTIONS",
      body:
        "The use and enjoyment of the Vulavula Playground and/or API is contingent upon your agreement to not access alternatively use (and not permit others to access alternatively use) Lelapa AI’s Intellectual Property in any manner as follows: <br>" +
        "<ul" +
        "<li>For the purposes of product evaluation;</li>" +
        "<li>Personal use;</li> " +
        "<li>Processing, using, transmiting and/or introducing any confidential alternatively sensitive information of any kind, including sensitive personal data and/or personal data relating to children;</li>" +
        "<li>Licensing, sub-licensing, selling, re-selling, renting, leasing, transferring, distributing or time sharing or otherwise making same available for access by third parties.</li>" +
        "<li>To disassemble, reverse engineer, decompile or modify them or otherwise create derivative works of them.</li>" +
        "<li>To access them for the purpose of developing a product or service that competes with a Lelapa AI Product</li>" +
        "<li>To use them to create, use, send, store or run viruses or other harmful computer code, files, scripts, agents or other programs or engage in any other malicious act.</li>" +
        "<li>To disrupt their security, integrity or operation.</li>" +
        "<li>To remove or modify a copyright or other rights notice in them.</li>" +
        "<li>To use them to reproduce, distribute, display, transmit or use material protected by copyright or other intellectual property right without first obtaining the permission of the owner.</li>" +
        "<li>To use them to damage the property of another.</li>" +
        "<li>That violates any applicable local or international law or regulation, or advocates, promotes or assists in any unlawful act, constitutes an illegal threat or violates export control laws.</li>" +
        "<li>That temporarily or permanently alters, erases, removes, copies, modifies, halts or disables any Lelapa AI or third party data, software or network.</li>" +
        "<li>That violates the rights of any person or entity that may give rise to civil or criminal liability under applicable laws or regulations applicable to you, another user, and/or Lelapa AI, including violation of privacy or publicity rights, infringement of any copyright, patent, trademark, trade secret or other intellectual property right, or conflicts with these Terms of Use or the Privacy Policy.</li>" +
        "<li>To transmit or introduce offensive materials, including those involving profanity, violence, sexual conduct, pornography or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>" +
        "<li>That is false, deceptive, misleading or fraudulent, including but not limited to: (1) any attempt to impersonate any person or entity, including any other user, Lelapa AI or a Lelapa AI employee; (2) to misrepresent your identity or affiliation with any person or organization; and (3) any attempt to give the impression that you are posting materials from any person or entity other than yourself if that is not the case, including but not limited to altering your IP source address.</li>" +
        "<li>To monitor or copy materials on the Vulavula playground and/or API for any unauthorised purpose or access the Vulavula playground and/or API via any automatic device, process or means of access such as a robot or spider.</li>" +
        "<li>hat may have a detrimental effect on the Vulavula’s playground and/or API function, user interaction or security, including but not limited to: (1) gaining unauthorized access to, or attempting to compromise the security of, any network, system, computing facility, equipment, data or information; (2) attempting to intercept, redirect or otherwise interfere with communications intended for others; (3) disabling, damaging overburdening or impairing the Vulavula playground and/or API or any server, computer or database connected to or accessed by the Vulavula playground and/or API; (4) modifying, blocking or otherwise interfering with the display of the Vulavula playground and/or API; (5) interfering with another user’s ability to access, use and enjoy the Vulavula playground and/or API; (6) accessing another user’s registration information or user account without that user’s express written permission; (7) transmitting or introducing any malicious or technologically harmful element to the Vulavula playground such as a spyware program, virus, Trojan horse, worm or logic bomb; (8) performing, without Lelapa AI’s express prior written authorisation, scalability testing, load testing, probing, scanning, penetration or vulnerability testing of the Vulavula playground and/or API, including without limitation the Community Services; and (9) engaging in any activities that results in any server being the target of a denial of service attack.</li>" +
        "</ul>",
    },
    {
      title: "INTELLECTUAL PROPERTY",
      body:
        "All rights, title and interest in and to all the intellectual property rights in the Lelapa AI Intellectual Property are owned exclusively by Lelapa AI and its licensors, notwithstanding any other provision in these Terms of Use or any other agreement. The Lelapa AI Intellectual Property is protected by both South African and international laws in respect of copyrights, trademarks, trade secrets and other intellectual property or proprietary rights. <br><br>" +
        "<b>“Lelapa AI Intelletual Property”</b> is defined as the information, text, displays, images, video, audio, and user interfaces included in or generated by the Vulavula Playground and/or API, and the design, selection and arrangement thereof, as well as the technology that is used by Lelapa AI to deliver the Vulavula Playground and/or API, including: (a) software code (whether in source or object form) including platforms and applications, hardware, algorithms, templates, graphical user interfaces, architectures, data base, class libraries, application programming interfaces, objects, methodologies, and documentation (both printed and electronic) in existence as of the Effective Date or otherwise acquired or developed by Lelapa AI apart from the services rendered under these Terms of Use; and (b) any derivative works, improvements, enhancements or extensions of the foregoing created by either you or Lelapa AI. Except as expressly provided in these Terms of Usa, Lelapa AI expressly reserves any rights, express or implied, or ownership in the Lelapa AI Intellectual Property and accordingly, does not grant you either rights or ownership to same. No right, title or interest in or to the Vulavula Playground is transferred to you. Lelapa AI reserves all rights not expressly granted to you herein. <br><br>" +
        "Lelapa AI grants you ownership of the output of the Vulavula Playground and/or API.  You hereby grant to Lelapa AI a royalty-free, fully-paid, non-exclusive, non-transferable, sub-licensable, worldwide right to use: (a) any and all electronic data uploaded or processed by you to the Vulavula Playground and/or API; and (b) any and all software, methodologies, output, templates, business processes, documentation or other material authored, invented or otherwise created by you using or for use with the Lelapa AI Intellectual Property provided by you to Lelapa AI solely to provide the Vulavula Playground. <br><br>" +
        "Lelapa AI Core Technology is not included in the Customer Technology. Accordingly, the Customer will not have ownership of intellectual property in Lelapa AI Core Technology. <br><br>" +
        "Any unauthorised use, being any use not expressly allowed in terms of these Terms and Conditions, of the Vulavula Playground and/or API is a breach of these Terms of Use and may violate copyright, trademark and other laws both nationally and internationally. You are permitted to use the Vulavula Playground and/or API only for legitimate business purposes related to your role as a current or prospective customer, supplier or partner of Lelapa AI. You shall not copy, modify, create derivative works of, publicly display or perform, republish, download or store, or transmit any Lelapa AI Intellectual Property without Lelapa AI’s express prior written consent or except as expressly provided in these Terms of Use.",
    },
    {
      title: "TRADEMARKS",
      body: "Lelapa AI, and all related, logos, product and service names, designs and slogans are trademarks or service marks of Lelapa AI or our affiliates or licensors. You shall not use such marks without Lelapa AI’s prior written consent. Any and all other names, brands and marks are used purely for identification purposes and remain the trademarks of their respective owners.",
    },
    {
      title: "IMPROVEMENT OF SERVICES",
      body:
        "Lelapa AI is providing the use of the Vulavula Playground and/or API free of charge.  Lelapa AI is committed to improving their services to you as well as others. Improvement may be achieved by using your data to train Lelapa AI’s models. However, Lelapa AI does provide you with the option to opt out of: <br>" +
        "<ol type='a'>" +
        "<li>The use of your data to enable Lelapa AI to improve the services currently being provided to you, though it should be noted that service delivery is intrinsically part of the service Lelapa AI offers to you; or</li>" +
        "<li>The use of your data to improve the Vulavula Playground and/or API for any and all users.</li>" +
        "</ol>",
    },
    {
      title: "TERMINATION",
      body:
        "Lelapa AI reserves the right, but is not obligated, to: <br>" +
        "<ul>" +
        "<li>Terminate your access to all or part of the Vulavula Playground and/or API at our sole discretion, including, but not limited to, the any violation of these Terms of Use or the Privacy Policy.</li>" +
        "<li>Disclose your identity to any and all third parties who claim that any material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>" +
        "<li>Take appropriate legal action, including, but not limited to, referring you to any relevant law enforcement body, for the illegal or unauthorised use of the Vulavula Playground and/or API.</li>" +
        "</ul>" +
        "Lelapa AI may terminate these Terms of Use at our sole discretion, at any time via written notice to you, and any and all licenses, permissions and other rights granted to you hereunder shall immediately terminate.  Notwithstanding the termination of these Terms of Use for any reason, sections relating to intellectual property, confidentiality and legal considerations shall remain enforceable. <br><br>" +
        "Lelapa AI reserves the right to fully cooperate with any relevant law enforcement authorities and/or bodies, or court orders directing us to disclose the identity of a specific user including any materials on or through the Vulavula Playground. You waive and hold Lelapa AI harmless from any claims resulting from any action taken by Lelapa AI during or as a result of its investigations and from any actions taken as a consequence of investigations by either Lelapa AI or any law enforcement authorities and/or bodies. <br><br>" +
        "Lelapa AI is not able to review inputs and outputs through the Vulavula Playground and/or API nor are we able to ensure prompt removal of objectionable or unauthorised material once same has been inputted or outputted. Lelapa AI assumes no liability and is held harmless for any action or inaction regarding transmissions, communications or content provided by any user or third parties. Lelapa AI bears no liability or responsibility to anyone for the performance or non-performance of the activities set out in this paragraph.",
    },
    {
      title: "FEEDBACK AND SUGGESTIONS",
      body:
        "Any feedback, comments, suggestions, requests for support and other communications relating to the Vulavula Playground should be directed to: <a href='mailto:vulavulasupport@lelapa.ai'>vulavulasupport@lelapa.ai</a> . Lelapa AI shall have a royalty-free, worldwide, non-exclusive, transferable, sub-licensable, irrevocable, perpetual right and license to make, use, sell, offer for sale, import or otherwise incorporate for any purpose, any feedback, and suggestions for improvements or enhancements to the the Vulavula Playground and/or API and the Lelapa AI Products. <br><br>" +
        "Should you become aware of any violation of these Terms of Use by any person, including other users or third parties, immediately notify Lelapa AI via electronic mail at <a href='mailto:vulavulasupport@lelapa.ai'>vulavulasupport@lelapa.ai</a> .",
    },
    {
      title: "REGIONAL LEGAL CONSIDERATIONS",
      body: "Lelapa AI is a South African company and has an affiliate company in Delaware, United States of America. The Vulavula Playground and/or API can be accessed from countries across the globe.  Please note that access to the Vulavula Playground and/or API may not be legal by certain persons or in certain countries. Should you access the Vulavula Playground and/or API from outside of South Africa or the United States, you do so on your own initiative and are responsible for compliance with local laws. Any access to the Vulavula Playground and/or API from territories where the Vulavula  Playground and/or API alternatively any of its services or products are illegal is prohibited. You acknowledge that you remain responsible at all times for your compliance with all applicable export and related legislation and regulations. You further hold Lelapa AI harmless for any failure by yourself to properly comply with any relevant laws of any country.",
    },
    {
      title: "DISCLAIMER OF WARRANTIES",
      body:
        "Lelapa AI cannot and does not warrant that any aspect of the Vulavula Playground and/or API is free of viruses or other destructive code. It is your responsibility to properly implement sufficient anti-virus protection procedures and software, and to further ensure that you have stored your data in place external to our site in the event that any such data is lost and required to be reconstructed. Lelapa AI will not bear any liability for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the Vulavula Playground and/or API or any services or items obtained through the Vulavula Playground and/or API or to your downloading of any material posted on it, or on any website linked to it. <br><br>" +
        "Your use of the Vulavula Playground and/or API, including any content, service or items obtained from same, is done at your own risk. The aforementioned content, service or items are provided  on an “as is” and “as available” basis, free of any warranties of any kind, either express or implied. Neither Lelapa AI nor any person associated with Lelapa AI makes any warranty or representation with respect to the Vulavula Playground and/or API including, but not limited to the completeness, security, reliability, quality, accuracy or availability of the Vulavula Playground and/or API. Without limiting the foregoing, neither Lelapa AI nor anyone associated with Lelapa AI represents or warrants that the Vulavula Playground and/or API will be accurate, reliable, error-free or uninterrupted, that defects will be corrected, that our site or the server that makes it available are free of viruses or other harmful components or that the Vulavula Playground and/or API or any services or items obtained through the Vulavula Playground and/or API will otherwise meet your needs or expectations. <br><br>" +
        "Lelapa AI hereby disclaims all warranties of any kind, either express or implied, statutory or otherwise, with respect to the Vulavula Playground and/or API and the website content, including but not limited to any warranties of merchantability, non-infringement and fitness for particular purpose. <br><br>" +
        "The foregoing does not affect any warranties which cannot be excluded or limited under applicable South African and United States of American law.",
    },
    {
      title: "LIMITATION OF LIABILITY",
      body: "Except to the extent that a disclaimer of liability is prohibited under applicable law, in no event will Lelapa AI, its affiliates and its licensors, service providers, employees, agents, officers and directors be liable for damages of any kind, under any legal theory, arising out of or in connection with your use, or inability to use, the Vulavula Playground and/or API, including any website content, including any direct, indirect, special, incidental, consequential or punitive damages, including but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and whether caused by delict or tort (including negligence), breach of contract or otherwise, even if foreseeable.",
    },
    {
      title: "INDEMNIFICATION",
      body: "You agree to defend, indemnify and hold Lelapa AI, its affiliates and licensors and their respective officers, directors, employees, contractors, agents, licensors and suppliers harmless from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees and other costs of defense) resulting from your violation of these Terms of Use or your use of the Vulavula Playground and/or API, including, without limitation, any use of the Vulavula Playground and/or API’s content, data, models, services and products other than as expressly authorised in these Terms of Use or your use of any information obtained from the Vulavula Playground and/or API.",
    },
    {
      title: "Governing Law and Jurisdiction",
      body:
        "These Terms of Use and any dispute or claim arising out of, or related to, them, their subject matter or their formation (in each case, including non-contractual disputes or claims) shall be governed by and construed in accordance with South African law without giving effect to any choice or conflict of laws provision or rule. <br><br>" +
        "Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Vulavula playground and/or API shall be instituted exclusively in the South Gauteng High Court, in although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You hereby irrevocably waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.",
    },
    {
      title: "Export Restrictions",
      body: "You may not access, download, use or export materials posted to the Vulavula playground and/or API in violation of any applicable export regulations. You agree to comply with all export laws and restrictions and regulations of South Africa or foreign agency or authority.",
    },
    {
      title: "Waiver and Severability",
      body:
        "No waiver of these Terms of Use by Lelapa AI shall be deemed a further or continuing waiver of such term or condition or any other term or condition, and any failure of Lelapa AI to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision. <br><br>" +
        "If any provision of these Terms of Use is held by a court of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.",
    },
    {
      title: "Whole Agreement",
      body: "The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and Lelapa AI with respect to the Vula playground and/or API and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Vula playground and/or API. For the avoidance of doubt, these Terms of Use do not supersede any Enterprise License Agreement, subscription agreement or other agreement pursuant to which you have been granted the right to use or access a Lelapa AI product or service.",
    },
  ],
};

export const apiKeyGenCardDetails = {
  headingSignedOut: "Signup for API Keys",
  headingSignedIn: "GENERATE API KEYS FOR YOUR PRODUCT",
  bodySignedOut:
    "Sign up now to gain access to API keys - Unlock limitless possibilities for your client products today! <br> Interested in an Enterprise solution? <br> <a href='mailto:vulavulasupport@lelapa.ai'>Reach out</a>",
  bodySignedIn:
    "Head on over to our <a href='https://docs.lelapa.ai/' target='_blank' rel='noreferrer'>docs</a> to learn how to use our API. Interested in an Enterprise solution? <br> <a href='mailto:vulavulasupport@lelapa.ai'>Reach out</a>.",
  signupButtonText: "SIGN UP",
  generateButtonText: "GENERATE KEY",
  viewHistoryButtonText: "VIEW HISTORY",
  apiKeyPlaceholder: "Generate an API Key for yourself",
  warningChipText:
    "You’ve reached your key gen limit. <br> Please contact us <a href=' href='mailto:morekeysplease@lelapa.ai'>morekeysplease@lelapa.ai</a>",
  copiedText: "Token copied!",
};

export const footerDetails = {
  sections: [
    {
      title: "",
      links: [
        { name: "Home", navigation: HOME },
        { name: "About", navigation: "https://lelapa.ai/about/" },
        { name: "Resources", navigation: "https://lelapa.ai/blog/" },
        { name: "Contact", navigation: "https://lelapa.ai/contact/" },
      ],
    },
    {
      title: "",
      links: [
        { name: "Newsletter", navigation: "/" }, // TODO: replace with correct link
        { name: "Career", navigation: "/" }, // TODO: replace with correct link
      ],
    },
    {
      title: "",
      links: [
        {
          name: "Privacy Policy",
          navigation: "https://lelapa.ai/privacy-policy/",
        },
      ],
    },
  ],
  contactUsSocials: [
    {
      type: SOCIALSTYPE.INSTAGRAM,
      navigation: "https://www.instagram.com/lelapaai/", // TODO: replace with correct link
    },
    {
      type: SOCIALSTYPE.LINKEDIN,
      navigation: "https://za.linkedin.com/company/lelapa-ai", // TODO: replace with correct link
    },
    {
      type: SOCIALSTYPE.X,
      navigation: "https://twitter.com/LelapaAI", // TODO: replace with correct link
    },
  ],
  contactUsEmail: "info@lelapa.ai",
};

export const transcribePageDetails = {
  title: "Transcription",
  body: "Hit record or upload a file <b>(10 seconds max)</b>. Vulavula will get to work transcribing your audio file into text. Note: This model is trained on call centre industry data, so it works best for those kinds of voice recordings.",
  deviderText: "OR",
  recordButtonText: "RECORD",
  stopRecordingButtonText: "STOP RECORDING",
  transcribeButtonText: "TRANSCRIBE",
  tryAgainButtonText: "TRY AGAIN",
  transcibingText: "TRANSCRIBING",
  uploadButtonText: "UPLOAD",
  uploadingText: "UPLOADING",
  uploadCompleteText: "UPLOAD COMPLETE",
  dragDropText: "Drag and drop a file or click to browse",
  acceptableText: "Acceptable audio files for upload:",
  acceptedAudioExtentions: [".mp3", ".WAV"],
};

export const fileUploadErrorMessages = {
  fileLimitMesssage: "LIMITED TO 1 FILE UPLOAD. PLEASE TRY AGAIN",
  unsupportedExtentionMessage:
    "FILE EXTENTION IS NOT SUPPORT. PLEASE UPLOAD SUPPORTED FILE",
  sizeExceededMessage:
    "FILE SIZE EXCEEDED. PLEASE UPLOAD FILE THAT IS LESS THAN 10MB",
};

export const progessLoaderText = "Vulavula is busy...";

export const transcribeResultCardInfo = {
  title: "Here’s your transcript!",
  subtitle: "Language Detected",
  editButtonText: "EDIT",
  copyButtonText: "COPY",
  correctionButtonText: "SUMBIT AS CORRECTION",
};

export const improveCardInfo = {
  title:
    "Thanks for trying Vulavula! You’ve helped us make Vulavula better for everyone.",
  tryAgainButtonText: "TRY AGAIN",
  rateUsButtonText: "RATE US",
};

export const ratingModalTranscribeInfo = {
  heading: "Rate our service",
  title: "Loving Vulavula or.. not so much?",
  subtitle: "TELL US WHY",
  tagsOptions: [
    "Error breaks context of audio",
    "Fails on homophones",
    "Fails to transcribe names",
    "Fails with words that aren't same language as rest of sentence",
    "Non-context breaking minor errors",
    "Spelling errors",
    "Unclear instructions",
    "Other",
  ],
  submitButtonText: "SUBMIT",
  inputPlaceholder: "Vulavula has been.....",
};

export const ratingModalNERInfo = {
  heading: "Rate our service",
  title: "Loving Vulavula or.. not so much?",
  subtitle: "TELL US WHY",
  tagsOptions: [
    "Missed an entity/entities",
    "Detected an entity, but not of correct type",
    "Only detected half or a portion of an entity",
    "Other",
  ],
  submitButtonText: "SUBMIT",
  inputPlaceholder: "Vulavula has been.....",
};

export const ratingModalSentimentAnalysisInfo = {
  heading: "Rate our service",
  title: "Loving Vulavula or.. not so much?",
  subtitle: "TELL US WHY",
  tagsOptions: [
    // TODO add more tag options ones on design do not make sense
  ],
  submitButtonText: "SUBMIT",
  inputPlaceholder: "Vulavula has been.....",
};

export const loginInfo = {
  title: "Welcome back!",
  subtitle: "Unlock the world with Vulavula",
  checkboxText: "Remember me",
  loginButtonText: "LOG IN",
  googleButtonText: "Log in with Google",
  githubButtonText: "Log in with Github",
  subText: "Don't have an account?",
  navigationText: "Sign up now",
};

export const forgotPasswordInfo = {
  title: "Forgot your password?",
  subtitle:
    "Enter the email address linked to your account, then follow instructions sent to your inbox.",
  forgotPasswordButtonText: "SEND CODE TO EMAIL",
  forgotPasswordButtonLoadingText: "SENDING...",
  backButtonText: "BACK",
  conditionalText:
    "If you've already received a reset code in your email, please make sure the email address above is correct and then: ",
  conditionalButtonText: "Enter Code here!",
  emailIsInvalid: "Enter valid email!",
};

export const forgotPasswordEmailSentInfo = {
  title: "Sending email",
  subtitle:
    "An email has been sent to your account. Please check your inbox for the verification code.",
  forgotPasswordEmailSentButtonText: "Enter Verification Code",
  backButtonText: "BACK",
  missingDetailsText:
    "The link you followed appears to be invalid or expired. Please start the password reset process again or return to the login page.",
};

export const resetPasswordSuccessInfo = {
  title: "Password Reset Successfully",
  subtitle:
    "Your password has been updated successfully. Please log in with your new password.",
  resetSuccessButtonText: "LOGIN NOW",
};

export const resetPasswordInfo = {
  title: "New Password",
  subtitle: "Create a new password",
  resetPasswordButtonText: "Reset Password",
  resetPasswordButtonLoadingText: "Submitting...",
  signInButtonText: "Go to login",

  passwordMetaInfo: "Password must contain at least 7 letters and 1 number",
  confirmationPasswordMetaInfo:
    "Password must contain at least 7 letters and 1 number",
  missingDetailsText:
    "The link you followed appears to be invalid or expired. Please start the password reset process again or return to the login page.",
  passwordMismatchError: "Passwords do not match.",
};

export const resetPasswordCodeInfo = {
  title: "Verification",
  subtitle: "Enter the code sent to your inbox",
  submitButtonText: "Verify",
  submitButtonLoadingText: "Verifying...",
  signInButtonText: "Go to login",
  missingDetailsText:
    "The link you followed appears to be invalid or expired. Please start the password reset process again or return to the login page.",
};

export const passwordResetMissingDetailsInfo = {
  primaryButtonText: "Retry forgot password",
  secondaryButtonText: "Go to login",
  defaultMessage:
    "No email address was provided. Please ensure you accessed this page through a valid link.",
};

export const generalErrors = {
  apiSyntax: "An error occurred. Please try again later.",
};

export const signupInfo = {
  title: "API quick sign-up",
  checkboxText:
    "I accept the <a href='https://lelapa.ai/privacy-policy/' target='_blank' rel='noreferrer'>Vulavula Privacy Policy</a>",
  signUpButtonText: "SIGN UP",
  googleButtonText: "Sign up with Google",
  githubButtonText: "Sign up with Github",
  subText: "Already have an account?",
  navigationText: "Login",
  signupCompleteHeading: "Thanks for signing up!",
  signupCompleteBody:
    "We've sent you an authentication link. Please click on the link in the email to verify your account.",
};

export const kycInfo = {
  title:
    "Before we get started, tell us more about you so we can tailor your experience.",
  nextButtonText: "NEXT",
  finishButtonText: "START PLAYING!",
  questions: [
    {
      heading: "What's your role?",
      options: [
        "MLOps/DevOps",
        "Machine learning Engineer",
        "Software engineer",
        "Data Scientist",
        "Researcher",
        "Product Manager",
        "Student",
        "Other",
      ],
    },
    {
      heading: "What's your industry?",
      options: [
        "Healthcare",
        "Agriculture",
        "Finance, Media",
        "Education",
        "Manufacturing",
        "IT",
        "Other",
      ],
    },
    {
      heading: "What Vulavula features would you be interested in?",
      options: [
        "Vulavula transcribe",
        "Vulavula analyse",
        "Vulavula speech",
        "Vulavula converse",
        "Other",
      ],
    },
    {
      heading: "Which Vulavula features are you most interested in?",
      options: [
        "Content moderation",
        "Text Classification",
        "Semantic search",
        "User intent recognition",
        "Text generation",
        "Entity extraction",
        "Text summarisation",
        "Chat",
        "Other",
      ],
    },
    {
      heading: "What languages are you interested in?",
      options: [
        "English",
        "Afrikaans",
        "Sesotho",
        "Setswana",
        "SePedi",
        "IsiZulu",
        "IsiXhosa",
        "Ndebele",
        "Kiswahili",
        "Other",
      ],
    },
  ],
};

export const apiKeyDashboardInfo = {
  title: "API Keys",
  generateButtontext: "GENERATE API KEY",
  dashboardCards: [
    {
      icon: Pattern1Grey,
      subtitle:
        "Navigate seamlessly through your Vulavula experience with our tailored rate limiting",
    },
    {
      icon: Pattern4Grey,
      subtitle:
        "Preserve context and meaning with our carefully calibrated length restrictions.",
    },
    {
      icon: Pattern5,
      subtitle:
        "Integrate easily with our API & Connect with us for bespoke, skill-level-appropriate linguistic API options.",
    },
  ],
  defaultStateInfo: {
    title: "You don’t have any API keys generated yet...",
    subtitle:
      "Keep track of your API keys and their status here once you’ve generated them. <br> <b>NB: Please note that keys are limited to 100 calls each and will expire after 30 days. Enjoy!</b>",
    icon: Pattern4LightGrey,
  },
  populatedStateInfo: {
    title: "API Key Archive",
    subtitle: "Keep track of your API keys and their status.",
    keyCountText: "keys left",
    tableColumns: [
      { name: "Key", icon: null },
      { name: "Status", icon: null },
      { name: "Created on", icon: null },
      { name: "Expires on", icon: DownwardArrowIcon },
      { name: "", icon: "" }, // empty column name for the delete button
    ],
    copyText: "COPY",
    deleteText: "DELETE",
  },
  recentButtonText: "RECENT",
  nextButtonText: "NEXT",
  filterButtonText: "FILTERS",
  showingText: "Showing",
  maxKeyWarning:
    "You’ve reached your key gen limit. <br> Please contact us at <a  href='mailto:morekeysplease@lelapa.ai'>morekeysplease@lelapa.ai</a>",
  apiKeyStatuses: {
    freePlanInfo: `The free plan gives you ${appConfig.API_MAX_FREE_CALLS} free calls per API key, with a max limit of ${appConfig.MAX_API_KEYS} API keys`,
    freePlanUsedUpInfo: "You’ve exceeded your free limit with us, <a href='/billing'>please consider upgrading to a paid plan</a>",
  }
};

export const entityRecognitionPageInfo = {
  title: "Entity recognition",
  subtitle: "Quickly spot important people, places, brands, values and more.",
  cardTitle: "Give the multilingual entity recognition feature a try!",
  placeholderText:
    "Drop a paragraph or two into the text box and hit “Detect”.",
  buttonText: "DETECT",
  tryAgainButtonText: "TRY AGAIN",
  rateUsButtonText: "RATE US",
};

export const sentimentAnalysisPageInfo = {
  title: "Sentiment analysis",
  subtitle:
    "Quickly understand whether the emotional tone of the text is positive, negative, or neutral for <b>Zulu</b> text.",
  cardTitle: "Try this super useful feature.",
  resultCardTitle: "Result",
  placeholderText:
    "Drop a paragraph or two into the text box and hit “Detect”.",
  buttonText: "DETECT",
  tryAgainButtonText: "TRY AGAIN",
  rateUsButtonText: "RATE US",
};

export const successRegistrationText = "Registration was successful";

export const cookieInfo = {
  title: "Cookies",
  body: "We use cookies on our site to enhance your browsing experience with us. To find out more, read our <a href='https://lelapa.ai/privacy-policy/' target='_blank' rel='noreferrer'>privacy policy.</a>",
  acceptButtonText: "ACCEPT",
  manageCookiesButtonText: "MANAGE COOKIES",
  manageCookiesTitle: "Manage Cookies",
  manageCookiesBody: "Manage your consent preferences on our site.",
  cookieOptions: [], // TODO: add cookie options
  privacyPolicyLink:
    "<a href='https://lelapa.ai/privacy-policy/' target='_blank' rel='noreferrer'>Privacy Policy.</a>",
};

export const BillingPageInfo = {
  BillingSection: {
    title: "Billing",
    subtitle:
      "Explore billing plans, integrate payment methods and gain access to your detailed activity log",
    cards: [
      {
        title: "Vulavula Partnership",
        subtitle:
          "Get access to our services through our partners or via a global marketplace",
        body: {
          title:
            "<div class='card-heading-2'>$21</div> <div class='card-option-text'>FROM</div>",
          text: "Amazon Sagemaker <br> Turn io <br> <br>  <div class='subtext-container' style='justify-content: flex-start'>other makertplaces coming soon</div>",
        },
        primaryButton: null,
        secondaryButton: {
          buttonText:
            "<a href='https://docs.lelapa.ai/getting-started/plans' target='_blank' style='color:#3a2730; text-decoration:none'>LEARN MORE</a>",
          buttonTextStyle: "vulavula-button-small",
          buttonStyle: "button-primary-white-no-border-default height-auto",
        },
      },
      {
        title: "Vulavula API",
        subtitle:
          "Access the wide selection of features within the Vulavula API",
        body: {
          title: "<div class='card-option-text'>FROM</div>",
          text:
            "Vulavula Transcribe <br>" +
            "Vulavula Converse <br>" +
            "Vulavula Translate" +
            "<div class='subtext-container' style='justify-content: flex-start; gap: 3px'>view full plan details <a href='https://docs.lelapa.ai/getting-started/plans' target='_blank' style='color:#3a2730;'>here</a></div>",
        },
        tagTitle: "Most popular",
        tagActiveTitle: "Active",
        primaryButton: {
          buttonText: "UPGRADE",
          buttonTextStyle: "vulavula-button-small white-text",
          buttonStyle: "medium-card-primary-button",
        },
        secondaryButton: null,
      },
      {
        title: "Vulavula Eval",
        subtitle: "Get ahead with our model evaluation service today.",
        body: {
          title: "<div class='card-heading-2'>POA</div>",
          text: "Human-in-the-loop evaluation <br> Cultural Relevance Evaluation <br> Automated Evaluation <br> Tutorials & Training <br> Consults & Premium Support",
        },
        primaryButton: null,
        secondaryButton: {
          buttonText:
            "<a href='mailto:vulavulasupport@lelapa.ai' style='color:#3a2730; text-decoration:none'>CONTACT US</a>",
          buttonTextStyle: "vulavula-button-small",
          buttonStyle: "button-primary-white-no-border-default height-auto",
        },
      },
    ],
  },
  CurrentBillSection: {
    title: "Current Bill",
    subtitle: "Your current bill with us",
    cardDueTitle: "CURRENT BILL DUE",
  },
  PaymentMethodSection: {
    title: "Payment method",
    subtitle: "Add your first payment method",
    emptyPaymentMethodText: "Add payment method",
    primaryText: "Primary",
    removeButtonText: "REMOVE",
    setPrimaryButtonText: "SET AS PRIMARY",
  },
  HistorySection: {
    title: "Billing history",
    subtitle:
      "Track invoices, billing history and usage capabilities associated with your account",
    filterOptions: ["This month"], // TODO: add more filter options
    emptyState: {
      title: "No billing data",
      subtitle: "Billing data will be shown here once there is data avaialble",
    },
    tableColumns: [
      { name: "Date", icon: DownwardArrowIcon },
      { name: "Bill id", icon: null },
      { name: "Cost", icon: null },
      { name: "Status", icon: null },
    ],
    recentButtonText: "RECENT",
    nextButtonText: "NEXT",
    downloadCSVButtonText: "DOWNLOAD CSV FILE",
  },
  CardRemovalModalInfo: {
    body: "You cannot delete your primary payment method. Please set another payment method as primary before deleting this one.",
    buttonText: "OKAY",
  },
};

export const SubscribePageInfo = {
  steppers: [
    {
      title: "Subscribe to the Vulavula API",
      subtitle:
        "Choose a feature and get full access to the Vulavula API today!",
    },
    {
      title: "Select a billing cycle",
      subtitle: "Choose how frequently you want to make payments",
    },
    {
      title: "Select your payment method",
      subtitle: "Choose how to pay for your subscription",
    },
    {
      title: "Enter your card details",
      subtitle: "Enter your card details to process payment",
    },
  ],
  featureTitle: {
    title: "Feature",
    subtitle: "Select features",
  },
  billingCycleTitle: {
    title: "Billing Summary",
    subtitle: "Select a billing cycle",
  },
  sectionTitle: { title: "Payment method" },
  cardForm: {
    cardHolder: "Name on card",
    cardNumber: "Card number",
    expiryDate: "Expiry date",
    cvv: "CVV",
  },
  payNowTitles: {
    title: "Pay now",
    subtitle: "Enter your card details and click Pay Now",
  },
  paymentSuccessTitles: {
    title: "Subscription sucessful",
    subtitle: "Check your email for billing updates from Stripe",
  },
  paymentFailureTitles: {
    title: "Subscription failed",
    subtitle:
      "We tried to process your subscription, but it failed! You can try again shortly.",
  },
  paymentConfirmationInfo: {
    billingPlanTitle: "Billing plan",
    billingCycleTitle: "Billing cycle",
    subtotalTitle: "Subtotal",
  },
  creditCardText: "New Credit Card",
  backButtonText: "BACK",
  nextButtonText: "NEXT",
  securedByText: "Secured by",
  payNowButtonText: "PAY NOW",
  backToBillingButtonText: "BILLING", //TODO: change this to "MY PRODUCTS" once page is available
};
