import React, { Component } from "react";
import "../../styles/type-scale-system.css";
import "./ResultCard.css";
import {improveCardInfo, transcribeResultCardInfo} from "../../cms/generalCMS";
import SmileIcon from "../../icons/smile-icon.svg";
import { ReactComponent as ChipDot } from "../../icons/chip-dot.svg";
import PenIcon from "../../icons/pen-icon.svg";
import CopyIcon from "../../icons/copy-icon.svg";
import {
  LANGUAGETYPE,
  ACTIONTYPE,
  LANGUAGE_ID_TYPE,
} from "../../utils/constants";
import { submitTranscriptionCorrection } from "../../helpers/api/transcribe";
import PropTypes from "prop-types";
import { ReactComponent as RepeatIcon} from "../../icons/repeat-icon.svg";

class ResultCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      result: "",
      editedResults: this.props.transcriptionResults,
      correctionSubmitted: "",
    };

    this.enableEditTranscription = this.enableEditTranscription.bind(this);
    this.handleEditTranscriptionChanges =
      this.handleEditTranscriptionChanges.bind(this);
    this.handleSubmitCorrection = this.handleSubmitCorrection.bind(this);
  }

  getLanguageChipColor(language) {
    switch (language) {
      case LANGUAGE_ID_TYPE.ENGLISH:
        return {
          lightColor: "#FAFDE8",
          darkColor: "#5E7615",
          chipText: LANGUAGETYPE.ENGLISH,
        };
      case LANGUAGE_ID_TYPE.AFRIKAANS:
        return {
          lightColor: "#FFF0F2",
          darkColor: "#C70940",
          chipText: LANGUAGETYPE.AFRIKAANS,
        };

      case LANGUAGE_ID_TYPE.SESOTHO:
        return {
          lightColor: "#F4F9F9",
          darkColor: "#2E5657",
          chipText: LANGUAGETYPE.SESOTHO,
        };

      case LANGUAGE_ID_TYPE.SETSWANA:
        return {
          lightColor: "#DFEAFA",
          darkColor: "#3053C5",
          chipText: LANGUAGETYPE.SETSWANA,
        };

      default: // IsiZulu
        return {
          lightColor: "#FFF2ED",
          darkColor: "#C1130D",
          chipText: LANGUAGETYPE.ISIZULU,
        };
    }
  }

  enableEditTranscription() {
    this.setState({ edit: true });
  }

  handleEditTranscriptionChanges(event) {
    let value = event.target.innerHTML;
    value = value.replaceAll("<div>", "");
    value = value.replaceAll("</div>", "");
    this.setState({ result: value });

    const editableDiv = document.getElementById("results");

    // Check if content exceeds width
    if (editableDiv.scrollWidth > editableDiv.clientWidth) {
      // Insert a newline character
      document.execCommand("insertHTML", false, "<br>");
    }

    // Dynamically adjust the height based on content
    editableDiv.style.height = "auto"; // Reset height

    // Set a maximum height for scrollability
    const maxHeight = 600;
    if (editableDiv.scrollHeight > maxHeight) {
      editableDiv.style.overflowY = "auto";
      editableDiv.style.height = maxHeight + "px";
    } else {
      editableDiv.style.overflowY = "hidden";
    }

    if (editableDiv.innerHTML.includes("<div>")) {
      editableDiv.innerHTML = editableDiv.innerHTML.replaceAll("<div>", "");
      editableDiv.innerHTML = editableDiv.innerHTML.replaceAll("</div>", "");
    }
  }

  async handleSubmitCorrection() {
    const { result } = this.state;
    await submitTranscriptionCorrection(result, this.props.dispatch);
    this.props.handleRecordingState(ACTIONTYPE.TRANSCRIBING, true, null);
  }

  copyToClipboard = () => {
    this.props.handleRecordingState(ACTIONTYPE.TRANSCRIBING, true, null);

    const editableDiv = document.getElementById("results");

    // Create a range and select the content
    const range = document.createRange();
    range.selectNodeContents(editableDiv);

    // Get the current selection and add the range
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    // Execute the copy command
    document.execCommand("copy");

    // Deselect the text
    selection.removeAllRanges();
  };

  render() {
    let chipDetails = this.getLanguageChipColor(this.props.languageId);
    let { edit, editedResults } = this.state;

    return (
      <div className="results-container">
        {/* TITLE CONTAINER */}
        <div className="result-card-title">
          {/* TITLE */}
          <div className="vulavula-card-heading-light">
            {transcribeResultCardInfo.title}
          </div>

          {/* SMILE ICON */}
          <img src={SmileIcon} alt="smile icon" />
        </div>

        {/* TRANSCRIPTION CONTAINER */}
        <div className="transcription-container">
          {/* SUBTITLE CONTAINER */}
          <div className="card-subtitle">
            {/* SUBTITLE */}
            <div className="vulavula-subtitle">
              {transcribeResultCardInfo.subtitle}
            </div>

            {/* CHIP */}
            <div
              className="chip-container"
              style={{
                background: chipDetails.lightColor,
                borderColor: chipDetails.darkColor,
              }}
            >
              <ChipDot fill={chipDetails.darkColor} />
              <div
                className="vulavula-chip-text"
                style={{ color: chipDetails.darkColor }}
              >
                {chipDetails.chipText}
              </div>
            </div>
          </div>

          {/* RESULT TEXT */}
          <div
            id="results"
            contentEditable={edit ? "true" : "false"}
            className={`vulavula-paragraph hyphenate ${
              edit ? "edit-text-field" : ""
            }`}
            dangerouslySetInnerHTML={{
              __html: editedResults,
            }}
            onInput={edit ? this.handleEditTranscriptionChanges : undefined}
            spellCheck={false}
          />
        </div>

        {/* BUTTONS CONTAINER */}
        <div className="outer-button-container">
          {!edit ? (
            <div className="button-container">
              {/* EDIT BUTTON */}
              <div
                className="result-button"
                style={{ background: "#CC4A2B", border: "1px solid #CC4A2B" }}
                onClick={this.enableEditTranscription}
              >
                <div className="result-button-text" style={{ color: "white" }}>
                  {transcribeResultCardInfo.editButtonText}
                </div>
                <img src={PenIcon} alt="pen icon" />
              </div>

              {/* COPY BUTTON */}
              <div
                className="result-button"
                style={{
                  color: "#3A2730",
                  border: "1px solid #3a2730",
                }}
                onClick={this.copyToClipboard}
              >
                <div
                  className="result-button-text"
                  style={{
                    color: "#3A2730",
                  }}
                >
                  {transcribeResultCardInfo.copyButtonText}
                </div>
                <img src={CopyIcon} alt="copy icon" />
              </div>

              {/* TRY AGAIN BUTTON */}
              <div
                  className="result-button"
                  style={{
                    color: "#3A2730",
                    border: "1px solid #3a2730",
                  }}
                  onClick={() => this.props.handleClearStates()}
              >
                <div
                    className="result-button-text"
                    style={{color: "#3A2730"}}
                >
                  {improveCardInfo.tryAgainButtonText}
                </div>
                <RepeatIcon style={{ filter: 'invert(25%) sepia(50%) saturate(750%) hue-rotate(325deg) brightness(30%) contrast(85%)' }}/>
              </div>

            </div>
          ) : (
              <div className="button-container md-column-reverse">
                <div
                    className="result-button"
                    style={{background: "#CC4A2B", border: "1px solid #CC4A2B"}}
                    onClick={this.handleSubmitCorrection}>

                  {/* SUMIT CORRECTION BUTTON */}
                  <div className="result-button-text" style={{color: "white"}}>
                    {transcribeResultCardInfo.correctionButtonText}
                  </div>
                  <img src={PenIcon} alt="pen icon"/>
                </div>

                {/* TRY AGAIN BUTTON */}
                <div
                    className="result-button"
                    style={{
                      color: "#3A2730",
                      border: "1px solid #3a2730",
                    }}
                    onClick={()=>this.props.handleClearStates()}
                >
                  <div
                      className="result-button-text"
                      style={{color: "#3A2730"}}
                  >
                    {improveCardInfo.tryAgainButtonText}
                  </div>
                  <RepeatIcon style={{ filter: 'invert(25%) sepia(50%) saturate(750%) hue-rotate(325deg) brightness(30%) contrast(85%)' }}/>
                </div>
              </div>
          )}
        </div>
      </div>
    );
  }
}

ResultCard.propTypes = {
  transcriptionResults: PropTypes.string,
  handleRecordingState: PropTypes.func,
  handleClearStates: PropTypes.func,
  languageId: PropTypes.string,
  dispatch: PropTypes.func,
};

export default ResultCard;
