import React, { Component } from "react";
import "./ProgressBar.css";
import PropTypes from "prop-types";

class ProgressBar extends Component {
  render() {
    let { processProgress } = this.props;

    return (
      <div className={processProgress ? "complete-outer-container" : ""}>
        {processProgress !== 100 ? (
          <div className="progress-outer-container">
            <div
              className="filler-container"
              style={{
                width: `${processProgress}%`,
                background: "#fac200",
              }}
            />
          </div>
        ) : (
          <div
            className="complete-container"
            onAnimationEnd={this.props.handleUploadComplete}
          >
            <div className="tick-mark-complete">&#10003;</div>
          </div>
        )}
      </div>
    );
  }
}

ProgressBar.propTypes = {
  processProgress: PropTypes.number,
  handleUploadComplete: PropTypes.func,
};

export default ProgressBar;
