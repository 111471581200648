import React, { Component } from "react";
import "./Login.css";
import "../../../styles/type-scale-system.css";
import "../../../styles/color-system.css";
import { loginInfo, successRegistrationText } from "../../../cms/generalCMS";
import {
  SIGNUP,
  KYC,
  ACTION_AREA,
  HOME,
  FORGOT_PASSWORD,
} from "../../../utils/constants";
import GoogleIcon from "../../../icons/google-icon.svg";
import GithubIcon from "../../../icons/github-icon.svg";
import CustomForm from "../../../components/form/CustomForm";
import {
  submitJWTLogin,
  submitOAuthLogin
} from "../../../helpers/api/auth";
import {
  setCookie,
  deleteCookie
} from "../../../helpers/cookie"
import {
  handleSocialLoginResponse,
  handleErrorMessage,
} from "../../../helpers/api/apiHelper"
import { handleGetKYCStatus } from "../../../helpers/api/kyc"

import PropTypes from "prop-types";
import { appConfig } from "../../../config";

class Login extends Component {
  constructor(props) {
    super(props);

    const { errorDetails, recentlyRegistered } = this.props.globalState;

    this.state = {
      checked: false,
      email: "",
      password: "",
      emailErrorMsg: "",
      passwordErrorMsg: "",
      validate: false,
      errorMessage: errorDetails.errorMessage,
      failureArea: errorDetails.failureArea,
      recentlyRegistered: recentlyRegistered, // To display the success message after registration when redirecting to login screen since we do not have email verification yet
    };

    this.handleRememberMe = this.handleRememberMe.bind(this);
    this.errorValidation = this.errorValidation.bind(this);
    this.handleEmailUpdate = this.handleEmailUpdate.bind(this);
    this.handlePasswordUpdate = this.handlePasswordUpdate.bind(this);
    this.handleJWTLogin = this.handleJWTLogin.bind(this);
    this.handleOAuthGoogleLogin = this.handleOAuthGoogleLogin.bind(this);
    this.handleOAuthGithubLogin = this.handleOAuthGithubLogin.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState !== this.props.globalState) {
      const { errorDetails, recentlyRegistered } = this.props.globalState;
      this.setState({
        errorMessage: errorDetails.errorMessage,
        failureArea: errorDetails.failureArea,
        recentlyRegistered: recentlyRegistered,
      });
    }
  }

  handleRememberMe() {
    this.setState((prevState) => {
      return {
        checked: !prevState.checked,
      };
    });
  }

  handleEmailUpdate(e) {
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
      handleErrorMessage("", 0, this.props.dispatch, ACTION_AREA.LOGIN);
    }

    this.setState({ email: e.target.value });
    let emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!e.target.value.match(emailFormat)) {
      this.setState({ validate: true });
      this.setState({ emailErrorMsg: "Enter valid email!" });
    } else if (this.state.emailErrorMsg !== "") {
      this.setState({ validate: false });
      this.setState({ emailErrorMsg: "" });
    }
  }

  handlePasswordUpdate(e) {
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
      handleErrorMessage("", 0, this.props.dispatch, ACTION_AREA.LOGIN);
    }

    this.setState({ password: e.target.value });
  }

  async handleJWTLogin() {
    const { checked } = this.state;
    await submitJWTLogin(
      this.state.email,
      this.state.password,
      this.props.dispatch
    ).then((resp) => {
      if (resp !== null) {
        this.props.dispatch({
          type: "UPDATE_SESSION_TOKEN",
          payload: resp.access_token,
        });
        this.props.dispatch({
          type: "UPDATE_ENABLE_BILLING",
          payload: { enable: false, error: false, fetched: false },
        });

        if (checked) {
          // Set expiry date to 6 months from now
          setCookie("sessionToken", resp.access_token, 180);
          deleteCookie("requestClientToken"); // Delete the request client token cookie since it belongs to playground user
        }

        handleGetKYCStatus(resp.access_token, this.props.dispatch).then(
          (kycRedirect) => {
            if (kycRedirect) {
              this.props.navigate(KYC);
            } else {
              this.props.navigate(HOME);
            }
          }
        );
      }
    });
  }

  async handleOAuthGoogleLogin() {
    var provider = "google";

    await submitOAuthLogin(provider, this.props.dispatch).then((loginUrl) => {
      if (loginUrl !== null) {
        handleSocialLoginResponse(provider, loginUrl.authorization_url);
      }
    });
  }

  async handleOAuthGithubLogin() {
    var provider = "github";

    await submitOAuthLogin(provider, this.props.dispatch).then((loginUrl) => {
      if (loginUrl !== null) {
        handleSocialLoginResponse(provider, loginUrl.authorization_url);
      }
    });
  }

  handleForgotPassword = () => {
    this.props.navigate(FORGOT_PASSWORD);
  };

  errorValidation() {
    const { email, password } = this.state;
    this.setState({ validate: true });

    if (email === "") {
      this.setState({ emailErrorMsg: "Email is required!" });
    }
    if (password === "") {
      this.setState({ passwordErrorMsg: "Password is required!" });
    }

    if (email && password) {
      this.setState({ validate: false });
      this.handleJWTLogin();
    }
  }

  getLoginFormDetails() {
    const {
      checked,
      email,
      password,
      emailErrorMsg,
      passwordErrorMsg,
      validate,
    } = this.state;

    let inputFields = [
      {
        validate: validate,
        value: email,
        placeholder: "Email",
        valueErrorMsg: emailErrorMsg,
        handleFieldUpdate: this.handleEmailUpdate,
        type: "email",
      },
      {
        validate: validate,
        value: password,
        placeholder: "Password",
        valueErrorMsg: passwordErrorMsg,
        handleFieldUpdate: this.handlePasswordUpdate,
        type: "password",
      },
    ];

    let checkboxFields = [
      {
        checked: checked,
        checkboxText: loginInfo.checkboxText,
        handleCheckboxUpdate: this.handleRememberMe,
      },
    ];

    let primaryButtons = [
      {
        backgroundColor:
          email === "" || password === "" ? "#B0B0B0" : "#cc4a2b",
        text: loginInfo.loginButtonText,
        handleButtonAction: this.errorValidation,
      },
    ];

    const conditionalButtons = [
      {
        enabled: true,
        text: "Forgot Password?",
        buttonText: "Reset Now",
        handleButtonAction: this.handleForgotPassword,
      },
    ];

    let secondaryButtons = [
      {
        enabled: appConfig.IS_GOOGLE_SIGNIN_ENABLED,
        text: loginInfo.googleButtonText,
        icon: GoogleIcon,
        iconName: "google logo",
        handleButtonAction: this.handleOAuthGoogleLogin,
        provider: "google",
      },
      {
        enabled: true,
        text: loginInfo.githubButtonText,
        icon: GithubIcon,
        iconName: "github logo",
        handleButtonAction: this.handleOAuthGithubLogin,
        provider: "github",
      },
    ];

    return {
      inputFields,
      checkboxFields,
      primaryButtons,
      secondaryButtons,
      conditionalButtons,
    };
  }

  render() {
    let details = this.getLoginFormDetails();
    const { errorMessage, failureArea, recentlyRegistered } = this.state;

    return (
      <div className="login-outer-container v-w-full">
        <div className="login-container v-w-70">
          <div className="v-d-flex v-flex-column v-mb-5">
            <div className="vulavula-card-heading v-mb-3">
              {loginInfo.title}
            </div>
            <div className="vulavula-card-subheading">{loginInfo.subtitle}</div>
          </div>

          {recentlyRegistered && (
            <div className="vulavula-paragraph accent-brand-lelapa-green-400">
              {successRegistrationText}
            </div>
          )}

          {errorMessage &&
            (failureArea === ACTION_AREA.LOGIN ||
              failureArea === ACTION_AREA.SIGNUP) && (
              <div className="vulavula-paragraph error-message-color">
                {errorMessage}
              </div>
            )}

          <div className="login-content">
            <CustomForm
              inputFields={details.inputFields}
              checkboxFields={details.checkboxFields}
              primaryButtons={details.primaryButtons}
              secondaryButtons={details.secondaryButtons}
              conditionalButtons={details.conditionalButtons}
            />

            {/* BUTTOM SUBTEXT */}
            <div className="subtext-container">
              <div className="vulavula-paragraph">
                {loginInfo.subText}{" "}
                <a href={SIGNUP} style={{ color: "#3966D7" }}>
                  {loginInfo.navigationText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  navigate: PropTypes.func,
  dispatch: PropTypes.func,
};

export default Login;
