export const validateEmail = (email) => {
    const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    let error;
    if (email === "") {
        error = "Email is required!"
    } else if (!email.match(emailFormat)) {
        error = "Enter valid email!"
    }
    return error;
}

export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};