import React, { Component, useEffect } from "react";
import "./EntityRecognition.css";
import "../../styles/type-scale-system.css";
import {
  entityRecognitionPageInfo,
  progessLoaderText,
  ratingModalNERInfo,
} from "../../cms/generalCMS";
import {
  handleErrorMessage,
} from "../../helpers/api/apiHelper";
import { getCookie } from "../../helpers/cookie";
import { triggerEntityRecognition } from "../../helpers/api/entityRecognition"
import { ENTITY_TYPE, ACTION_AREA, HOME } from "../../utils/constants";
import ErrorCard from "../../components/errorCard/ErrorCard";
import ProgressBar from "../../components/progressBar/ProgressBar";
import CustomTextAreaCard from "../../components/customTextAreaCard/CustomTextAreaCard";
import PropTypes from "prop-types";

class EntityRecognition extends Component {
  constructor(props) {
    super(props);

    let { errorDetails, isMobile } = this.props.globalState;

    this.state = {
      entityInputValue: "",
      encodedResults: "",
      errorMessage: errorDetails.errorMessage,
      failureArea: errorDetails.failureArea,
      processProgress: 0,
      processing: false,
      doneProcessing: false,
      entityChips: [],
      isRating: false,
      isMobile: isMobile,
    };

    this.handleInputUpdate = this.handleInputUpdate.bind(this);
    this.handleClearStates = this.handleClearStates.bind(this);
    this.handleEntityRecognition = this.handleEntityRecognition.bind(this);
    this.setIsRating = this.setIsRating.bind(this);
    this.handleUpdateProcessing = this.handleUpdateProcessing.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState !== this.props.globalState) {
      const { errorDetails } = this.props.globalState;
      this.setState({
        errorMessage: errorDetails.errorMessage,
        failureArea: errorDetails.failureArea,
      });
    }
  }

  handleClearStates() {
    this.setState({
      entityInputValue: "",
      encodedResults: "",
      errorMessage: "",
      failureArea: 0,
      processProgress: 0,
      processing: false,
      doneProcessing: false,
      entityChips: [],
      isRating: false,
    });

    handleErrorMessage(
      "",
      0,
      this.props.dispatch,
      ACTION_AREA.ENTITY_RECOGNITION
    );
  }

  handleInputUpdate(e) {
    this.setState({ entityInputValue: e.target.value });

    const editableDiv = document.getElementById("results");
    const resultsContainerDiv = document.getElementById("results-container");

    // Dynamically adjust the height based on content
    editableDiv.style.height = "auto"; // Reset height
    resultsContainerDiv.style.height = "auto"; // Reset height
    if (e.target.value !== "") {
      editableDiv.style.height = editableDiv.scrollHeight + "px";
      resultsContainerDiv.style.height = editableDiv.scrollHeight + "px";
    }
  }

  async handleEntityRecognition() {
    const { entityInputValue, processProgress } = this.state;
    const playgroundSession = localStorage.getItem("playgroundSession");
    let tokenObject = getCookie("requestClientToken");
    let requestClientToken = JSON.parse(tokenObject);

    if (
      requestClientToken === null ||
      requestClientToken === "" ||
      requestClientToken === undefined
    ) {
      this.props.dispatch({
        type: "UPDATE_ERROR_MESSAGE",
        payload: {
          errorMessage: `Something went wrong, please try again.`,
          failureArea: ACTION_AREA.ENTITY_RECOGNITION,
        },
      });

      this.setState({ processing: false });

      return;
    }

    this.setState({ processing: true });

    const config = {
      onUploadProgress: () => {
        this.setState({
          processing: false,
          doneProcessing: true,
        });
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${playgroundSession}`,
        "X-CLIENT-TOKEN": `${requestClientToken.token}`,
      },
    };

    try {
      const totalSteps = 80;
      let currentStep = 0;

      const simulateProgress = () => {
        const progressInterval = setInterval(() => {
          currentStep += 10;
          if (currentStep >= totalSteps || processProgress === 100) {
            clearInterval(progressInterval);
          } else {
            this.setState({ processProgress: currentStep });
          }
        }, 200);
      };

      simulateProgress();

      const resp = await triggerEntityRecognition(
        entityInputValue,
        config,
        this.props.dispatch
      );

      if (resp != null) {
        let markedResult = entityInputValue;
        let entityWordMap = {};
        resp.entities.map((entityDetails) => {
          if (entityWordMap[entityDetails.entity] === undefined) {
            entityWordMap[entityDetails.entity] = [];
          }
          entityWordMap[entityDetails.entity] = [
            ...entityWordMap[entityDetails.entity],
            entityDetails.word,
          ];
          return entityWordMap;
        });

        let chipDetails = [];
        for (let [entity, words] of Object.entries(entityWordMap)) {
          let entityColor = this.getEntityTypeColor(entity);
          //Filtering out dash characters until backend can handle the separating words bug
          if (words.includes("-")) {
            words = words.filter((a) => a !== "-");
          }

          markedResult = this.identifyEntities(
            markedResult,
            words,
            entityColor.darkColor
          );

          chipDetails.push({
            entityName: entity[0].toUpperCase() + entity.slice(1),
            wordCount: words.length,
            colors: entityColor,
          });
        }

        this.setState({
          processProgress: 100,
          processing: false,
          doneProcessing: true,
          encodedResults: markedResult,
          entityChips: chipDetails,
        });
      }
    } catch (err) {
      handleErrorMessage(
        err.message,
        500,
        this.props.dispatch,
        ACTION_AREA.ENTITY_RECOGNITION
      );
    }
  }

  identifyEntities(result, entityNames, color) {
    // Create a regular expression pattern to match any word in the entityNames
    // This regex checks for words that match from right to left and visa versa
    // this will help with languages that are written from left to right eg Arabic
    const pattern = new RegExp(
      `(?<!\\p{L}\\p{M}*|[\\p{N}_])(?:${entityNames.join(
        "|"
      )})(?![\\p{L}\\p{N}_])`,
      "igu"
    );

    const styledString = result.replace(
      pattern,
      `<span style="text-decoration: underline; color: ${color};">$&</span>`
    );

    return styledString;
  }

  getEntityTypeColor(entity) {
    switch (entity) {
      case ENTITY_TYPE.ORGANISATION:
        return { darkColor: "#428080", lightColor: "#F4F9F9" };
      case ENTITY_TYPE.DATE:
        return { darkColor: "#BDDB3D", lightColor: "#FAFDE8" };
      case ENTITY_TYPE.PERSON:
        return { darkColor: "#FD3661", lightColor: "#FFF0F2" };
      default: // LOCATION
        return { darkColor: "#838383", lightColor: "#F6F6F6" };
    }
  }

  setIsRating() {
    this.setState({ isRating: true });
    this.props.dispatch({
      type: "UPDATE_SHOW_RATE_SERVICE_MODAL",
      payload: { show: true },
    });
  }

  handleUpdateProcessing() {
    this.setState({ processing: true });
  }

  getContent() {
    const {
      entityChips,
      entityInputValue,
      encodedResults,
      processing,
      doneProcessing,
      isRating,
    } = this.state;
    let cardContent = {
      title: entityRecognitionPageInfo.cardTitle,
      resultChips: entityChips,
      encodedResults: encodedResults,
      entityInputValue: entityInputValue,
      placeholderText: entityRecognitionPageInfo.placeholderText,
      processing: processing,
      doneProcessing: doneProcessing,
      buttonText: entityRecognitionPageInfo.buttonText,
      tryAgainButtonText: entityRecognitionPageInfo.tryAgainButtonText,
      rateUsButtonText: entityRecognitionPageInfo.rateUsButtonText,
      isRating: isRating,
      modalInfo: ratingModalNERInfo,
    };

    return cardContent;
  }

  render() {
    let {
      encodedResults,
      errorMessage,
      failureArea,
      processing,
      processProgress,
      doneProcessing,
      isMobile,
    } = this.state;

    return (
      <div className="entity-container page-background">
        {/* TITLE */}
        <div
          className={
            isMobile
              ? "vulavula-heading-3 text-align-center"
              : "title-container"
          }
        >
          {entityRecognitionPageInfo.title}
        </div>

        {/* SUB TITLE */}
        <div
          className="vulavula-paragraph text-align-center"
          dangerouslySetInnerHTML={{
            __html: entityRecognitionPageInfo.subtitle,
          }}
        />

        {errorMessage !== "" &&
          failureArea === ACTION_AREA.ENTITY_RECOGNITION ? (
          <div className="entity-error-card-container">
            <ErrorCard
              errorMessage={errorMessage}
              handleClearStates={this.handleClearStates}
            />
          </div>
        ) : (!processing && !doneProcessing) ||
          (doneProcessing && encodedResults !== "") ? (
          // ENTITY INPUT
          <CustomTextAreaCard
            dispatch={this.props.dispatch}
            globalState={this.props.globalState}
            cardContent={this.getContent()}
            handleInputUpdate={this.handleInputUpdate}
            handleInputProcess={this.handleEntityRecognition}
            handleClearStates={this.handleClearStates}
            handleUpdateProcessing={this.handleUpdateProcessing}
            setIsRating={this.setIsRating}
          />
        ) : (
          <div className="process-loader-container">
            {/* processing LOADER */}
            <div
              className="vulavula-paragraph-bold flashing-text"
              style={{ color: "#E29900", paddingTop: "30px" }}
            >
              {progessLoaderText}
            </div>
            <div className="progress-bar-container">
              <ProgressBar processProgress={processProgress} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

EntityRecognition.propTypes = {
  navigate: PropTypes.func,
  globalState: PropTypes.object,
  dispatch: PropTypes.func,
};

const EntityRecognitionWithEffect = (props) => {
  const nav = props.navigate;
  useEffect(() => {
    // Check if the user has accepted terms for EntityRecognition
    const hasAcceptedTerms = getCookie("consent_ner");

    if (!hasAcceptedTerms) {
      nav(HOME);
    }
  }, [nav]);

  return <EntityRecognition {...props} />;
};

export default EntityRecognitionWithEffect;
