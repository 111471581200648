import { Component } from "react";
import { vulaApi } from "../../../helpers/api/apiHelper";

class StripePlansPage extends Component {
  constructor(props) {
    super(props);

    const { sessionToken } = this.props.globalState;
    this.state = {
      sessionToken: sessionToken,
      clientSecret: null,
    }
  }

  componentDidMount() {
    vulaApi.get("billing/pricing-table/customer-session", { headers: { "Authorization": `Bearer ${this.state.sessionToken}` } })
      .then((response) => {
        this.setState({ clientSecret: response.data.client_secret });
      })

    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js'; // Example URL
    script.async = true;
    script.onload = () => {
      // Additional logic if needed after script loads
    };

    // Append the script to a specific element or body
    document.querySelector('#pricing-table-container').appendChild(script);
  }

  render() {
    return (
      <div className="billing-page-container">
        <div className="billing-section-container">
          <div className="billing-title-container">
            <div className="vulavula-heading-3">Choose a plan</div>
            <div className="vulavula-paragraph">
              Please use the email address that was used to log into the Vulavula platform to enable a seamless sync back to your account. Reach out to <a href="mailto:support@lelapa.ai">support@lelapa.ai</a> for any assistance
            </div>
            <div className="card-set-container" style={{ "display": "inline-table" }}>
              <div className="vulavula-paragraph" id="pricing-table-container">
                <stripe-pricing-table
                  pricing-table-id="prctbl_1PqCBR08MYZn8qkBJBUN7FM4"
                  publishable-key="pk_live_51PDobQ08MYZn8qkBfGSwcIaincKk6bQxCZptNso6Ttk8i1cI7JR9EEy85l9LgfzGrWFMEi91Nhyg5c4zMPeNNZmy00IyhEfKAC"
                  customer-session-client-secret={this.state.clientSecret}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StripePlansPage;