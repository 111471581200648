import { generalErrors, resetPasswordCodeInfo, resetPasswordInfo } from "../../../cms/generalCMS";
import CustomForm from "../../../components/form/CustomForm";
import React, { useState } from "react";
import { ACTION_AREA, RESET_PASSWORD, SIGNIN } from "../../../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { handleErrorMessage } from "../../../helpers/api/apiHelper";
import { submitResetPasswordCode } from "../../../helpers/api/auth"
import { PasswordResetMissingDetails } from "../../../components/auth";
import { isValidEmail } from "../../../utils";

export const ResetPasswordCode = ({ globalState: { errorDetails }, dispatch }) => {
  const navigate = useNavigate();
  const [validate, setValidate] = useState(false)
  const [code, setCode] = useState("")
  const [codeErrorMsg, setCodeErrorMsg] = useState("")
  const [errorMessage, setErrorMessage] = useState(errorDetails.errorMessage);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get("email");
  const email = emailParam ? decodeURIComponent(emailParam) : '';

  if (!email || !isValidEmail(email)) {
    return <PasswordResetMissingDetails
      message={resetPasswordCodeInfo.missingDetailsText}
    />;
  }

  const handleCodeUpdate = (e) => {
    if (errorMessage !== "") {
      setErrorMessage("");
      handleErrorMessage("", 0, dispatch, ACTION_AREA.FORGOT_PASSWORD_CODE); //clear error message when editing code
    }

    setCode(e.target.value);
    handleCodeValidate(e.target.value)
  }

  const handleCodeValidate = (codeInput) => {
    if (codeInput === "") {
      setCodeErrorMsg("Code is required!")
      setValidate(true);
      return false;
    } else if (codeInput.length < 6) {
      setValidate(true);
      setCodeErrorMsg("Code is too short!")
      return false;
    }
    setValidate(false)
    setCodeErrorMsg("")
    return true;
  }

  const handleSubmitCode = async () => {
    if (!handleCodeValidate(code)) {
      return false;
    }
    setValidate(false)
    setIsLoading(true);
    try {
      const response = await submitResetPasswordCode(code, email, dispatch)
      if (response) {
        const { token } = response.data;
        navigate(`${RESET_PASSWORD}?email=${encodeURIComponent(email)}&token=${token}`);
      }
    }
    catch (error) {
      setCodeErrorMsg(generalErrors.apiSyntax);
    } finally {
      setIsLoading(false);
    }
  }

  const getResetPasswordDetails = () => {
    let inputFields = [
      {
        validate: validate,
        value: code,
        placeholder: "Verification Code",
        type: "code",
        valueErrorMsg: codeErrorMsg,
        handleFieldUpdate: handleCodeUpdate
      }
    ];

    let primaryButtons = [
      {
        buttonTextColor: code === "" ? "#B0B0B0" : '#fff',
        backgroundColor:
          code === "" ? "transparent" : "#cc4a2b",
        text: isLoading ? resetPasswordCodeInfo.submitButtonLoadingText : resetPasswordCodeInfo.submitButtonText,
        handleButtonAction: handleSubmitCode,
      },
    ];

    let secondaryButtons = [
      {
        enabled: true,
        text: resetPasswordInfo.signInButtonText,
        handleButtonAction: () => navigate(SIGNIN),
      },
    ];

    return { inputFields, primaryButtons, secondaryButtons };
  }

  const details = getResetPasswordDetails();

  return (
    <div className="v-w-full v-d-flex  v-justify-center v-align-center">
      <div className="login-container v-w-70 v-text-center">
        <div className="v-d-flex v-flex-column v-mb-5">
          <div className="vulavula-card-heading v-mb-3">{resetPasswordCodeInfo.title}</div>
          <div className="vulavula-card-subheading">{resetPasswordCodeInfo.subtitle}</div>
        </div>

        <div className="login-content">
          <CustomForm
            inputFields={details.inputFields}
            primaryButtons={details.primaryButtons}
            secondaryButtons={details.secondaryButtons}
          />
        </div>
      </div>
    </div>
  )
}