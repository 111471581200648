import React, { Component } from "react";
import "./CookieBanner.css";
import "../../styles/type-scale-system.css";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { cookieInfo } from "../../cms/generalCMS";
import { setCookie, getCookie } from "../../helpers/cookie";

import Stack from "@mui/material/Stack";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";

class CookieBanner extends Component {
  constructor(props) {
    super(props);

    let { isMobile, cookieConsent } = this.props.globalState;

    this.state = {
      isMobile: isMobile,
      manageCookies: cookieConsent,
      selectedCookieOptions: [],
      bannerOpen: true, // this will depend on whether the user has accepted terms or not
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState !== this.props.globalState) {
      const { isMobile, cookieConsent } = this.props.globalState;
      this.setState({ isMobile: isMobile, manageCookies: cookieConsent });
    }
  }

  updateManageCookiesState = () => {
    this.setState({ manageCookies: !this.state.manageCookies });
  };

  handleCookieOptionSelection = (option) => {
    let selectedCookies = [...this.state.selectedCookieOptions];

    if (selectedCookies.length !== 0 && selectedCookies.includes(option)) {
      // If the option is already selected, remove it
      const updatedCookieOptions = selectedCookies.filter((o) => o !== option);
      selectedCookies = updatedCookieOptions;
    } else {
      // If the option is not selected, add it
      const updatedCookieOptions = [...selectedCookies, option];
      selectedCookies = updatedCookieOptions;
    }

    this.setState({ selectedCookieOptions: selectedCookies });
  };

  handleGenerateCookie = () => {
    // Set expiry date to 6 months from now
    setCookie("cookie_consent", "true", 180);
    const cookieConsent = getCookie("cookie_consent");
    this.props.dispatch({
      type: "UPDATE_COOKIE_CONSENT",
      payload: cookieConsent,
    });
  };

  render() {
    const { isMobile, manageCookies, selectedCookieOptions, bannerOpen } =
      this.state;

    return (
      <TrapFocus open disableAutoFocus disableEnforceFocus>
        <Fade appear={false} in={bannerOpen}>
          <Paper
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            variant="outlined"
            tabIndex={-1}
            sx={{
              position: "fixed",
              bottom: "20px",
              right: 0,
              m: 0,
              p: 2,
              borderWidth: 1,
              maxWidth: isMobile ? "92%" : " 50%",
              padding: "30px",
            }}
          >
            <Stack
              direction={{ xs: "column", sm: manageCookies ? "column" : "row" }}
              justifyContent="space-between"
              gap={4}
            >
              <Box
                className="cookie-title-container"
                sx={{
                  flexShrink: 1,
                  alignSelf: { xs: "flex-start", sm: "center" },
                }}
              >
                <div className="vulavula-card-heading">
                  {manageCookies
                    ? cookieInfo.manageCookiesTitle
                    : cookieInfo.title}
                </div>
                <div
                  className="vulavula-paragraph"
                  dangerouslySetInnerHTML={{
                    __html: manageCookies
                      ? cookieInfo.manageCookiesBody
                      : cookieInfo.body,
                  }}
                />

                {manageCookies &&
                  cookieInfo.cookieOptions.map((option, i) => {
                    let checked = selectedCookieOptions.includes(option);
                    return (
                      <div className="cookie-option-container" key={i}>
                        <div
                          className={`custom-checkbox ${
                            checked || !option.editable ? "checked" : ""
                          }`}
                          onClick={() =>
                            option.editable &&
                            this.handleCookieOptionSelection(option)
                          }
                        >
                          {(checked || !option.editable) && (
                            <div className="tick-mark">&#10003;</div>
                          )}
                        </div>

                        <div className="vulavula-paragraph">{option.title}</div>
                      </div>
                    );
                  })}
              </Box>

              <Stack
                gap={manageCookies ? 3 : 2}
                direction={manageCookies ? "column" : "row"}
                sx={{
                  flexShrink: 0,
                  alignSelf: manageCookies ? "center" : "flex-end",
                }}
              >
                {/* MANAGE COOKIES */}
                {!manageCookies && cookieInfo.cookieOptions.length > 0 && (
                  <div
                    className="button-primary-white-large form-secondary-button"
                    style={{
                      color: "#3A2730",
                      border: "1px solid #3a2730",
                    }}
                    onClick={() => this.updateManageCookiesState()}
                  >
                    <div className="cookie-button-text blue-gray-color">
                      {cookieInfo.manageCookiesButtonText}
                    </div>
                  </div>
                )}

                {/* ACCEPT */}
                <div
                  className="button-primary-red-larger form-primary-button"
                  onClick={() => this.handleGenerateCookie()}
                >
                  <div
                    className="cookie-button-text"
                    style={{ color: "white" }}
                  >
                    {cookieInfo.acceptButtonText}
                  </div>
                </div>

                {/* PRIVACY POLICY LINK */}
                {manageCookies && (
                  <div
                    className="vulavula-paragraph"
                    dangerouslySetInnerHTML={{
                      __html: cookieInfo.privacyPolicyLink,
                    }}
                  />
                )}
              </Stack>
            </Stack>
          </Paper>
        </Fade>
      </TrapFocus>
    );
  }
}

CookieBanner.propTypes = {
  globalState: PropTypes.object,
  dispatch: PropTypes.func,
};

export default CookieBanner;
