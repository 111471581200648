import { resetPasswordSuccessInfo } from "../../../cms/generalCMS";
import CustomForm from "../../../components/form/CustomForm";
import React from "react";
import { SIGNIN } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";

export const ResetPasswordSuccess = () => {
  const navigate = useNavigate();
  const getResetPasswordSuccessDetails = () => {

    let primaryButtons = [
      {
        backgroundColor: "#cc4a2b",
        text: resetPasswordSuccessInfo.resetSuccessButtonText,
        handleButtonAction: () => navigate(SIGNIN),
      },
    ];

    return { primaryButtons };
  }

  const details = getResetPasswordSuccessDetails();

  return (
    <div className="v-w-full v-d-flex  v-justify-center v-align-center">
      <div className="login-container v-d-flex v-w-70 v-text-center">
        <div className="v-d-flex v-flex-column v-mb-5">
          <div className="vulavula-card-heading v-mb-3">{resetPasswordSuccessInfo.title}</div>
          <div className="vulavula-card-subheading">{resetPasswordSuccessInfo.subtitle}</div>
        </div>

        <div className="login-content">
          <CustomForm
            primaryButtons={details.primaryButtons}
            separator={false}
          />
        </div>
      </div>
    </div>
  )
}