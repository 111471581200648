import axios from "axios";
import {
  DEFAULT_ERROR_MESSAGE,
} from "../../utils/constants";
import { appConfig } from "../../config";
import { deleteCookie } from "../cookie";
import { HOME } from "../../utils/constants";


export const vulaApi = axios.create({
  baseURL: appConfig.VULAVULA_SERVICES_URL,
});

export const handleLogout = (dispatch, navigate) => {
  deleteCookie("sessionToken");
  deleteCookie("requestClientToken");

  const pathName = window.location.pathname;
  if (pathName !== HOME) {
    navigate(HOME);
  }

  dispatch({
    type: "UPDATE_SESSION_TOKEN",
    payload: "",
  });
  dispatch({
    type: "UPDATE_ENABLE_BILLING",
    payload: { enable: false, error: false, fetched: false },
  });
}

export const handleSocialLoginResponse = (provider, auth_url) => {
  const urlParams = new URLSearchParams(auth_url);
  const urlState = urlParams.get("state");

  window.localStorage.setItem("provider", provider);
  window.localStorage.setItem("providerState", urlState);

  window.location.assign(auth_url);
};

export const handleErrorMessage = (errMsg, code, dispatch, area) => {
  if (code !== 0) {
    switch (code) {
      case 400:
        break;
      case 401:
        errMsg = errMsg.concat(". ", DEFAULT_ERROR_MESSAGE.REFRESH);
        break;
      case 429:
        errMsg = errMsg.concat(". ", DEFAULT_ERROR_MESSAGE.RETRY);
        break;
      case 503:
        errMsg = DEFAULT_ERROR_MESSAGE.MODEL_ASLEEP;
        break;
      default:
        errMsg = DEFAULT_ERROR_MESSAGE.SOMETHING_WENT_WRONG;
        break;
    }
  }

  if (dispatch !== null) {
    dispatch({
      type: "UPDATE_ERROR_MESSAGE",
      payload: {
        errorMessage: errMsg,
        failureArea: area,
      },
    });
  }
};