import {
  TRANSCRIBE,
  ENTITY_RECOGNITION,
  SENTIMENT_ANALYSIS
} from "../utils/constants"
import { getCookie } from "./cookie";


export const getProductTermsConsent = (navigation) => {
  switch (navigation) {
    case TRANSCRIBE:
      return getCookie("consent_transcribe");
    case ENTITY_RECOGNITION:
      return getCookie("consent_ner");
    case SENTIMENT_ANALYSIS:
      return getCookie("consent_sentimental");
    default:
      return false;
  }
};