export const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
        errors.push("Password must be at least 8 characters.");
    } else if (!/\d/.test(password)) {
        errors.push("Password must contain at least one digit.");
    } else if (!/[A-Z]/.test(password)) {
        errors.push("Password must contain at least one uppercase letter.");
    } else if (!/[a-z]/.test(password)) {
        errors.push("Password must contain at least one lowercase letter.");
    }

    return errors;
};