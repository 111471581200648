import React, { Component, useEffect } from "react";
import "./KYCPage.css";
import "../../styles/type-scale-system.css";
import { kycInfo } from "../../cms/generalCMS";
import VerticalBrandLogo from "../../icons/brand-logo-vertical.svg";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { HOME } from "../../utils/constants";
import ProtoType from "prop-types";
import { getCookie } from "../../helpers/cookie";
import { handleKYCSubmit } from "../../helpers/api/kyc"

class KYC_PAGE extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      answers: [],
      inputValue: "",
      complete: false,
      processProgress: 0,
    };

    const storedToken = getCookie("sessionToken");
    this.sessionToken = storedToken;

    this.handleValueUpdate = this.handleValueUpdate.bind(this);
  }

  handleAnswerChange = (index, answer) => {
    const newAnswers = [...this.state.answers];
    let currentAnswers = newAnswers[index];

    if (currentAnswers !== undefined && currentAnswers.includes(answer)) {
      // If the answer is already selected, remove it
      const updatedAnswers = currentAnswers.filter((a) => a !== answer);
      newAnswers[index] = updatedAnswers;
    } else {
      // If the answer is not selected, add it
      if (currentAnswers === undefined) {
        currentAnswers = "";
      }
      const updatedAnswers = [...currentAnswers, answer];
      newAnswers[index] = updatedAnswers;
    }

    this.setState({ answers: newAnswers });
  };

  handleValueUpdate(e) {
    this.setState({ inputValue: e.target.value });
  }

  handleNextPage = () => {
    const { currentPage, inputValue, answers } = this.state;
    if (currentPage < kycInfo.questions.length - 1) {
      this.setState({ currentPage: currentPage + 1 });
    }

    const stateAnswers = [...answers];
    if (inputValue !== "") {
      let currentAnswers = stateAnswers[currentPage];
      let currentQuestion = kycInfo.questions[kycInfo.questions.length - 1];
      let lastOption =
        currentQuestion.options[currentQuestion.options.length - 1];

      if (currentAnswers.includes(lastOption)) {
        let updatedAnswers = currentAnswers.filter((a) => a !== lastOption);
        updatedAnswers.push(inputValue);
        stateAnswers[currentPage] = updatedAnswers;

        this.setState({
          answers: stateAnswers,
          inputValue: "",
        });
      }
    }

    if (currentPage === kycInfo.questions.length - 1) {
      this.setState({
        complete: true,
      });
      handleKYCSubmit(stateAnswers, this.sessionToken, this.props.dispatch);
      this.handleProgressBar();
    }
  };

  renderDots = () => {
    return kycInfo.questions.map((_, index) => (
      <span
        key={index}
        className={this.state.currentPage === index ? "dot active" : "dot"}
      ></span>
    ));
  };

  isNextButtonEnabled = () => {
    return this.state.answers[this.state.currentPage] !== "";
  };

  handleProgressBar = () => {
    const simulateProgress = () => {
      const totalSteps = 100;
      let currentStep = 0;

      const progressInterval = setInterval(() => {
        currentStep++;
        this.setState({ processProgress: (currentStep / totalSteps) * 100 });

        if (currentStep === totalSteps) {
          clearInterval(progressInterval);
          currentStep = 0;
          this.props.navigate(HOME);
        }
      }, 50);
    };

    simulateProgress();
  };

  render() {
    const { currentPage, answers, complete, processProgress } = this.state;
    const currentPageDetails = kycInfo.questions[currentPage];

    return (
      <div className="kyc-outer-container">
        {!complete ? (
          <div className="inner-content-container">
            <div className="vulavula-card-heading">{kycInfo.title}</div>

            {/* QUESTIONS */}
            <div className="questions-container">
              <div
                className="vulavula-paragraph-bold"
                style={{ textAlign: "left" }}
              >
                {currentPageDetails.heading}
              </div>

              <div className="question-inner-container">
                {currentPageDetails.options.map((question, i) => {
                  let checked = answers[currentPage]?.includes(question);

                  return (
                    <div className="checkbox-container" id={i}>
                      <div
                        className={`custom-checkbox ${checked ? "checked" : ""
                          }`}
                        onClick={() =>
                          this.handleAnswerChange(currentPage, question)
                        }
                      >
                        {checked && <div className="tick-mark">&#10003;</div>}
                      </div>

                      <div className="vulavula-paragraph">{question}</div>
                    </div>
                  );
                })}

                {currentPageDetails.options[
                  currentPageDetails.options.length - 1
                ] === "Other" &&
                  answers[currentPage]?.includes(
                    currentPageDetails.options[
                    currentPageDetails.options.length - 1
                    ]
                  ) && (
                    <div className="input-container">
                      <input
                        className="standard-input vulavula-paragraph"
                        name="Other"
                        autoComplete="false"
                        placeholder="Enter option."
                        onInput={this.handleValueUpdate}
                      />
                    </div>
                  )}
              </div>
            </div>

            <div className="buttons-container">
              {/* NEXT BUTTON */}
              <div
                className={
                  answers[currentPage] === undefined ||
                    answers[currentPage]?.length === 0
                    ? "button-primary-white-no-border-large"
                    : "button-primary-red-larger"
                }
                style={{ height: "auto" }}
                onClick={
                  answers[currentPage] !== undefined ||
                    answers[currentPage]?.length > 0
                    ? this.handleNextPage
                    : undefined
                }
              >
                <div
                  className="button-text"
                  style={{
                    color:
                      answers[currentPage] === undefined ||
                        answers[currentPage]?.length === 0
                        ? "#D1D1D1"
                        : "#FFFFFF",
                  }}
                >
                  {currentPage === kycInfo.questions.length - 1
                    ? kycInfo.finishButtonText
                    : kycInfo.nextButtonText}
                </div>
              </div>

              {/* DOTS */}
              <div className="dots-container">{this.renderDots()}</div>
            </div>
          </div>
        ) : (
          <div className="kyc-complete-container">
            <img
              className="vertical-brand-logo"
              src={VerticalBrandLogo}
              alt="vertical brand logo"
            />
            <div className="kyc-progress-bar">
              <ProgressBar processProgress={processProgress} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

KYC_PAGE.protoType = {
  navigate: ProtoType.func,
  dispatch: ProtoType.func,
};

const KYC_PAGEWithEffect = (props) => {
  const nav = props.navigate;
  useEffect(() => {
    // Check if the user is logged in
    const sessionToken = getCookie("sessionToken");
    if (sessionToken === null || sessionToken === "") {
      nav(HOME);
    }
  }, [nav]);

  return <KYC_PAGE {...props} />;
};

export default KYC_PAGEWithEffect;
