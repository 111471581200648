import jwtDecode from "jwt-decode";
import {appConfig} from "../../config";
import {
  ACTION_AREA,
  DASHBOARD
} from "../../utils/constants"
import {vulaApi, handleErrorMessage} from "./apiHelper";
import {
  setCookie,
  deleteCookie
} from "../cookie"


export const handleSocialCallback = async (
  provider,
  urlCode,
  providerState,
  dispatch
) => {
  try {
    return vulaApi
      .get(
        `${appConfig.VULAVULA_AUTH_API_BASE_ENDPOINT}/${provider}/${appConfig.VULAVULA_SOCIAL_LOGIN_CALLBACK}`,
        {
          params: {
            code: urlCode,
            state: providerState,
            redirect_uri: appConfig.VULAVULA_WEB_BASE_URL,
          },
          headers: {
            Accept: "application/xhtml+xml",
          },
        }
      )
      .then((resp) => {
        if (resp.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.LOGIN);
          return null;
        }
        setCookie("sessionToken", resp.data.access_token, 180);
        deleteCookie("requestClientToken"); // delete the requestClientToken cookie since it belongs to playground user

        return resp.data;
      })
      .catch((err) => {
        if (err.response !== undefined) {
          handleErrorMessage(
            err.response.data.detail,
            err.response.status,
            dispatch,
            ACTION_AREA.LOGIN
          );
        } else {
          handleErrorMessage(err.message, 500, dispatch, ACTION_AREA.LOGIN);
        }

        return null;
      });
  } catch (err) {
    handleErrorMessage("", 500, dispatch, ACTION_AREA.LOGIN);
    return null;
  }
};

export const fetchPlaygroundSessionToken = async () => {
  try {
    const storedToken = localStorage.getItem("playgroundSession");
    let isTokenExpired = true;

    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      isTokenExpired = decodedToken.exp * 1000 < Date.now();
    }

    if (!isTokenExpired) {
      return {data: storedToken, fetched: true};
    }

    const response = await vulaApi.post(
      appConfig.VULAVULA_PLAYGROUND_TOKEN_API_ENDPOINT,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": appConfig.VULAVULA_PLAYGROUND_API_KEY,
        },
      }
    );

    const data = response.data;
    localStorage.setItem("playgroundSession", data.access_token);

    return {data: data.access_token, fetched: true};

  } catch (error) {
    if (error.response !== undefined) {
      handleErrorMessage(
        error.response.data.detail,
        error.response.status,
        null,
        ACTION_AREA.UNKNOWN
      );
    } else {
      handleErrorMessage(error.message, 500, null, ACTION_AREA.UNKNOWN);
    }

    return {data: "", fetched: true};
  }
};

export const createClientToken = async (
  playgroundSession,
  dispatch,
  playgroundUse
) => {
  try {
    if (!playgroundSession) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.DASHBOARD);
      return {
        id: 0,
        token: "",
        expiration_time: "",
        fetched: true,
        error: true,
      };
    }

    return vulaApi
      .post(
        appConfig.VULAVULA_CLIENT_TOKEN_CREATE_API,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${playgroundSession}`,
            "X-PLAYGROUND": playgroundUse,
          },
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.DASHBOARD);
          return null;
        }
        var data = response.data;
        return {
          id: data.id,
          token: data.token,
          expiration_time: data.expiration_time,
          fetched: true,
          error: false,
        };
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch,
            ACTION_AREA.DASHBOARD
          );
        } else {
          handleErrorMessage(
            error.message,
            500,
            dispatch,
            ACTION_AREA.DASHBOARD
          );
        }

        return {
          id: 0,
          token: "",
          expiration_time: "",
          fetched: true,
          error: true,
        };
      });
  } catch (error) {
    handleErrorMessage("", 500, dispatch, ACTION_AREA.DASHBOARD);
    return {
      id: 0,
      token: "",
      expiration_time: "",
      fetched: true,
      error: true,
    };
  }
};

export const getCustomerAPIKeys = async (sessionToken, dispatch) => {
  try {
    if (!sessionToken) {
      console.error("Session token is required");
      return [];
    }

    let user_id = 0;
    if (sessionToken) {
      const decodedToken = jwtDecode(sessionToken);
      user_id = decodedToken.sub;
    }

    if (user_id === 0) {
      return [];
    }

    return vulaApi
      .get(`${appConfig.VULAVULA_CLIENT_TOKEN_API}/${user_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.DASHBOARD);
          return [];
        }

        return response.data;
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage(error.message, 500, dispatch, DASHBOARD);
        }

        return [];
      });
  } catch (error) {
    handleErrorMessage("", 500, dispatch, ACTION_AREA.DASHBOARD);
    return [];
  }
};

export const deleteAPIKey = async (sessionToken, id, dispatch) => {
  try {
    if (!sessionToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.DASHBOARD);
      return false;
    }

    let user_id = 0;
    if (sessionToken) {
      const decodedToken = jwtDecode(sessionToken);
      user_id = decodedToken.sub;
    }

    if (user_id === 0) {
      return;
    }

    return vulaApi
      .delete(`${appConfig.VULAVULA_CLIENT_TOKEN_API}/${id}/${user_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.DASHBOARD);
          return false;
        }

        return true;
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch
          );
        } else {
          handleErrorMessage(error.message, 500, dispatch, DASHBOARD);
        }

        return false;
      });
  } catch (error) {
    handleErrorMessage("", 500, dispatch, DASHBOARD);
    return false;
  }
};

export const submitJWTLogin = async (email, password, dispatch) => {
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("password", password);
    bodyFormData.append("username", email);

    return vulaApi
      .post(
        `${appConfig.VULAVULA_AUTH_API_BASE_ENDPOINT}/jwt/login`,
        bodyFormData
      )
      .then((resp) => {
        if (resp.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.LOGIN);
          return null;
        }

        var data = resp.data;
        setCookie("sessionToken", data.access_token, 180);
        deleteCookie("requestClientToken"); // delete the requestClientToken cookie since it belongs to playground user;
        return data;
      })
      .catch((err) => {
        if (err.response !== undefined) {
          handleErrorMessage(
            err.response.data.detail,
            err.response.status,
            dispatch,
            ACTION_AREA.LOGIN
          );
        } else {
          handleErrorMessage(err.message, 500, dispatch, ACTION_AREA.LOGIN);
        }

        return null;
      });
  } catch (err) {
    handleErrorMessage(err.message, 500, dispatch, ACTION_AREA.LOGIN);
    return null;
  }
};

export const submitOAuthLogin = async (provider, dispatch) => {
  try {
    return vulaApi
      .get(`${appConfig.VULAVULA_AUTH_API_BASE_ENDPOINT}/${provider}/authorize`)
      .then((resp) => {
        if (resp.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.LOGIN);
          return null;
        }

        return resp.data;
      })
      .catch((err) => {
        if (err.response !== undefined) {
          handleErrorMessage(
            err.response.data.detail,
            err.response.status,
            dispatch,
            ACTION_AREA.LOGIN
          );
        } else {
          handleErrorMessage(err.message, 500, dispatch, ACTION_AREA.LOGIN);
        }

        return null;
      });
  } catch (err) {
    handleErrorMessage(err.message, 500, dispatch, ACTION_AREA.LOGIN);
    return null;
  }
};

export const registerUser = async (fullName, email, password, dispatch) => {
  try {
    let data = {
      email: email,
      password: password,
      is_active: true,
      is_superuser: false,
      is_verified: false,
      full_name: fullName,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${appConfig.VULAVULA_AUTH_API_BASE_ENDPOINT}/register`,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return vulaApi
      .request(config)
      .then((response) => {
        if (response.status === 201) {
          return true;
        }

        handleErrorMessage("", 500, dispatch, ACTION_AREA.SIGNUP);
        return false;
      })
      .catch((error) => {
        if (error.response !== undefined) {
          handleErrorMessage(
            error.response.data.detail,
            error.response.status,
            dispatch,
            ACTION_AREA.SIGNUP
          );
        } else {
          handleErrorMessage(error.message, 500, dispatch, ACTION_AREA.SIGNUP);
        }

        return false;
      });
  } catch (err) {
    handleErrorMessage(err.message, 500, dispatch, ACTION_AREA.SIGNUP);
    return false;
  }
};

export const submitResetPasswordCode = async (code, email, dispatch) => {
  try {
    const data = {
      email,
      code,
    };
    const response = await vulaApi.post(
      `${appConfig.VULAVULA_AUTH_API_BASE_ENDPOINT}/confirm-reset-password`,
      data
    );
    if (response.status !== 200) {
      const message = "Unexpected response received. Please try again.";
      handleErrorMessage(
        message,
        500,
        dispatch,
        ACTION_AREA.FORGOT_PASSWORD_CODE
      );
      return false;
    }
    return response;
  } catch (error) {
    if (error.response && error.response.status) {
      handleErrorMessage(
        error.message,
        error.response.status,
        dispatch,
        ACTION_AREA.FORGOT_PASSWORD_CODE
      );
    } else {
      handleErrorMessage(
        "Network error or server not responding",
        500,
        dispatch,
        ACTION_AREA.FORGOT_PASSWORD_CODE
      );
    }
    return false;
  }
};

export const submitPasswordReset = async (token, password, dispatch) => {
  try {
    const data = {
      token,
      password,
    };
    const response = await vulaApi.post(
      `${appConfig.VULAVULA_AUTH_API_BASE_ENDPOINT}/reset-password`,
      data
    );
    if (response.status !== 200) {
      const message = "Unexpected response received. Please try again.";
      handleErrorMessage(message, 500, dispatch, ACTION_AREA.RESET_PASSWORD);
      return false;
    }
    return response;
  } catch (error) {
    if (error.response && error.response.status) {
      handleErrorMessage(
        error.message,
        error.response.status,
        dispatch,
        ACTION_AREA.RESET_PASSWORD
      );
    } else {
      handleErrorMessage(
        "Network error or server not responding",
        500,
        dispatch,
        ACTION_AREA.RESET_PASSWORD
      );
    }
    return false;
  }
};

export const submitForgotPassword = async (email, dispatch) => {
  try {
    const data = {
      email,
    };
    const response = await vulaApi.post(
      `${appConfig.VULAVULA_AUTH_API_BASE_ENDPOINT}/forgot-password`,
      data
    );
    if (response.status !== 200) {
      const message = "Unexpected response received. Please try again.";
      handleErrorMessage(message, 500, dispatch, ACTION_AREA.FORGOT_PASSWORD);
      return false;
    }
    return response;
  } catch (error) {
    if (error.response && error.response.status) {
      handleErrorMessage(
        error.message,
        error.response.status,
        dispatch,
        ACTION_AREA.FORGOT_PASSWORD
      );
    } else {
      handleErrorMessage(
        "Network error or server not responding",
        500,
        dispatch,
        ACTION_AREA.FORGOT_PASSWORD
      );
    }
    return false;
  }
};