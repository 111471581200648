//ui
import StripeIcon from "../../icons/stripe-icon.svg";
import LockIcon from "../../icons/lock-icon.svg";
// Stripe React SDK
import { useState, useEffect } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";

import './PaymentForm.css';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { handleErrorMessage, vulaApi } from "../../helpers/api/apiHelper";


function PaymentForm({onSubmit, showForm, sessionToken, selectedDetails, setPaymentFormCompleted, nextFn, setPaymentMethodResponse}) {
    const stripe = useStripe();
    const elements = useElements();

    const [processing, setProcessing] = useState(false);
    const [newSubscriptionCallComplete, setNewSubscriptionCallComplete] = useState(false);

    const [cardNumberComplete, setCardNumberComplete] = useState(false);
    const [cardCvcComplete, setCardCvcComplete] = useState(false);
    const [cardExpiryComplete, setCardExpiryComplete] = useState(false);

    const [nameOnCard, setNameOnCard] = useState("");
    
    const [clientSecret, setClientSecret] = useState(null);

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        setProcessing(true);

        const cardNumberElement = elements.getElement(CardNumberElement);

        const {setupIntent, error} = await stripe.confirmCardSetup(
            clientSecret,
            {
              payment_method: {
                card: cardNumberElement,
                billing_details: {
                  name: nameOnCard,
                },
              },
            },
          );

        if (error) {
            handleErrorMessage(error.message, 500);
            // setError(error.message);
            console.log('SetupIntent error:', error);
            setProcessing(false);
            setPaymentMethodResponse({
            completed: true,
            success: false,
            error: "Failed to create subscription",
            });
        } else {
            console.log('SetupIntent succeeded:', setupIntent);
            setProcessing(false);
            nextFn();
            setPaymentMethodResponse({
                completed: true,
                success: true,
                error: "",
            });
        }
    };


    // Pass handleSubmit along to parent component so that it can be called from there
    useEffect(() => {
    if (onSubmit) {
        console.log("setting childOnSubmit", onSubmit)
        if (onSubmit) {
            console.log("child onSubmit set", onSubmit)
            onSubmit(handleSubmit);
        }
    }
    }, [onSubmit]);

    useEffect(() => {
        console.log("setPaymentFormCompleted", setPaymentFormCompleted)
        // Set `setPaymentFormCompleted` to true when all fields are completed
        if (cardNumberComplete && cardCvcComplete && cardExpiryComplete) {
            setPaymentFormCompleted(true);
        } else {
            setPaymentFormCompleted(false);
        }
    }, [cardNumberComplete, cardCvcComplete, cardExpiryComplete]);

    const cardNumberOptions = {
        "placeholder": "0000 0000 0000 0000",
    };

  useEffect(() => {
    if (newSubscriptionCallComplete){
        return;
    }

    const {selectedPlan, selectedPlanPrice, selectedPaymentMethod} = selectedDetails;
    // return if selectedPlan, selectedPlanPrice or selectedPaymentMethod is null
    if (!selectedPlan || !selectedPlanPrice || !selectedPaymentMethod) {
        return;
    };
    const createPaymentIntent = {
        "product_id": selectedPlan,
        "price_id": selectedPlanPrice,
        "payment_method_id": selectedPaymentMethod,
    };

    vulaApi.post("billing/new-subscription", createPaymentIntent, {
        headers: { Authorization: `Bearer ${sessionToken}` }
    })
        .then((response) => {
            console.log("setup_intent_id", response.data.setup_intent_id);
            setClientSecret(response.data.client_secret);
            setNewSubscriptionCallComplete(true);
        })
        .catch((error) => {
            console.log("error", error);
        });

    }, [selectedDetails, sessionToken, setNewSubscriptionCallComplete]);


    const handleCardNumberChange = (event) => {
        setCardNumberComplete(event.complete);
    };

    const handleCardCvcChange = (event) => {
        setCardCvcComplete(event.complete);
    };

    const handleCardExpiryChange = (event) => {
        setCardExpiryComplete(event.complete);
    };

    if (!showForm) {
        return null; // Render nothing if showForm is false
      };
    
    const handleNameOnCardUpdate = (event) => {
        setNameOnCard(event.target.value);
    };


    return (<>
            <div class="form-container">
            <div class="input-container">
                <div class="input-inner-container">
                    <div class="card-label">Name on card</div>
                    <input class={`standard-input vulavula-paragraph`} name="nameOnCard" autoComplete="false" 
                    type="text" placeholder="First Names Surname" onChange={handleNameOnCardUpdate} />
                </div>
            </div>
            <div class="input-container">
                <div class="input-inner-container">
                    <div class="card-label">Card Number</div>
                    <CardNumberElement class="standard-input vulavula-paragraph" id="StripeCardNumber" options={cardNumberOptions} onChange={handleCardNumberChange} />
                </div>
            </div>
            <div class="row-input-outer-container">
                <div class="input-container">
                    <div class="input-inner-container">
                        <div class="card-label">CVC</div>
                        <CardCvcElement class="standard-input vulavula-paragraph" id="StripeCardCvc" onChange={handleCardCvcChange} />
                    </div>
                </div>
                <div class="input-container">
                    <div class="input-inner-container">
                        <div class="card-label">Expiry Date</div>
                        <CardExpiryElement class="standard-input vulavula-paragraph" id="StripCardExpiry" onChange={handleCardExpiryChange} />
                    </div>
                </div>
            </div>
        </div><div className="secured-by-container">
                <div className="card-paragraph" style={{ color: "#6d6d6d" }}> Secured by </div>

                {/* STRIPE ICON */}
                <img src={StripeIcon} alt="stripe-icon" className="stripe-icon" width={42} height={21} />

                {/* LOCK ICON */}
                <img src={LockIcon} alt="lock-icon" className="lock-icon" width={16} height={16} />
            </div>

        <div 
            className={!processing ? `button-primary-red-larger` : `button-primary-white-no-border-large disabled`} 
            style={{ height: "auto" }} 
            onClick={!processing ? handleSubmit: null} >
          <div class="button-text" style={{ color: "#FFFFFF" }} >
            {!processing ? 'Pay Now' : 'Processing...'}
          </div>
        </div>
    </>);
};

export default PaymentForm;