import { forgotPasswordInfo, generalErrors } from "../../../cms/generalCMS";
import React, { useState } from "react";
import CustomForm from "../../../components/form/CustomForm";
import { handleErrorMessage } from "../../../helpers/api/apiHelper";
import { submitForgotPassword } from "../../../helpers/api/auth"
import {
  ACTION_AREA,
  FORGOT_PASSWORD_EMAIL_SENT,
  RESET_PASSWORD_CODE,
  SIGNIN
} from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { isValidEmail } from "../../../utils";

export const ForgotPassword = ({ globalState, dispatch }) => {
  const { errorDetails } = globalState;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [validate, setValidate] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorDetails.errorMessage);
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async () => {
    if (!handleValidateEmail(email)) {
      return false;
    }
    setValidate(false)
    setIsLoading(true);
    setEmailErrorMsg("");
    try {
      const response = await submitForgotPassword(email, dispatch);
      if (response) {
        navigate(`${FORGOT_PASSWORD_EMAIL_SENT}?email=${encodeURIComponent(email)}`);
      }
    } catch (error) {
      setEmailErrorMsg(generalErrors.apiSyntax);
    } finally {
      setIsLoading(false);
    }
  }

  const handleUseResetCode = () => {
    if (handleValidateEmail(email)) {
      navigate(`${RESET_PASSWORD_CODE}?email=${encodeURIComponent(email)}`);
    }
  }

  const handleBackButton = () => {
    navigate(SIGNIN)
  }


  const handleValidateEmail = (emailInput) => {
    if (emailInput === "") {
      setEmailErrorMsg(forgotPasswordInfo.emailIsInvalid)
      setValidate(true);
      return false;
    } else if (!isValidEmail(emailInput)) {
      setValidate(true);
      setEmailErrorMsg(forgotPasswordInfo.emailIsInvalid)
      return false;
    }
    setValidate(false)
    setEmailErrorMsg("")
    return true;
  }

  const handleEmailChange = (e) => {
    if (errorMessage !== "") {
      setErrorMessage("");
      handleErrorMessage("", 0, dispatch, ACTION_AREA.FORGOT_PASSWORD);
    }
    setEmail(e.target.value);
    handleValidateEmail(e.target.value);

  };

  const getForgotPasswordDetails = () => {
    let inputFields = [
      {
        validate: validate,
        value: email,
        placeholder: "Enter email",
        valueErrorMsg: emailErrorMsg,
        handleFieldUpdate: handleEmailChange,
        type: "email",
        name: "email",
      }
    ];

    let primaryButtons = [
      {
        buttonTextColor: email === "" || validate ? "#B0B0B0" : '#fff',
        backgroundColor:
          email === "" || validate ? "transparent" : "#cc4a2b",
        text: isLoading ? forgotPasswordInfo.forgotPasswordButtonLoadingText : forgotPasswordInfo.forgotPasswordButtonText,
        handleButtonAction: handleForgotPassword,
      },
    ];

    let secondaryButtons = [
      {
        enabled: true,
        text: forgotPasswordInfo.backButtonText,
        handleButtonAction: handleBackButton,
      },
    ];

    let conditionalButtons = [
      {
        enabled: true,
        text: forgotPasswordInfo.conditionalText,
        buttonText: forgotPasswordInfo.conditionalButtonText,
        handleButtonAction: handleUseResetCode
      }
    ]

    return { inputFields, primaryButtons, secondaryButtons, conditionalButtons };
  }

  const details = getForgotPasswordDetails();

  return (
    <div className="v-w-full v-d-flex v-justify-center v-align-center">
      <div className="login-container v-w-70 v-text-center">
        <div className="v-d-flex v-flex-column v-mb-5">
          <div className="vulavula-card-heading v-mb-3">{forgotPasswordInfo.title}</div>
          <div className="vulavula-card-subheading">{forgotPasswordInfo.subtitle}</div>
        </div>


        <div className="login-content">
          <CustomForm
            inputFields={details.inputFields}
            checkboxFields={details.checkboxFields}
            primaryButtons={details.primaryButtons}
            secondaryButtons={details.secondaryButtons}
            conditionalButtons={details.conditionalButtons}
          />
        </div>
      </div>
    </div>
  )
}