import React, { Component } from "react";
import "./Header.css";
import "../../styles/type-scale-system.css";
import BrandSymbol from "../../icons/lelapa-logo.svg";
import BrandWordmark from "../../icons/brand-wordmark.svg";
import PropTypes from "prop-types";
import {
  DASHBOARD,
  ENTITY_RECOGNITION,
  HOME,
  SIGNIN,
  SIGNUP,
  TERMSANDCONDITIONS,
  TRANSCRIBE,
  SENTIMENT_ANALYSIS,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  BILLING,
} from "../../utils/constants";
import { headerInfo } from "../../cms/generalCMS";
import DownChevron from "../../icons/down-chevron.svg";
import { Drawer } from "@mui/material";
import {
  handleErrorMessage
} from "../../helpers/api/apiHelper";
import { handleLogout } from "../../helpers/api/apiHelper"
import { getProductTermsConsent } from "../../helpers/consents"
import jwtDecode from "jwt-decode";


class Header extends Component {
  constructor(props) {
    super(props);
    const { showTermsModal, enableBilling } = this.props.globalState;
    this.state = {
      showDropdown: false,
      showModal: showTermsModal.show,
      hamburgerMenuOpen: false,
      enableBilling: enableBilling.enable,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState !== this.props.globalState) {
      const { showTermsModal, enableBilling } = this.props.globalState;
      this.setState({
        showModal: showTermsModal.show,
        enableBilling: enableBilling.enable,
      });
    }
  }

  getStyleBasedOnRoute(pathName) {
    if (
      pathName.includes(TERMSANDCONDITIONS) ||
      pathName.includes(SIGNIN) ||
      pathName.includes(SIGNUP) ||
      pathName.includes(RESET_PASSWORD) ||
      pathName.includes(FORGOT_PASSWORD)
    ) {
      return {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        zIndex: 1,
        background: "transparent",
      };
    }

    return {
      background: "#FAC200",
    };
  }

  handleProductNav(productNav) {
    let hasAcceptedTerms = getProductTermsConsent(productNav);

    if (!hasAcceptedTerms) {
      this.props.dispatch({
        type: "UPDATE_SHOW_TERMS_MODAL",
        payload: { show: !this.state.showModal, navigation: productNav },
      });

      this.setState({
        showModal: !this.state.showModal,
      });
    } else {
      this.props.navigate(productNav);
    }

    this.setState({ showDropdown: !this.state.showDropdown });
  }

  handleHamburgerMenuState() {
    this.setState((prevState) => {
      return { hamburgerMenuOpen: !prevState.hamburgerMenuOpen };
    });
  }

  handleMenuNavigation(navigation) {
    this.setState({ hamburgerMenuOpen: !this.state.hamburgerMenuOpen });

    if (
      navigation === TRANSCRIBE ||
      navigation === ENTITY_RECOGNITION ||
      navigation === SENTIMENT_ANALYSIS
    ) {
      this.handleProductNav(navigation);
    } else {
      this.props.navigate(navigation);
    }

    handleErrorMessage("", 0, this.props.dispatch, "");
  }

  render() {
    let { globalState, navigate } = this.props || {};
    const { isLoggedIn, isMobile, sessionToken } = globalState;
    let pathName = window.location.pathname;
    const { showDropdown, hamburgerMenuOpen, enableBilling } = this.state;

    let email = "";
    if (isLoggedIn) {
      email = jwtDecode(sessionToken).email;
    }

    return (
      <div
        className={isMobile ? "mobile-container" : "header-main"}
        style={{ ...this.getStyleBasedOnRoute(pathName) }}
      >
        {isMobile ? (
          <div className="mobile-inner-container">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.handleHamburgerMenuState()}
            >
              <img src={BrandSymbol} alt="lelapa-logo" />
            </div>

            <div
              className="vulavula-heading-4"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(HOME)}
            >
              {headerInfo.brandName}
            </div>

            {/* BURGER MENU */}
            <Drawer
              anchor="left"
              open={hamburgerMenuOpen}
              onClose={() => this.handleHamburgerMenuState()}
              PaperProps={{ sx: { height: "auto", borderRadius: "8px" } }}
            >
              <div className="burger-menu-container">
                <div>{email}</div>
                <div className="mobile-brand">
                  <div className="mobile-header-title">
                    <img src={BrandSymbol} alt="lelapa-logo" />
                    <div
                      className="vulavula-heading-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(HOME)}
                    >
                      {headerInfo.brandName}
                    </div>
                  </div>

                  <div className="mobile-header-subtitle">
                    <div className="powered-tag">
                      {headerInfo.poweredTagText}
                    </div>

                    <img src={BrandWordmark} alt="brand wordmark" />
                  </div>
                </div>

                {/* MENU OPTIONS */}
                <div className="menu-option-container">
                  {headerInfo.burgerMenuOptions.map((item, i) => {
                    return (
                      ((item.enabled && item.title !== "API Keygen") ||
                        (item.enabled &&
                          item.title === "API Keygen" &&
                          item.enabled &&
                          isLoggedIn)) && (
                        <div
                          key={i}
                          className="menu-option-inner-container"
                          onClick={() =>
                            this.handleMenuNavigation(item.navigation)
                          }
                        >
                          <img src={item.icon} alt={item.title} />
                          <div className="menu-option-title">{item.title}</div>
                        </div>
                      )
                    );
                  })}
                </div>

                {/* SIGN IN/LOG OUT BUTTON */}
                {isLoggedIn ? (
                  <button
                    className="button-primary-white barlow-20-dark"
                    style={{ width: "100%" }}
                    onClick={() => this.handleLogout()}
                  >
                    {headerInfo.logoutText}
                  </button>
                ) : (
                  <button
                    className="button-primary-red-larger barlow-20-light"
                    style={{ width: "100%" }}
                    onClick={() =>
                      this.handleMenuNavigation(
                        pathName === SIGNIN ? SIGNUP : SIGNIN
                      )
                    }
                  >
                    {pathName === SIGNIN
                      ? headerInfo.signupText
                      : headerInfo.loginText}
                  </button>
                )}
              </div>
            </Drawer>
          </div>
        ) : (
          <div className="container">
            <div className="brand">
              <div>
                <img src={BrandSymbol} alt="lelapa-logo" />
              </div>
              <div
                className="vulavula-heading-4"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(HOME)}
              >
                {headerInfo.brandName}
              </div>
              <div className="powered-tag">{headerInfo.poweredTagText}</div>
              <div>
                <img src={BrandWordmark} alt="brand wordmark" />
              </div>
            </div>

            {!pathName.includes("kyc") && (
              <div className="options-outer-container">
                <div className="email">{email}</div>
                <div className="playground-dropdown">
                  <div
                    className="playground-menu-container"
                    onClick={() =>
                      this.setState({ showDropdown: !showDropdown })
                    }
                  >
                    <button
                      className="button-primary-white-no-border-default barlow-20-dark"
                      style={{ padding: 0 }}
                    >
                      {headerInfo.playgroundText}
                    </button>

                    <img
                      className={`down-chevron-container ${
                        showDropdown ? "rotate180" : ""
                      }`}
                      src={DownChevron}
                      alt="down chevron"
                    />
                  </div>

                  {showDropdown && (
                    <div className="playground-dropdown-options">
                      {headerInfo.playgroundOptions.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className="dropdown-text"
                            onClick={() =>
                              this.handleProductNav(item.navigation)
                            }
                          >
                            {item.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                {isLoggedIn ? (
                  <div className="options">
                    <button
                      className="button-primary-white-no-border-default barlow-20-dark"
                      onClick={() => navigate(DASHBOARD)}
                    >
                      {headerInfo.apiKeysText}
                    </button>

                    {enableBilling && (
                      <button
                        className="button-primary-white-no-border-default barlow-20-dark"
                        onClick={() => navigate(BILLING)}
                      >
                        {headerInfo.billingText}
                      </button>
                    )}

                    <button
                      className="button-primary-white-no-border-default barlow-20-dark"
                      onClick={() => handleLogout(this.props.dispatch, this.props.navigate)}
                    >
                      {headerInfo.logoutText}
                    </button>
                  </div>
                ) : (
                  <button
                    className="button-primary-red-larger barlow-20-light"
                    onClick={() => {
                      navigate(pathName === SIGNIN ? SIGNUP : SIGNIN);
                      handleErrorMessage("", 0, this.props.dispatch, "");
                    }}
                  >
                    {pathName === SIGNIN
                      ? headerInfo.signupText
                      : headerInfo.loginText}
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  navigate: PropTypes.func,
  globalState: PropTypes.object,
  dispatch: PropTypes.func,
};

export default Header;
