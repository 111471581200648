import {appConfig} from "../../config";
import {
  DEFAULT_ERROR_MESSAGE,
  ACTION_AREA

} from "../../utils/constants"
import {vulaApi, handleErrorMessage} from "./apiHelper";
import {convertFileToBase64} from "../utils";
import {getCookie} from "../cookie"

export const transcribeAudioSync = async (file, clientToken, dispatch) => {
  try {
    if (!file || !clientToken) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.TRANSCRIBE);
      return null;
    }
    const jsonData = JSON.stringify({
      file_name: file.name ? file.name : "recording.wav",
      audio_blob: await convertFileToBase64(file),
      file_size: file.size,
    });

    const syncTranscribeResponse = await vulaApi
      .post(`${appConfig.VULAVULA_TRANSCRIBE_API_BASE_ENDPOINT}${appConfig.VULAVULA_TRANSCRIBE_SYNC_ENDPOINT}`, jsonData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CLIENT-TOKEN": `${clientToken}`,
        },
      });

    if (syncTranscribeResponse.status !== 200) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.TRANSCRIBE);
      return null;
    } else {
      return syncTranscribeResponse.data;
    }
  } catch (err) {
    if (err.response !== undefined) {
      handleErrorMessage(
        err.response.data.detail,
        err.response.status,
        dispatch,
        ACTION_AREA.TRANSCRIBE
      );
    } else {
      handleErrorMessage(
        err.message,
        500,
        dispatch,
        ACTION_AREA.TRANSCRIBE
      );
    }

    return null;
  }
}

export const submitTranscriptionCorrection = async (correction, dispatch) => {
  const upload_id = localStorage.getItem("upload_id");

  const jsonData = JSON.stringify({
    upload_id: upload_id,
    transcription_correction: correction,
  });

  let tokenObject = getCookie("requestClientToken");
  let requestClientToken = JSON.parse(tokenObject);

  try {
    await vulaApi
      .post(
        `${appConfig.VULAVULA_TRANSCRIBE_API_BASE_ENDPOINT}/${upload_id}/${appConfig.VULAVULA_TRANSCRIBE_SUBMIT_CORRECTION_API_ENDPOINT}`,
        jsonData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CLIENT-TOKEN": `${requestClientToken.token}`,
          },
        }
      )
      .then((resp) => {
        if (resp.status !== 200) {
          handleErrorMessage("", 500, dispatch, ACTION_AREA.TRANSCRIBE);
          return;
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          handleErrorMessage(
            err.response.data.detail,
            err.response.status,
            dispatch,
            ACTION_AREA.TRANSCRIBE
          );
        } else {
          handleErrorMessage(
            err.message,
            500,
            dispatch,
            ACTION_AREA.TRANSCRIBE
          );
        }
      });
  } catch (err) {
    handleErrorMessage(err.message, 500, dispatch, ACTION_AREA.TRANSCRIBE);
  }
};

export const transcriptionResults = async (
  handleTranscribeComplete,
  dispatch
) => {
  const upload_id = localStorage.getItem("upload_id");
  try {
    const ws = new WebSocket(
      `${appConfig.VULAVULA_SERVICES_WEBSOCKET_URL}/${appConfig.VULAVULA_TRANSCRIBE_WEBSOCKET_ENDPOINT}/${upload_id}`
    );

    ws.onopen = function () {
      var timeoutId;
      timeoutId = setTimeout(function () {
        ws.close();
        handleErrorMessage(
          DEFAULT_ERROR_MESSAGE.MODEL_ASLEEP,
          503,
          dispatch,
          ACTION_AREA.TRANSCRIBE
        );
      }, appConfig.VULAVULA_TRANSCRIBE_TIMEOUT);

      ws.onmessage = function (event) {
        clearTimeout(timeoutId);

        const jsonData = JSON.parse(event.data);
        if (jsonData !== null || jsonData !== undefined) {
          if (jsonData.status_code !== 200) {
            handleErrorMessage(
              jsonData.error_message,
              jsonData.status_code,
              dispatch,
              ACTION_AREA.TRANSCRIBE
            );
            return;
          }

          if (jsonData.postprocessed_text !== undefined) {
            let result = "";
            for (let i = 0; i < jsonData.postprocessed_text.length; i++) {
              let resp = jsonData.postprocessed_text[i];
              if (result === "") {
                result = resp.text;
              } else {
                result = result.concat(" ", resp.text);
              }
            }

            if (result === "") {
              handleErrorMessage("", 500, dispatch, ACTION_AREA.TRANSCRIBE);
            } else {
              handleTranscribeComplete(
                result,
                jsonData.language_id,
                ACTION_AREA.TRANSCRIBE
              );
            }
          }
        }
      };
    };

    ws.onerror = function () {
      handleErrorMessage(
        "Something went wrong. Please try again later.",
        500,
        dispatch,
        ACTION_AREA.TRANSCRIBE
      );
      return;
    };
  } catch (err) {
    handleErrorMessage(err.message, 500, dispatch, ACTION_AREA.TRANSCRIBE);
  }
};