import React, { Component } from "react";
import "./SignUp.css";
import "../../styles/type-scale-system.css";
import CustomForm from "../../components/form/CustomForm";
import { signupInfo } from "../../cms/generalCMS";
import GoogleIcon from "../../icons/google-icon.svg";
import GithubIcon from "../../icons/github-icon.svg";
import { SIGNIN, ACTION_AREA } from "../../utils/constants";
import LelalapaTree1 from "../../icons/lelapatree-1.svg";
import {
  registerUser
} from "../../helpers/api/auth";
import { handleErrorMessage } from "../../helpers/api/apiHelper";
import { submitOAuthLogin } from "../../helpers/api/auth"
import { handleSocialLoginResponse } from "../../helpers/api/apiHelper"
import PropTypes from "prop-types";
import { appConfig } from "../../config";

class SignUp extends Component {
  constructor(props) {
    super(props);

    const { errorDetails } = this.props.globalState;

    this.state = {
      username: "",
      email: "",
      password: "",
      confirmationPassword: "",
      accepted: false,
      validate: false,
      usernameErrorMsg: "",
      emailErrorMsg: "",
      passwordErrorMsg: "",
      confirmPasswordErrorMsg: "",
      signupComplete: false,
      errorMessage: errorDetails.errorMessage,
      failureArea: errorDetails.failureArea,
    };

    this.handleUsernameUpdate = this.handleUsernameUpdate.bind(this);
    this.handleEmailUpdate = this.handleEmailUpdate.bind(this);
    this.handlePasswordUpdate = this.handlePasswordUpdate.bind(this);
    this.handleConfirmPasswordUpdate =
      this.handleConfirmPasswordUpdate.bind(this);
    this.handleAcceptTerms = this.handleAcceptTerms.bind(this);
    this.errorValidation = this.errorValidation.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
    this.handleOAuthGoogleLogin = this.handleOAuthGoogleLogin.bind(this);
    this.handleOAuthGithubLogin = this.handleOAuthGithubLogin.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState !== this.props.globalState) {
      const { errorDetails } = this.props.globalState;
      this.setState({
        errorMessage: errorDetails.errorMessage,
        failureArea: errorDetails.failureArea,
      });
    }
  }

  handleUsernameUpdate(e) {
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
      handleErrorMessage("", 0, this.props.dispatch, ACTION_AREA.SIGNUP);
    }

    this.setState({ username: e.target.value });
    if (this.state.usernameErrorMsg !== "") {
      this.setState({ usernameErrorMsg: "" });
    }
  }

  handleEmailUpdate(e) {
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
      handleErrorMessage("", 0, this.props.dispatch, ACTION_AREA.SIGNUP);
    }

    this.setState({ email: e.target.value });
    let emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!e.target.value.match(emailFormat)) {
      this.setState({ validate: true });
      this.setState({ emailErrorMsg: "Enter valid email!" });
    } else if (this.state.emailErrorMsg !== "") {
      this.setState({ validate: false });
      this.setState({ emailErrorMsg: "" });
    }
  }

  handlePasswordUpdate(e) {
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
      handleErrorMessage("", 0, this.props.dispatch, ACTION_AREA.SIGNUP);
    }

    // TODO: ADD PASSWORD RULES
    this.setState({ password: e.target.value });
    if (this.state.passwordErrorMsg !== "") {
      this.setState({ passwordErrorMsg: "" });
    }
  }

  handleConfirmPasswordUpdate(e) {
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
      handleErrorMessage("", 0, this.props.dispatch, ACTION_AREA.SIGNUP);
    }

    let { password, confirmPasswordErrorMsg } = this.state;

    this.setState({ confirmationPassword: e.target.value });

    if (password !== e.target.value) {
      this.setState({ validate: true });
      this.setState({ confirmPasswordErrorMsg: "Password does not match!" });
    } else if (password === e.target.value && confirmPasswordErrorMsg !== "") {
      this.setState({ validate: false });
      this.setState({ confirmPasswordErrorMsg: "" });
    }
  }

  errorValidation() {
    const { username, email, password, confirmationPassword, accepted } =
      this.state;
    this.setState({ validate: true });

    if (username === "") {
      this.setState({ usernameErrorMsg: "Username is required!" });
    }
    if (email === "") {
      this.setState({ emailErrorMsg: "Email is required!" });
    }
    if (password === "") {
      this.setState({ passwordErrorMsg: "Password is required!" });
    }
    if (confirmationPassword === "") {
      this.setState({ confirmPasswordErrorMsg: "Password does not match!" });
    }

    if (
      username &&
      email &&
      password &&
      confirmationPassword &&
      confirmationPassword === password &&
      accepted
    ) {
      this.setState({ validate: false });
      this.handleSignup(username, email, password);
    }
  }

  async handleSignup(username, email, password) {
    await registerUser(username, email, password, this.props.dispatch).then(
      (success) => {
        if (success) {
          // TODO: SHOW SIGN UP COMPLETE PAGE ONCE EMAIL VERIFICATION WORK IS DONE
          // this.setState({ signupComplete: true });
          this.props.dispatch({
            type: "UPDATE_REGISTRATION_STATUS",
            payload: true,
          });
          this.props.navigate(SIGNIN);

          if (this.state.errorMessage !== "") {
            this.setState({ errorMessage: "" });
            handleErrorMessage("", 0, this.props.dispatch);
          }
        } else {
          this.setState({
            username: "",
            email: "",
            password: "",
            confirmationPassword: "",
            accepted: false,
            validate: false,
          });

          window.scrollTo(0, 0);
        }
      }
    );
  }

  async handleOAuthGoogleLogin() {
    var provider = "google";
    let { accepted } = this.state;

    if (!accepted) {
      this.setState({
        errorMessage: "Please accept the terms to continue!",
        failureArea: ACTION_AREA.SIGNUP,
      });
    } else {
      await submitOAuthLogin(provider, this.props.dispatch).then((loginUrl) => {
        if (loginUrl !== null) {
          handleSocialLoginResponse(provider, loginUrl.authorization_url);
        }
      });
    }
  }

  async handleOAuthGithubLogin() {
    var provider = "github";
    let { accepted } = this.state;

    if (!accepted) {
      this.setState({
        errorMessage: "Please accept the terms to continue!",
        failureArea: ACTION_AREA.SIGNUP,
      });
    } else {
      await submitOAuthLogin(provider, this.props.dispatch).then((loginUrl) => {
        if (loginUrl !== null) {
          handleSocialLoginResponse(provider, loginUrl.authorization_url);
        }
      });
    }
  }

  handleAcceptTerms() {
    this.setState((prevState) => {
      return {
        accepted: !prevState.accepted,
      };
    });
  }

  getSignupFormDetails() {
    let {
      username,
      email,
      password,
      confirmationPassword,
      accepted,
      usernameErrorMsg,
      emailErrorMsg,
      passwordErrorMsg,
      confirmPasswordErrorMsg,
      validate,
    } = this.state;

    let inputFields = [
      {
        validate: validate,
        value: username,
        placeholder: "Username",
        valueErrorMsg: usernameErrorMsg,
        handleFieldUpdate: this.handleUsernameUpdate,
      },
      {
        validate: validate,
        value: email,
        placeholder: "Email",
        valueErrorMsg: emailErrorMsg,
        handleFieldUpdate: this.handleEmailUpdate,
      },
      {
        validate: validate,
        value: password,
        placeholder: "Password",
        type: "password",
        valueErrorMsg: passwordErrorMsg,
        handleFieldUpdate: this.handlePasswordUpdate,
      },
      {
        validate: validate,
        value: confirmationPassword,
        placeholder: "Confirm Password",
        type: "password",
        valueErrorMsg: confirmPasswordErrorMsg,
        handleFieldUpdate: this.handleConfirmPasswordUpdate,
      },
    ];

    let checkboxFields = [
      {
        checked: accepted,
        checkboxText: signupInfo.checkboxText,
        handleCheckboxUpdate: this.handleAcceptTerms,
      },
    ];

    let primaryButtons = [
      {
        backgroundColor:
          username === "" ||
            email === "" ||
            password === "" ||
            confirmationPassword !== password ||
            !accepted
            ? "#B0B0B0"
            : "#cc4a2b",
        text: signupInfo.signUpButtonText,
        handleButtonAction: this.errorValidation,
      },
    ];

    let secondaryButtons = [
      {
        enabled: appConfig.IS_GOOGLE_SIGNIN_ENABLED,
        text: signupInfo.googleButtonText,
        icon: GoogleIcon,
        iconName: "google logo",
        handleButtonAction: this.handleOAuthGoogleLogin,
      },
      {
        enabled: true,
        text: signupInfo.githubButtonText,
        icon: GithubIcon,
        iconName: "github logo",
        handleButtonAction: this.handleOAuthGithubLogin,
      },
    ];

    return { inputFields, checkboxFields, primaryButtons, secondaryButtons };
  }

  render() {
    let details = this.getSignupFormDetails();
    let { signupComplete, errorMessage, failureArea } = this.state;

    return (
      <div className="sign-up-outer-container v-w-full">
        {!signupComplete ? (
          <div className="sign-up-container v-w-70">
            <div className="vulavula-card-heading">{signupInfo.title}</div>

            {errorMessage && failureArea === ACTION_AREA.SIGNUP && (
              <div className="vulavula-paragraph error-message-color">
                {errorMessage}
              </div>
            )}

            <div className="sign-up-content">
              <CustomForm
                inputFields={details.inputFields}
                checkboxFields={details.checkboxFields}
                primaryButtons={details.primaryButtons}
                secondaryButtons={details.secondaryButtons}
              />

              {/* BUTTOM SUBTEXT */}
              <div className="subtext-container">
                <div className="vulavula-paragraph">
                  {signupInfo.subText}{" "}
                  <a href={SIGNIN} style={{ color: "#3966D7" }}>
                    {signupInfo.navigationText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="signup-complete-container">
            <img
              src={LelalapaTree1}
              alt="lelapa tree"
              width="250px"
              height="250px"
            />
            <div className="complete-content">
              <div className="vulavula-card-heading">
                {signupInfo.signupCompleteHeading}
              </div>
              <div className="vulavula-paragraph-xl">
                {signupInfo.signupCompleteBody}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SignUp.propTypes = {
  globalState: PropTypes.object,
  dispatch: PropTypes.func,
  navigate: PropTypes.func,
};

export default SignUp;
