import React, { Component } from "react";
import "./CustomCard.css";
import "../../styles/type-scale-system.css";
import ProtoTypes from "prop-types";
import { ReactComponent as ChipDot } from "../../icons/chip-dot.svg";

class CustomCard extends Component {
  constructor(props) {
    super(props);

    let { cardDetails, cardSize, index } = this.props;
    this.state = {
      cardDetails: cardDetails,
      cardSize: cardSize,
      index: index,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cardDetails !== this.props.cardDetails) {
      this.setState({ cardDetails: this.props.cardDetails });
    }

    if (prevProps.cardSize !== this.props.cardSize) {
      this.setState({ cardSize: this.props.cardSize });
    }

    if (prevProps.index !== this.props.index) {
      this.setState({ index: this.props.index });
    }
  }

  render() {
    let { cardDetails, cardSize, index } = this.state;

    return (
      <div
        className={`custom-card-container ${cardSize}-card`}
        key={index}
        style={cardDetails.cardStyle}
        onClick={cardDetails.cardAction ? cardDetails.cardAction : () => {}}
      >
        {/* IMAGE AND TAG */}
        {(cardDetails.image || cardDetails.tag) && (
          <div
            className="card-pattern-container"
            style={{
              justifyContent:
                cardDetails.image && cardDetails.tag
                  ? "space-between"
                  : cardDetails.image.position,
            }}
          >
            {cardDetails?.image?.url && (
              <div className="pattern-text-container">
                <img
                  src={cardDetails.image.url}
                  alt="card pattern"
                  className="custom-card-pattern"
                  style={cardDetails.image.imageStyle}
                />

                {cardDetails.image.text && (
                  <div
                    className="vulavula-paragraph"
                    style={cardDetails.image.textStyle}
                    dangerouslySetInnerHTML={{ __html: cardDetails.image.text }}
                  />
                )}
              </div>
            )}

            {cardDetails?.tag?.title && (
              <div className="tag-container">
                <div
                  className="chip"
                  style={{
                    background: cardDetails.tag.lightColor,
                    borderColor: cardDetails.tag.darkColor,
                  }}
                >
                  <div>
                    <ChipDot fill={cardDetails.tag.darkColor} />
                  </div>
                  <div
                    className="vulavula-chip-text"
                    style={{ color: cardDetails.tag.darkColor }}
                  >
                    {cardDetails.tag.title}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* TITLES */}
        {(cardDetails.title || cardDetails.subtitle) && (
          <div className="card-title-container">
            {/* TITLE */}
            <div className="card-title-content" style={cardDetails.title.style}>
              {cardDetails.title.text}
            </div>

            {/* SUBTITLE */}
            <div
              className="vulavula-paragraph"
              style={cardDetails.subtitle.style}
            >
              {cardDetails.subtitle.text}
            </div>
          </div>
        )}

        {/* BODY */}
        {cardDetails.body && (
          <div className="body-content-container">
            {cardDetails.body.title && (
              <div
                className="body-title-content"
                style={cardDetails.body.bodyStyle}
                dangerouslySetInnerHTML={{ __html: cardDetails.body.title }}
              />
            )}

            {cardDetails.body.text && (
              <div
                className="vulavula-paragraph card-content"
                style={cardDetails.body.textStyle}
                dangerouslySetInnerHTML={{ __html: cardDetails.body.text }}
              />
            )}
          </div>
        )}

        {/* ACTION BUTTONS */}
        {(cardDetails.primaryButton || cardDetails.secondaryButton) && (
          <div className="action-buttons-container">
            {cardDetails?.primaryButton && (
              <div
                className={`${cardDetails.primaryButton.buttonStyle}`}
                onClick={cardDetails?.primaryButton?.action}
              >
                <div className={`${cardDetails.primaryButton.buttonTextStyle}`}>
                  {cardDetails.primaryButton.buttonText}
                </div>
              </div>
            )}

            {cardDetails.secondaryButton && (
              <div
                className={`${cardDetails.secondaryButton.buttonStyle}`}
                onClick={cardDetails.secondaryButton.action}
              >
                <div
                  className={`${cardDetails.secondaryButton.buttonTextStyle}`}
                  dangerouslySetInnerHTML={{
                    __html: cardDetails.secondaryButton.buttonText,
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

CustomCard.protoType = {
  cardDetails: ProtoTypes.object,
  cardSize: ProtoTypes.number,
  index: ProtoTypes.number,
};

export default CustomCard;
