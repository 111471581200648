import React, { Component } from "react";
import "./Tile.css";
import "../../styles/type-scale-system.css";
import PropTypes from "prop-types";
import { getProductTermsConsent } from "../../helpers/consents";

class Tile extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      show: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({
        show: this.props.showModal,
      });
    }
  }

  handleShow() {
    this.setState((prevState) => {
      return {
        show: !prevState.show,
      };
    });
  }

  handleClick(indicator, navigation) {
    if (indicator === "toggle-modal") {
      let hasAcceptedTerms = getProductTermsConsent(navigation);

      if (!hasAcceptedTerms) {
        this.props.dispatch({
          type: "UPDATE_SHOW_TERMS_MODAL",
          payload: { show: !this.state.show, navigation: navigation },
        });
      } else {
        this.props.navigate(navigation);
      }

      this.handleShow();
    }
  }

  render() {
    let { items = [] } = this.props || {};

    return (
      <div className="tile-container">
        {items.length > 0
          ? items.map((cardInfo) => {
              return (
                <div
                  className="card-container"
                  id={cardInfo.id.toString()}
                  key={cardInfo.id}
                >
                  {cardInfo.icon ? (
                    <div className="icon-container">
                      <img
                        src={cardInfo.icon}
                        alt={cardInfo.title}
                        width="80px"
                        height="60px"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="content-container">
                    <div className="tile-card-title">{cardInfo.title}</div>
                    <div
                      className="vulavula-paragraph"
                      style={{
                        textAlign: "right",
                        cursor: "default",
                        height: "120px",
                      }}
                      dangerouslySetInnerHTML={{ __html: cardInfo.subtitle }}
                    />
                    {cardInfo.buttonText ? (
                      <button
                        className="button-primary-red-larger barlow-20-light"
                        style={
                          !cardInfo.enabled
                            ? { background: "#B0B0B0", cursor: "default" }
                            : {}
                        }
                        onClick={() =>
                          this.handleClick(
                            cardInfo.buttonAction,
                            cardInfo.navigation
                          )
                        }
                      >
                        {cardInfo.buttonText}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    );
  }
}

Tile.propTypes = {
  items: PropTypes.array,
  dispatch: PropTypes.func,
  showModal: PropTypes.bool,
  navigate: PropTypes.func,
};

export default Tile;
