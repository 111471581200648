export var appConfig = {
  VULAVULA_SERVICES_URL: process.env.REACT_APP_VULAVULA_SERVICES_URL,
  VULAVULA_PLAYGROUND_TOKEN_API_ENDPOINT: "login/playground-access-token",
  VULAVULA_PLAYGROUND_API_KEY:
    process.env.REACT_APP_VULAVULA_PLAYGROUND_API_KEY,
  VULAVULA_UPLOAD_FILE_API: "transport/file-upload",
  VULAVULA_CLIENT_TOKEN_CREATE_API: "token/create",
  CREATE_CLIENT_TOKEN: true,
  VULAVULA_SERVICES_WEBSOCKET_URL:
    process.env.REACT_APP_VULAVULA_SERVICES_WEBSOCKET_URL,
  VULAVULA_TRANSCRIBE_WEBSOCKET_ENDPOINT: "feedback",
  VULAVULA_RATING_API_ENDPOINT: "playground/rating/submit",
  VULAVULA_TRANSCRIBE_API_BASE_ENDPOINT: "transcribe",
  VULAVULA_TRANSCRIBE_SYNC_ENDPOINT: "/sync",
  VULAVULA_TRANSCRIBE_SUBMIT_CORRECTION_API_ENDPOINT: "correction",
  VULAVULA_CLIENT_TOKEN_API: "token",
  MAX_API_KEYS: 3,
  API_MAX_FREE_CALLS: 100,
  SYSTEM_USER_ID: 4,
  VULAVULA_AUTH_API_BASE_ENDPOINT: "auth",
  VULAVULA_ENTITY_RECOGNITION_API_BASE_ENDPOINT: "entity_recognition",
  VULAVULA_SENTIMENT_ANALYSIS_API_BASE_ENDPOINT: "sentiment_analysis",
  VULAVULA_PROCESS_ENDPOINT: "process",
  VULAVULA_SOCIAL_LOGIN_CALLBACK: "callback",
  VULAVULA_WEB_BASE_URL: process.env.REACT_APP_VULAVULA_WEB_BASE_URL,
  VULAVULA_KYC_GET_API_URL: "kyc/get",
  VULAVULA_KYC_CREATE_API_URL: "kyc/create",
  VULAVULA_TRANSCRIBE_TIMEOUT: 30000, // milliseconds, 30s
  VULAVULA_TERMS_COOKIE_EXPIRY: 365, // days
  VULAVULA_BILLING_PLANS_API_URL: "billing/plans",
  VULAVULA_BILLING_CUSTOMER_LATEST_INVOICE_API_URL:
    "billing/customer/latest-invoice",
  VULAVULA_BILLING_CUSTOMER_INVOICES_API_URL: "billing/customer/invoices",
  VULAVULA_BILLING_CUSTOMER_INVOICE_CSV_DATA_API_URL:
    "billing/customer/invoice_csv_data",
  VULAVULA_BILLING_CUSTOMER_PAYMENT_METHODS_API_URL:
    "billing/customer/payment-methods",
  VULAVULA_BILLING_CUSTOMER_SUBSCRIPTIONS_API_URL:
    "billing/customer/subscriptions",
  VULAVULA_BILLING_ENABLE_API_URL: "billing/enable-billing",
  IS_GOOGLE_SIGNIN_ENABLED: process.env.REACT_APP_IS_GOOGLE_SIGNIN_ENABLED === "true"
};
