import React, { Component } from "react";
import "./Card.css";
import "../../styles/type-scale-system.css";
import PropTypes from "prop-types";
import ApiKeyCardPattern from "../../icons/api-key-card-pattern.svg";
import { ReactComponent as ErrorIcon } from "../../icons/error-icon.svg";

class Card extends Component {
  constructor(props) {
    super(props);

    let { cardDetails, isLoggedIn, hasHistory } = this.props;
    this.state = {
      cardDetails: cardDetails,
      isLoggedIn: isLoggedIn,
      hasHistory: hasHistory,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cardDetails !== this.props.cardDetails) {
      this.setState({ cardDetails: this.props.cardDetails });
    }

    if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
      this.setState({ isLoggedIn: this.props.isLoggedIn });
    }

    if (prevProps.hasHistory !== this.props.hasHistory) {
      this.setState({ hasHistory: this.props.hasHistory });
    }
  }

  render() {
    let { cardDetails, isLoggedIn, hasHistory } = this.state;

    return (
      <div className="big-card-outer-container">
        <div className="big-card-container">
          <div className="big-pattern-container">
            <img
              className="key-card-pattern"
              src={ApiKeyCardPattern}
              alt="card pattern"
            />
          </div>
          <div className="big-content-container">
            <div
              className="vulavula-card-heading"
              style={
                this.props.isMobile
                  ? { textAlign: "center" }
                  : { textAlign: "right" }
              }
            >
              {cardDetails.heading}
            </div>

            <div
              className="vulavula-paragraph"
              style={
                this.props.isMobile
                  ? { textAlign: "center" }
                  : { textAlign: "right" }
              }
              dangerouslySetInnerHTML={{
                __html: cardDetails.body,
              }}
            />

            {cardDetails.warningChip !== null ? (
              // WARNING CHIP
              <div className="max-key-warn-container">
                <div
                  className="vulauvula-chip-text warning-text"
                  dangerouslySetInnerHTML={{
                    __html: cardDetails.warningChip,
                  }}
                />

                <ErrorIcon fill="#BB6D02" />
              </div>
            ) : (
              <div
                className="input-content-outer-container"
                style={
                  isLoggedIn && !this.props.isMobile ? { width: "100%" } : {}
                }
              >
                {/* COPY TEXT */}
                <div
                  id="copied-text"
                  className="hide-copy-text"
                  style={{ color: "#6D6D6D" }}
                >
                  {cardDetails.copiedText}
                </div>

                {/* INPUT AND ACTION BUTTON */}
                <div
                  className="key-input-container"
                  style={
                    isLoggedIn && !this.props.isMobile
                      ? { flexDirection: "initial" }
                      : { width: "auto" }
                  }
                >
                  {isLoggedIn ? (
                    <div
                      className="text-main-container"
                      style={{ display: "flex", width: "inherit" }}
                      onClick={() => this.props.copyAction()}
                    >
                      <input
                        className="api-key-display"
                        type="text"
                        placeholder={
                          cardDetails.newlyGeneratedKey !== ""
                            ? cardDetails.newlyGeneratedKey
                            : cardDetails.apiKeyPlaceholder
                        }
                        disabled={true}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className={
                      hasHistory
                        ? "button-primary-white-large"
                        : "action-button"
                    }
                    style={
                      isLoggedIn && !hasHistory && !this.props.isMobile
                        ? { width: "45%" }
                        : {}
                    }
                  >
                    <div
                      className="barlow-20-light"
                      style={hasHistory ? { color: "#000" } : {}}
                      onClick={() => this.props.buttonAction()}
                    >
                      {cardDetails.buttonText}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  cardDetails: PropTypes.any,
  hasHistory: PropTypes.bool,
  buttonAction: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  copyAction: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default Card;
