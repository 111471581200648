import React, { Component, createRef } from "react";
import "./Uploader.css";
import "../../styles/type-scale-system.css";
import {
  transcribePageDetails,
  fileUploadErrorMessages,
} from "../../cms/generalCMS";
import UploadIcon from "../../icons/upload-icon.svg";
import RuleSettingsIcon from "../../icons/rule-settings.svg";
import ProgressBar from "../progressBar/ProgressBar";
import PropTypes from "prop-types";
import { handleErrorMessage } from "../../helpers/api/apiHelper";
import { ACTION_AREA } from "../../utils/constants";

class Uploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: this.props.uploading,
      complete: this.props.uploadComplete,
      droppedFile: null,
      errorMessage: this.props.errorMsg,
    };

    this.inputFileRef = createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uploading !== this.props.uploading) {
      this.setState({
        uploading: this.props.uploading,
      });
    }

    if (prevProps.uploadComplete !== this.props.uploadComplete) {
      this.setState({
        uploadComplete: this.props.uploadComplete,
      });
    }

    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }

  handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
      handleErrorMessage("", 0, this.props.dispatch, ACTION_AREA.TRANSCRIBE);
    }
  };

  handleDragLeave = (event) => {
    event.preventDefault();
  };

  handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // Fetch the files
    const droppedFiles = Array.from(event.dataTransfer.files);

    if (droppedFiles.length > 1) {
      this.setState({
        errorMessage: fileUploadErrorMessages.fileLimitMesssage,
      });
      handleErrorMessage(
        this.state.errorMessage,
        0,
        this.props.dispatch,
        ACTION_AREA.TRANSCRIBE
      );
      return;
    }

    const audioExtentions = transcribePageDetails.acceptedAudioExtentions;
    let supportedFormats = [];

    audioExtentions.forEach((extention) => {
      const vals = extention.split(".");

      supportedFormats.push("audio/".concat(vals[1].toLowerCase()));
    });

    const file = droppedFiles[0];

    if (supportedFormats.indexOf(file.type) === -1) {
      this.setState({
        errorMessage: fileUploadErrorMessages.unsupportedExtentionMessage,
      });
      handleErrorMessage(
        this.state.errorMessage,
        0,
        this.props.dispatch,
        ACTION_AREA.TRANSCRIBE
      );
      return;
    }

    // Begin Reading File
    const reader = new FileReader();

    reader.onload = (e) => this.setPreview(e.target.result);
    reader.onerror = () => {
      console.error("There was an issue reading the file.");
    };
    reader.readAsDataURL(file);

    // SEND FILE TO THE BACK END
    this.setState({ droppedFile: file });
    this.props.handleFileStateUpdate(file);
  };

  setPreview = (val) => {
    this.setState({ preview: val });
  };

  handleDragOver = (event) => {
    event.preventDefault();
  };

  handleManualUpload = () => {
    if (this.inputFileRef.current) {
      // Use click method on the input file element to trigger file selector
      this.inputFileRef.current.click();
    }
  };

  handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      this.setState({
        droppedFile: selectedFile,
      });
      this.props.handleFileStateUpdate(selectedFile);
    }
  };

  render() {
    let { errorMessage, uploading, complete } = this.state;
    return (
      <div className="control-recording-container">
        {/* drop zone */}
        {!uploading && !complete ? (
          <div
            className="drop-zone"
            onDragEnter={this.handleDragEnter}
            onDragLeave={this.handleDragLeave}
            onDrop={this.handleDrop}
            onDragOver={this.handleDragOver}
            style={errorMessage !== "" ? { borderColor: "#CC4A2B" } : {}}
          >
            <div className="vulavula-paragraph" style={{ textAlign: "center" }}>
              {transcribePageDetails.dragDropText}
            </div>
            <div className="subtext-container">
              {transcribePageDetails.acceptableText}{" "}
              {transcribePageDetails.acceptedAudioExtentions.join(", ")}
            </div>
          </div>
        ) : (
          <div className="uploading-loader">
            <ProgressBar
              processProgress={this.props.processProgress}
              handleUploadProgressBar={this.props.handleUploadProgressBar}
              handleUploadComplete={this.props.handleUploadComplete}
            />
          </div>
        )}

        {/* ERROR MESSAGE DIV */}
        {errorMessage !== "" ? (
          <div className="vulavula-paragraph error-message-container">
            {errorMessage}
          </div>
        ) : (
          ""
        )}
        {/* upload button */}
        {!uploading && !complete ? (
          <div
            className="button-primary-red-larger"
            onClick={this.handleManualUpload}
          >
            <img src={UploadIcon} alt="button-icon" />
            <div className="record-button-text">
              {transcribePageDetails.uploadButtonText}
            </div>
          </div>
        ) : (
          <div className="button-primary-white-no-border-default ">
            <div className="upload-button-text">
              {!this.state.complete
                ? transcribePageDetails.uploadingText
                : transcribePageDetails.uploadCompleteText}
            </div>
            {!this.state.complete ? (
              <img src={RuleSettingsIcon} alt="button-icon" />
            ) : (
              ""
            )}
          </div>
        )}
        {/* Hidden file input */}
        <input
          type="file"
          accept={["audio/mp3", "audio/wav"]}
          style={{ display: "none" }}
          ref={this.inputFileRef}
          onChange={this.handleFileChange}
        />
      </div>
    );
  }
}

Uploader.propTypes = {
  uploading: PropTypes.bool,
  uploadComplete: PropTypes.bool,
  processProgress: PropTypes.number,
  handleFileStateUpdate: PropTypes.func,
  handleUploadProgressBar: PropTypes.func,
  handleUploadComplete: PropTypes.func,
  errorMsg: PropTypes.string,
  dispatch: PropTypes.func,
};

export default Uploader;
