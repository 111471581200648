import React, { Component, useEffect } from "react";
import "../../styles/type-scale-system.css";
import "./BillingPage.css";
import ProtoType from "prop-types";
import { BillingPageInfo } from "../../cms/generalCMS";
import CustomCard from "../../components/customCard/CustomCard";
import Pattern1 from "../../icons/pattern-1.svg";
import GreyAddIcon from "../../icons/grey-add-icon.svg";
import { ReactComponent as ChipDot } from "../../icons/chip-dot.svg";
import { ReactComponent as ChevronRightIcon } from "../../icons/chevron-right-icon.svg";
import DownloadIcon from "../../icons/download-icon.svg";
import Dropdown from "../../components/dropdown/Dropdown";
import GreyRingPlanetIcon from "../../icons/grey-ring-planet-icon.svg";
import {
  BILLING_INVOICE_STATUS,
  TABLE_MAX_ITEMS,
  MONTHS_SHORT_NAMES,
  SORT_DIRECTION,
  BILLING_PROCESS,
  BILLING,
  STRIPE_PLANS_PAGE,
} from "../../utils/constants";
import { chunkArray, createInvoicesCSV } from "../../helpers/utils";
import {
  deleteCustomerPaymentMethod,
  getCustomerInvoicesCSVData,
  upsertPaymentMethod,
} from "../../helpers/api/billing";
import { HOME } from "../../utils/constants";
import { getCookie } from "../../helpers/cookie";
import { getCurrencySymbol, getMinimumPlanAmount } from "../../helpers/utils";
import StripeIcon from "../../icons/stripe-icon.svg";
import CustomModal from "../../components/customModal/CustomModal";

class BillingPage extends Component {
  constructor(props) {
    super(props);

    const {
      currentUserInvoice,
      userInvoices,
      userPaymentMethods,
      userSubscriptions,
      sessionToken,
      productPlans,
    } = this.props.globalState;
    this.state = {
      sessionToken: sessionToken,
      currentPage: 0,
      currentBillData: currentUserInvoice.data,
      userInvoices: userInvoices.data,
      userPaymentMethods: userPaymentMethods.data,
      userSubscriptions: userSubscriptions.data,
      sortDirection: SORT_DIRECTION.ASCENDING,
      defaultCurrency: productPlans.data?.currency,
      minimumAmount: productPlans.data ? productPlans.data.minimum_price : 0,
      showModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState !== this.props.globalState) {
      this.setState({
        sessionToken: this.props.globalState.sessionToken,
        currentBillData: this.props.globalState.currentUserInvoice.data,
        userInvoices: this.props.globalState.userInvoices.data,
        userPaymentMethods: this.props.globalState.userPaymentMethods.data,
        userSubscriptions: this.props.globalState.userSubscriptions.data,
        defaultCurrency: this.props.globalState.productPlans.data?.currency,
        minimumAmount: this.props.globalState.productPlans.data
          ? this.props.globalState.productPlans.data.minimum_price
          : 0,
      });
    }
  }

  handleAddBankingCard = () => {
    this.props.dispatch({
      type: "UPDATE_ADDING_PAYMENT_METHOD",
      payload: true,
    });
    this.props.navigate(BILLING_PROCESS);
  };

  handleDeleteBankingCard = async (id) => {
    const { userPaymentMethods, sessionToken, userSubscriptions } = this.state;
    if (userSubscriptions.length >= 1) {
      // need to show a modal that they cannot delete the card without adding a new card
      this.setState({ showModal: true });
      return;
    }

    let updatedPaymentMethods = userPaymentMethods.filter(
      (paymentMethod) => paymentMethod.id !== id
    );
    this.setState({ userPaymentMethods: updatedPaymentMethods });

    await deleteCustomerPaymentMethod(
      sessionToken,
      id,
      this.props.dispatch
    ).then((resp) => {
      if (!resp.error) {
        this.props.dispatch({
          type: "UPDATE_USER_PAYMENT_METHODS",
          payload: { data: [], error: false, fetched: false },
        });
        this.props.dispatch({
          type: "UPDATE_CURRENT_USER_INVOICE",
          payload: { data: null, error: false, fetched: false },
        });
      }
    });
  };

  handleSetPrimaryBankingCard = async (id) => {
    console.log("setting primary key");
    const { sessionToken } = this.state;
    const data = {
      id: id,
      default: true,
      card_number: "",
      expiry_month: "",
      expiry_year: "",
      cvv: "",
    };

    await upsertPaymentMethod(sessionToken, data, this.props.dispatch).then(
      (resp) => {
        if (!resp.error) {
          this.props.dispatch({
            type: "UPDATE_USER_PAYMENT_METHODS",
            payload: { data: [], error: false, fetched: false },
          });
          this.props.dispatch({
            type: "UPDATE_CURRENT_USER_INVOICE",
            payload: { data: null, error: false, fetched: false },
          });
        }
      }
    );
  };

  handleGetCurrentInvoice = () => {
    const { currentBillData } = this.state;
    if (!currentBillData) {
      return null;
    }

    const dueDate = new Date(currentBillData?.due_date * 1000); // Multiply by 1000 to convert from seconds to milliseconds

    let cardDetails = {
      body: {
        title: `<div class='card-heading-2'>${parseFloat(
          currentBillData?.amount_due / 100
        ).toFixed(
          2
        )}</div> <div class='card-option-text'>${currentBillData?.currency?.toUpperCase()}</div>`,
        text: `<b>${
          BillingPageInfo.CurrentBillSection.cardDueTitle
        }:   ${dueDate.getDate()} ${
          MONTHS_SHORT_NAMES[dueDate.getMonth()]
        } ${dueDate.getFullYear()} </b>`,
        bodyStyle: {
          alignItems: "flex-end",
          width: "auto",
          alignSelf: "flex-end",
        },
        textStyle: {
          height: "auto",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        },
      },
      cardStyle: {
        boxShadow: "2px 2px 2px 0 rgba(101, 101, 101, 0.4)",
        borderRadius: "16px",
      },
    };

    return cardDetails;
  };

  getBillingCardDetails = () => {
    const { userSubscriptions, defaultCurrency } = this.state;
    const { productPlans } = this.props.globalState;
    const currencySymbol = getCurrencySymbol(defaultCurrency);
    const planMinimumAmount = productPlans.data?.products
      ? getMinimumPlanAmount(productPlans.data?.products)
      : 0; // requested change for now, change to use minimumAmount in state

    let cards = [];
    BillingPageInfo.BillingSection.cards.map((card, i) => {
      let isAPICard = card.title.includes("API");
      return cards.push({
        image: isAPICard
          ? {
              url: Pattern1,
              position: "flex-start",
              imageStyle: {
                display: "flex",
                width: "48px",
                height: "41px",
              },
            }
          : {},
        tag: isAPICard
          ? {
              title:
                userSubscriptions?.length > 0
                  ? card.tagActiveTitle
                  : card.tagTitle,
              lightColor: "#fef8d9",
              darkColor: "#bb6d02",
            }
          : {},
        title: {
          text: card.title,
          style: {
            color: isAPICard ? "#d96136" : "#3a2730",
          },
        },
        subtitle: {
          text: card.subtitle,
          style: {},
        },
        body: {
          title: isAPICard
            ? `<div class='card-heading-2'>${currencySymbol}${planMinimumAmount}</div> ${card.body.title}`
            : card.body.title,
          text: card.body.text,
          bodyStyle: isAPICard ? { color: "#d96136" } : {},
          textStyle: { height: "150px" },
        },
        primaryButton: card.primaryButton && {
          buttonText: card.primaryButton.buttonText,
          buttonTextStyle: card.primaryButton.buttonTextStyle,
          buttonStyle: card.primaryButton.buttonStyle,
          action:
            i === 1
              ? () => this.props.navigate(STRIPE_PLANS_PAGE)
              : undefined,
        },
        secondaryButton: card.secondaryButton && {
          buttonText: card.secondaryButton.buttonText,
          buttonTextStyle: card.secondaryButton.buttonTextStyle,
          buttonStyle: card.secondaryButton.buttonStyle,
        },
        cardStyle: {
          boxShadow: isAPICard
            ? "0 10px 22px 0 rgba(0, 0, 0, 0.1), 0 39px 39px 0 rgba(0, 0, 0, 0.09), 0 89px 53px 0 rgba(0, 0, 0, 0.05), 0 80px 63px 0 rgba(0, 0, 0, 0.01)"
            : "2px 2px 2px 0 rgba(101, 101, 101, 0.4)",
          borderRadius: "16px",
          border: isAPICard ? "solid 4px #d96136" : "",
          backgroundImage:
            "linear-gradient(157deg, #fff 62%, #eaab87 141%), linear-gradient(to bottom, #fff, #fff)",
        },
      });
    });

    return cards;
  };

  getPaymentCardDetails = () => {
    const { userPaymentMethods } = this.state;
    let cardsDetails = [];
    userPaymentMethods.sort((a, b) => {
      return a.default === b.default ? 0 : a.default ? -1 : 1;
    });

    userPaymentMethods.map((paymentMethodCard) => {
      let cardDetails = {
        image: {
          url: StripeIcon,
          position: "flex-start",
          imageStyle: {
            display: "flex",
            alignItems: "flex-start",
            width: "56px",
            height: "45px",
            paddingRight: "16px",
          },
          text: `<b>${
            paymentMethodCard.brand[0].toUpperCase() +
            paymentMethodCard.brand.slice(1)
          } ending in ${paymentMethodCard.last4} </b> <br> Expires ${
            paymentMethodCard.exp_month
          }/${paymentMethodCard.exp_year}`,
          textStyle: { color: "#6d6d6d" },
          containerStyle: { alignItems: "center" },
        },
        tag: paymentMethodCard.default && {
          title: BillingPageInfo.PaymentMethodSection.primaryText,
          lightColor: "#fef8d9",
          darkColor: "#bb6d02",
        },
        primaryButton: {
          buttonText: BillingPageInfo.PaymentMethodSection.removeButtonText,
          buttonTextStyle: "vulavula-button-small primary-500",
          buttonStyle: "button-primary-white-no-border-default height-auto",
          action: () => this.handleDeleteBankingCard(paymentMethodCard.id),
        },
        secondaryButton: !paymentMethodCard.default && {
          buttonText: BillingPageInfo.PaymentMethodSection.setPrimaryButtonText,
          buttonTextStyle: "vulavula-button-small primary-500 underline-text",
          buttonStyle: "button-primary-white-no-border-default height-auto",
          action: () => this.handleSetPrimaryBankingCard(paymentMethodCard.id),
        },
        cardStyle: {
          boxShadow: "2px 2px 2px 0 rgba(101, 101, 101, 0.4)",
          borderRadius: "16px",
          padding: "21px 25px",
          gap: "16px",
        },
      };

      return cardsDetails.push(cardDetails);
    });

    return cardsDetails;
  };

  getPaymentMethodsCards = () => {
    let cards = [];

    const bankCards = this.getPaymentCardDetails();
    if (bankCards.length > 0) {
      cards.push(...bankCards);
    }

    let cardEmptyCard = {
      image: {
        url: GreyAddIcon,
        position: "center",
        imageStyle: {
          display: "flex",
          alignItems: "center",
          width: "24px",
          height: "24px",
        },
        text: BillingPageInfo.PaymentMethodSection.emptyPaymentMethodText,
        textStyle: { color: "#6d6d6d" },
        containerStyle: { alignItems: "center" },
      },
      cardStyle: {
        backgroundColor: "#f6f6f6",
        borderRadius: "16px",
        border: "1px dashed #6d6d6d",
        cursor: "pointer",
        padding: "50px 20px",
      },
      cardAction: this.handleAddBankingCard,
    };
    cards.push(cardEmptyCard);

    return cards;
  };

  handleSelectedFilter = (selectedFilter) => {
    // TODO: enrich once we start getting data from the backend
  };

  getChipDetails(invoice_status) {
    switch (invoice_status) {
      case BILLING_INVOICE_STATUS.PAID:
        return {
          lightColor: "#FAFDE8",
          darkColor: "#BDDB3D",
          tagText: BILLING_INVOICE_STATUS.PAID,
        };
      case BILLING_INVOICE_STATUS.OVER_DUE:
        return {
          lightColor: "#FFF2ED",
          darkColor: "#D96136",
          tagText: BILLING_INVOICE_STATUS.OVER_DUE,
        };
      case BILLING_INVOICE_STATUS.OPEN:
        return {
          lightColor: "#fef8d9",
          darkColor: "#bb6d02",
          tagText: BILLING_INVOICE_STATUS.OPEN,
        };
      default:
        return {
          lightColor: "#fef8d9",
          darkColor: "#bb6d02",
        };
    }
  }

  getCurrentPagetDetails(userInvoices) {
    if (userInvoices?.length === 0) {
      return;
    }
    const { currentPage } = this.state;

    let pages = chunkArray(userInvoices, TABLE_MAX_ITEMS);

    let currentPageData = pages[currentPage];
    let itemsCount = currentPageData?.length;
    let pagesCount = pages?.length;

    return {
      currentPageData,
      itemsCount,
      pagesCount,
    };
  }

  handleSortHistory = () => {
    this.setState((prevState) => {
      const { sortDirection, userInvoices } = prevState;
      let sortedInvoices;

      switch (sortDirection) {
        case SORT_DIRECTION.ASCENDING:
          sortedInvoices = userInvoices.sort((a, b) => {
            return (
              new Date(a.created_on * 1000) - new Date(b.created_on * 1000)
            );
          });
          return {
            userInvoices: sortedInvoices,
            sortDirection: SORT_DIRECTION.DESCENDING,
          };
        default:
          sortedInvoices = userInvoices.sort((a, b) => {
            return (
              new Date(b.created_on * 1000) - new Date(a.created_on * 1000)
            );
          });
          return {
            userInvoices: sortedInvoices,
            sortDirection: SORT_DIRECTION.ASCENDING,
          };
      }
    });
  };

  getDesktopHistoryTable = (pageData) => {
    return (
      <div className="table-container">
        {/* TABLE COLUMN */}
        <div
          className="table-header-container"
          style={{
            justifyContent: "space-between",
          }}
        >
          {BillingPageInfo.HistorySection.tableColumns.map((column, index) => {
            return (
              <div
                key={index}
                className="column-name-container table-header-cell"
                style={{
                  textAlign: "inherit",
                }}
              >
                <div className="text-regular-semi-bold">{column.name}</div>
                {column.icon && (
                  <img
                    src={column.icon}
                    alt=""
                    onClick={this.handleSortHistory}
                  />
                )}
              </div>
            );
          })}
        </div>

        {/* TABLE DATA */}
        <table
          className="inner-table-container"
          style={{ borderRadius: "0", overflow: "auto", border: "none" }}
        >
          <tbody className="table-content-container">
            {pageData?.currentPageData?.map((data, index) => {
              let status = data.status;
              // if status is open and the due day has passed make status overdue
              if (status === BILLING_INVOICE_STATUS.OPEN) {
                let dueDate = new Date(data.due_date * 1000);
                let currentDate = new Date();
                if (dueDate < currentDate) {
                  status = BILLING_INVOICE_STATUS.OVER_DUE;
                }
              }

              let chipDetails = this.getChipDetails(status);
              let parsedCreatedOnDate = new Date(data.created_on * 1000);

              return (
                <tr
                  className="table-row-container"
                  key={index}
                  style={{
                    backgroundColor: index % 2 !== 0 ? "#F6F6F6" : "#fff",
                  }}
                >
                  {/* DATE */}
                  <td className="vulavula-paragraph table-row-cell">
                    {`${
                      MONTHS_SHORT_NAMES[parsedCreatedOnDate.getMonth()]
                    } ${parsedCreatedOnDate.getDate()}, ${parsedCreatedOnDate.getFullYear()}`}
                  </td>

                  {/* BILL ID */}
                  <td
                    className="vulavula-paragraph table-row-cell"
                    width="100px"
                  >
                    {data.id}
                  </td>

                  {/* COST */}
                  <td className="vulavula-paragraph table-row-cell">{`${parseFloat(
                    data.amount / 100
                  ).toFixed(2)} ${data.currency.toUpperCase()}`}</td>

                  {/* STATUS */}
                  <td className="table-row-cell">
                    <div className="status-chip-container">
                      <div
                        className="chip"
                        key={index}
                        style={{
                          background: chipDetails.lightColor,
                          borderColor: chipDetails.darkColor,
                          cursor: "default",
                        }}
                      >
                        <ChipDot fill={chipDetails.darkColor} />
                        <div
                          className="vulavula-chip-text"
                          style={{ color: chipDetails.darkColor }}
                        >
                          {chipDetails.tagText.charAt(0).toUpperCase() +
                            chipDetails.tagText.slice(1)}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  renderPageNumbers(totalPages) {
    let { currentPage } = this.state;
    let pages = new Array(totalPages).fill("");

    return pages?.map((_, index) => (
      <span
        key={index}
        className={
          currentPage === index ? "page-number border-radius" : "page-number"
        }
        style={{ cursor: "pointer" }}
        onClick={() => this.setState({ currentPage: index })}
      >
        {index + 1}
      </span>
    ));
  }

  resetPageAndFilters = () => {
    let { sortDirection } = this.state;
    this.setState({ currentPage: 0 });

    if (sortDirection === SORT_DIRECTION.DESCENDING) {
      this.handleSortUserApiKeys();
    }
  };

  handleDownloadHistoryCSV = async () => {
    await getCustomerInvoicesCSVData(
      this.state.sessionToken,
      this.props.dispatch
    ).then((invoices) => {
      const csvContent = createInvoicesCSV(invoices);

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "billing_history.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    let billingCards = this.getBillingCardDetails();
    let paymentMethodCards = this.getPaymentMethodsCards();
    let currentBillData = this.handleGetCurrentInvoice();
    let { currentPage, userInvoices, showModal } = this.state;
    let pageInfo = this.getCurrentPagetDetails(userInvoices);
    const { navigate, dispatch } = this.props;

    return (
      <div className="billing-page-container">
        {/* BILLING SECTION */}
        <div className="billing-section-container">
          {/* TITLES */}
          <div className="billing-title-container">
            <div className="vulavula-heading-3">
              {BillingPageInfo.BillingSection.title}
            </div>
            <div className="vulavula-paragraph">
              {BillingPageInfo.BillingSection.subtitle}
            </div>
          </div>

          {/* CARDS */}
          <div className="card-set-container">
            {billingCards.map((card, index) => {
              return (
                <CustomCard
                  key={index}
                  cardDetails={card}
                  cardSize="medium"
                  index={index}
                />
              );
            })}
          </div>
        </div>

        {/* CURRENT BILL */}
        {currentBillData && (
          <div className="current-bill-container">
            {/* TITLES */}
            <div className="billing-title-container">
              <div className="vulavula-heading-3">
                {BillingPageInfo.CurrentBillSection.title}
              </div>
              <div className="vulavula-paragraph">
                {BillingPageInfo.CurrentBillSection.subtitle}
              </div>
            </div>

            <CustomCard
              cardDetails={currentBillData}
              cardSize="small-long"
              index={0}
            />
          </div>
        )}
      </div>
    );
  }
}

BillingPage.protoType = {
  navigate: ProtoType.func,
  dispatch: ProtoType.func,
  globalState: ProtoType.object,
};

const BillingPageWithEffect = (props) => {
  const nav = props.navigate;
  const { enableBilling } = props.globalState;
  useEffect(() => {
    // check if use if logged in and if not navigate to home
    const sessionToken = getCookie("sessionToken");
    if (!enableBilling.enable || !sessionToken) {
      nav(HOME);
    }
  }, [nav, enableBilling.enable]);

  return <BillingPage {...props} />;
};

export default BillingPageWithEffect;
