import React, { Component, useEffect } from "react";
import "./ApiDashboard.css";
import "../../styles/type-scale-system.css";
import { apiKeyDashboardInfo } from "../../cms/generalCMS";
import { ACTION_AREA, HOME } from "../../utils/constants";
import { appConfig } from "../../config";
import AddIcon from "../../icons/add-icon.svg";
import SearchIcon from "../../icons/search-icon.svg";
import FilterIcon from "../../icons/filter-icon.svg";
import LinkIcon from "../../icons/link-icon.svg";
import WhiteDeleteIcon from "../../icons/white-delete-icon.svg";
import { ReactComponent as ErrorIcon } from "../../icons/error-icon.svg";
import {
  API_KEYS_TABLE_MAX_ITEMS,
  API_KEY_STATUS,
  MONTHS_SHORT_NAMES,
  SORT_DIRECTION,
} from "../../utils/constants";
import { chunkArray } from "../../helpers/utils";
import { ReactComponent as ChipDot } from "../../icons/chip-dot.svg";
import { ReactComponent as ChevronRightIcon } from "../../icons/chevron-right-icon.svg";
import {
  handleCopyValue
} from "../../helpers/utils";
import PropTypes from "prop-types";
import { getCookie } from "../../helpers/cookie"
import { deleteAPIKey, createClientToken } from "../../helpers/api/auth"
import {Link} from "react-router-dom";

class ApiDashboard extends Component {
  constructor(props) {
    super(props);

    const { userApiKeys, errorDetails, isMobile } = this.props.globalState;

    this.state = {
      errMsg: "",
      userApiKeys: userApiKeys.data,
      currentPage: 0,
      sortDirection: SORT_DIRECTION.ASCENDING,
      hoverCopyRowIndex: null,
      hoverDeleteRowIndex: null,
      errorMessage: errorDetails.errorMessage,
      failureArea: errorDetails.failureArea,
      hideFilters: true, // TODO: Remove when we have idea of how the filters should look like
      isMobile: isMobile,
    };

    const storedToken = getCookie("sessionToken");
    this.sessionToken = storedToken;

    this.handleSortUserApiKeys = this.handleSortUserApiKeys.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState !== this.props.globalState) {
      const { userApiKeys, errorDetails, isMobile } = this.props.globalState;
      this.setState({
        userApiKeys: userApiKeys.data,
        errorMessage: errorDetails.errorMessage,
        failureArea: errorDetails.failureArea,
        isMobile: isMobile,
      });
    }
  }

  async handleGenerateApiKey() {
    const { userApiKeys, sortDirection } = this.state;

    await createClientToken(this.sessionToken, this.props.dispatch, false).then(
      (resp) => {
        if (resp !== "") {
          let updatedUserKeys;
          switch (sortDirection) {
            case SORT_DIRECTION.ASCENDING:
              updatedUserKeys = [resp, ...userApiKeys];
              break;

            default:
              updatedUserKeys = [...userApiKeys, resp];
              break;
          }

          this.setState({ userApiKeys: updatedUserKeys });
          this.props.dispatch({
            type: "UPDATE_USER_API_KEYS",
            payload: { data: updatedUserKeys, fetched: true },
          });
        }
      }
    );
  }

  handleSearch() {
    // TODO: enrich, once acceptance criteria is known
  }

  getCurrentPagetDetails(userApiKeys) {
    if (userApiKeys?.length === 0) {
      return;
    }
    const { currentPage } = this.state;

    let pages = chunkArray(userApiKeys, API_KEYS_TABLE_MAX_ITEMS);

    let currentPageData = pages[currentPage];
    let itemsCount = currentPageData?.length;
    let pagesCount = pages?.length;

    return {
      currentPageData,
      itemsCount,
      pagesCount,
    };
  }

  getChipDetails(expiration_time) {
    let expired = Date.parse(expiration_time) < Date.now();

    if (expired) {
      return {
        lightColor: "#FFF2ED",
        darkColor: "#D96136",
        tagText: API_KEY_STATUS.EXPIRED,
      };
    } else {
      return {
        lightColor: "#FAFDE8",
        darkColor: "#BDDB3D",
        tagText: API_KEY_STATUS.ACTIVE,
      };
    }
  }

  resetPageAndFilters() {
    let { sortDirection } = this.state;
    this.setState({ currentPage: 0 });

    if (sortDirection === SORT_DIRECTION.DESCENDING) {
      this.handleSortUserApiKeys();
    }
  }

  renderPageNumbers(totalPages) {
    let { currentPage } = this.state;
    let pages = new Array(totalPages).fill("");

    return pages?.map((_, index) => (
      <span
        key={index}
        className={
          currentPage === index ? "page-number border-radius" : "page-number"
        }
        style={{ cursor: "pointer" }}
        onClick={() => this.setState({ currentPage: index })}
      >
        {index + 1}
      </span>
    ));
  }

  handleSortUserApiKeys() {
    this.setState((prevState) => {
      const { userApiKeys, sortDirection } = prevState;
      let sortedApiKeys;

      switch (sortDirection) {
        case SORT_DIRECTION.ASCENDING:
          sortedApiKeys = [...userApiKeys].sort((a, b) => {
            return (
              Date.parse(a.expiration_time) - Date.parse(b.expiration_time)
            );
          });
          return {
            sortDirection: SORT_DIRECTION.DESCENDING,
            userApiKeys: sortedApiKeys,
          };

        default:
          sortedApiKeys = [...userApiKeys].sort((a, b) => {
            return (
              Date.parse(b.expiration_time) - Date.parse(a.expiration_time)
            );
          });
          return {
            sortDirection: SORT_DIRECTION.ASCENDING,
            userApiKeys: sortedApiKeys,
          };
      }
    });
  }

  handleCopyToken = (token) => {
    handleCopyValue(token);
    this.setState({ hoverCopyRowIndex: null });
  };

  handleCopyRowHoverIndexUpdate = (index) => {
    this.setState({ hoverCopyRowIndex: index });
  };

  handleDeleteRowHoverIndexUpdate = (index) => {
    this.setState({ hoverDeleteRowIndex: index });
  };

  async handleDeleteAPIKey(apiId) {
    let { userApiKeys } = this.state;

    await deleteAPIKey(this.sessionToken, apiId, this.props.dispatch).then(
      (resp) => {
        if (resp) {
          let updatedKeys;

          updatedKeys = userApiKeys.filter((a) => a.id !== apiId);
          this.setState({ userApiKeys: updatedKeys });
          this.props.dispatch({
            type: "UPDATE_USER_API_KEYS",
            payload: { data: updatedKeys, fetched: true },
          });
        }
      }
    );
  }

  getDesktopAPITable() {
    const { userApiKeys, hoverCopyRowIndex, hoverDeleteRowIndex } = this.state;
    const pageInfo = this.getCurrentPagetDetails(userApiKeys);

    return (
      <div className="table-container">
        {/* TABLE COLUMN */}
        <div className="table-header-container">
          {apiKeyDashboardInfo.populatedStateInfo.tableColumns.map(
            (colDetails, index) => {
              return (
                <div
                  className="column-name-container"
                  key={index}
                  style={{
                    textAlign: "inherit",
                    width:
                      index === 0
                        ? "79%"
                        : index ===
                          apiKeyDashboardInfo.populatedStateInfo.tableColumns
                            .length -
                            1
                        ? "10%"
                        : index ===
                          apiKeyDashboardInfo.populatedStateInfo.tableColumns
                            .length -
                            2
                        ? "23%"
                        : index ===
                          apiKeyDashboardInfo.populatedStateInfo.tableColumns
                            .length -
                            3
                        ? "15%"
                        : "",
                  }}
                >
                  <div className="text-regular-semi-bold">
                    {colDetails.name}
                  </div>
                  {colDetails.icon && (
                    <img
                      src={colDetails.icon}
                      alt=""
                      onClick={this.handleSortUserApiKeys}
                    />
                  )}
                </div>
              );
            }
          )}
        </div>

        {/* TABLE DATA */}
        <table className="inner-table-container">
          <tbody className="table-content-container">
            {pageInfo?.currentPageData?.map((data, i) => {
              let chipDetails = this.getChipDetails(data.expiration_time);
              let parsedExpiryDate = new Date(Date.parse(data.expiration_time));

              let parsedCreatedOnDate = data.created_on
                ? new Date(Date.parse(data.created_on))
                : new Date();

              return (
                <tr
                  key={i}
                  style={{
                    backgroundColor: i % 2 !== 0 ? "#F6F6F6" : "",
                  }}
                >
                  {/* TOKEN */}
                  <td className="half-width">
                    <div className="token-container">
                      <div className="vulavula-paragraph token">
                        {data.token}
                      </div>
                      <div
                        className="api-white-button"
                        style={
                          hoverCopyRowIndex === i
                            ? {
                                borderBottom: "2px solid #cc4a2b",
                                transition:
                                  "border-botton-color 0.3s color 0.3s",
                              }
                            : {}
                        }
                        onClick={() => this.handleCopyToken(data.token)}
                        onMouseEnter={() =>
                          this.handleCopyRowHoverIndexUpdate(i)
                        }
                        onMouseLeave={() =>
                          this.handleCopyRowHoverIndexUpdate(null)
                        }
                      >
                        <div
                          className="small-button-text"
                          style={
                            hoverCopyRowIndex === i
                              ? {
                                  color: "#cc4a2b",
                                }
                              : {}
                          }
                          onMouseEnter={() =>
                            this.handleCopyRowHoverIndexUpdate(i)
                          }
                          onMouseLeave={() =>
                            this.handleCopyRowHoverIndexUpdate(null)
                          }
                        >
                          {apiKeyDashboardInfo.populatedStateInfo.copyText}
                        </div>
                        <img src={LinkIcon} alt="link icon" />
                      </div>
                    </div>
                  </td>

                  {/* STATUS */}
                  <td className="ten-percent-width">
                    <div className="status-chip-container">
                      <div
                        className="chip"
                        key={i}
                        style={{
                          background: chipDetails.lightColor,
                          borderColor: chipDetails.darkColor,
                          cursor: "default",
                        }}
                      >
                        <ChipDot fill={chipDetails.darkColor} />
                        <div
                          className="vulavula-chip-text"
                          style={{ color: chipDetails.darkColor }}
                        >
                          {chipDetails.tagText}
                        </div>
                      </div>
                    </div>
                  </td>

                  {/* CREATED ON */}
                  <td className="vulavula-paragraph">
                    {`${
                      MONTHS_SHORT_NAMES[parsedCreatedOnDate.getMonth()]
                    } ${parsedCreatedOnDate.getDate()}, ${parsedCreatedOnDate.getFullYear()}`}
                  </td>

                  {/* EXPIRES ON */}
                  <td className="vulavula-paragraph">
                    {`${
                      MONTHS_SHORT_NAMES[parsedExpiryDate.getMonth()]
                    } ${parsedExpiryDate.getDate()}, ${parsedExpiryDate.getFullYear()}`}
                  </td>

                  {/* DELETE BUTTON */}
                  <td>
                    <div
                      className="api-white-button"
                      style={
                        hoverDeleteRowIndex === i
                          ? {
                              borderBottom: "2px solid #cc4a2b",
                              transition: "border-botton-color 0.3s color 0.3s",
                            }
                          : {}
                      }
                      onClick={() => this.handleDeleteAPIKey(data.id)}
                      onMouseEnter={() =>
                        this.handleDeleteRowHoverIndexUpdate(i)
                      }
                      onMouseLeave={() =>
                        this.handleDeleteRowHoverIndexUpdate(null)
                      }
                    >
                      <div
                        className="small-button-text"
                        style={
                          hoverDeleteRowIndex === i
                            ? {
                                color: "#cc4a2b",
                                transition:
                                  "border-botton-color 0.3s color 0.3s",
                              }
                            : {}
                        }
                        onMouseEnter={() =>
                          this.handleDeleteRowHoverIndexUpdate(i)
                        }
                        onMouseLeave={() =>
                          this.handleDeleteRowHoverIndexUpdate(null)
                        }
                      >
                        {apiKeyDashboardInfo.populatedStateInfo.deleteText}
                      </div>
                      <img src={WhiteDeleteIcon} alt="delete api icon" />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  getMobileAPITable() {
    const { userApiKeys } = this.state;
    const pageInfo = this.getCurrentPagetDetails(userApiKeys);

    return (
      <div className="mobile-table-container">
        <div className="mobile-outer-table-container">
          {pageInfo?.currentPageData?.map((data, i) => {
            let chipDetails = this.getChipDetails(data.expiration_time);
            let parsedExpiryDate = new Date(Date.parse(data.expiration_time));

            let parsedCreatedOnDate = data.created_on
              ? new Date(Date.parse(data.created_on))
              : new Date();
            const columnsListLen =
              apiKeyDashboardInfo.populatedStateInfo.tableColumns.length;

            return (
              <div key={i} className="mobile-table-inner-container">
                {/* TABLE CONTENT */}
                {apiKeyDashboardInfo.populatedStateInfo.tableColumns.map(
                  (colDetails, index) => {
                    if (colDetails.name) {
                      return (
                        <div key={index} className="full-width">
                          {/* COLUMN NAME */}
                          <div className="mobile-column-name-container">
                            <div className="mobile-inner-column-name-container">
                              <div className="text-regular-semi-bold">
                                {colDetails.name}
                              </div>

                              {colDetails.icon && (
                                <img
                                  src={colDetails.icon}
                                  alt="col icon"
                                  onClick={this.handleSortUserApiKeys}
                                />
                              )}
                            </div>
                          </div>

                          {/* DATA COLUMN */}
                          <div
                            className="mobile-data-column-container"
                            style={{
                              backgroundColor: "#F6F6F6",
                              borderRadius: `${
                                index === columnsListLen - 1 ? "16px" : "0"
                              }`,
                            }}
                          >
                            {index === 0 ? (
                              // TOKEN
                              <div className="vulavula-paragraph mobile-token">
                                {data.token}
                              </div>
                            ) : index === 1 ? (
                              // STATUS
                              <div
                                className="chip"
                                key={i}
                                style={{
                                  background: chipDetails.lightColor,
                                  borderColor: chipDetails.darkColor,
                                  cursor: "default",
                                }}
                              >
                                <ChipDot fill={chipDetails.darkColor} />
                                <div
                                  className="vulavula-chip-text"
                                  style={{ color: chipDetails.darkColor }}
                                >
                                  {chipDetails.tagText}
                                </div>
                              </div>
                            ) : index === 2 ? (
                              // CREATED ON
                              <div className="vulavula-paragraph">
                                {`${
                                  MONTHS_SHORT_NAMES[
                                    parsedCreatedOnDate.getMonth()
                                  ]
                                } ${parsedCreatedOnDate.getDate()}, ${parsedCreatedOnDate.getFullYear()}`}
                              </div>
                            ) : index === 3 ? (
                              // EXPIRY DATE
                              <div className="vulavula-paragraph">
                                {`${
                                  MONTHS_SHORT_NAMES[
                                    parsedExpiryDate.getMonth()
                                  ]
                                } ${parsedExpiryDate.getDate()}, ${parsedExpiryDate.getFullYear()}`}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    }

                    return <></>;
                  }
                )}

                {/* ACTION BUTTON */}
                <div className="mobile-buttons-container">
                  {/* COPY */}
                  <div
                    className="mobile-api-button"
                    onClick={() => this.handleCopyToken(data.token)}
                  >
                    <div className="small-button-text">
                      {apiKeyDashboardInfo.populatedStateInfo.copyText}
                    </div>
                    <img src={LinkIcon} alt="link icon" />
                  </div>

                  {/* DELETE */}
                  <div
                    className="mobile-api-button"
                    onClick={() => this.handleDeleteAPIKey(data.id)}
                  >
                    <div className="small-button-text">
                      {apiKeyDashboardInfo.populatedStateInfo.deleteText}
                    </div>
                    <img src={WhiteDeleteIcon} alt="delete api icon" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    let {
      userApiKeys,
      currentPage,
      errorMessage,
      failureArea,
      hideFilters,
      isMobile,
    } = this.state;
    const pageInfo = this.getCurrentPagetDetails(userApiKeys);

    const { freePlanUsedUpInfo } = apiKeyDashboardInfo.apiKeyStatuses;
      const [freePlanUsedUpInfoBeforeLink, freePlanUsedUpInfoAfterLink] = freePlanUsedUpInfo.split("<a href='/billing'>");
    return (
      <div className={`dashboard-container ${!isMobile && "page-background"}`}>
        {/* TITLE */}
        {!isMobile && (
          <div
            className={
              isMobile ? "vulavula-card-heading" : "vulavula-heading-1"
            }
          >
            {apiKeyDashboardInfo.title}
          </div>
        )}

        <div className="dashboard-content">
          {/* CARDS */}
          <div className="dashboard-cards">
            {apiKeyDashboardInfo.dashboardCards.map((card, i) => {
              return (
                <div className="dashboard-card" key={i}>
                  <img src={card.icon} alt={`dashboard card ${i}`} />
                  <div
                    className="vulavula-paragraph"
                    dangerouslySetInnerHTML={{ __html: card.subtitle }}
                  />
                </div>
              );
            })}
          </div>

          {/* API KEYS LIST */}
          {pageInfo?.currentPageData?.length > 0 ? (
            <div className="default-container">
              {/* TITLES */}
              <div className="top-container">
                {/* TITLES */}
                <div className="titles-container">
                  <div className="vulavula-card-heading">
                    {apiKeyDashboardInfo.populatedStateInfo.title}
                  </div>
                  {
                    userApiKeys?.length >= appConfig.MAX_API_KEYS ?
                        (
                       <div className="vulavula-paragraph">
                          {apiKeyDashboardInfo.populatedStateInfo.subtitle}
                      </div>
                        ) :
                        (
                            <div className={"vulavula-paragraph bg-lelapa-yellow-600 v-p-3 v-rounded-md"}>
                              {
                                apiKeyDashboardInfo.apiKeyStatuses.freePlanInfo
                              }
                            </div>
                        )
                  }

                </div>

                {userApiKeys?.length >= appConfig.MAX_API_KEYS ? (
                    // WARNING CHIP
                    <div className={"v-d-flex v-flex-column"}>
                      <div className="max-key-warn-container">
                        <div
                          className="vulauvula-chip-text warning-text"
                          dangerouslySetInnerHTML={{
                            __html: apiKeyDashboardInfo.maxKeyWarning,
                          }}
                        />

                        <ErrorIcon fill="#BB6D02" />
                      </div>

                      <div className={"vulavula-paragraph bg-lelapa-yellow-600 v-mt-2 v-p-3 v-rounded-md"}>
                             {freePlanUsedUpInfoBeforeLink}
                              <Link to="/billing" style={{ color: 'inherit' }}>
                                {freePlanUsedUpInfoAfterLink.replace('</a>', '')}
                              </Link>
                      </div>
                    </div>
                ) : (
                    <div
                    className={
                      isMobile ? "mobile-top-container" : "top-container"
                    }
                  >
                    {/* API KEY COUNT */}
                    <div>
                      <div className="vulavula-paragraph-bold">{`${userApiKeys?.length}/${appConfig.MAX_API_KEYS} ${apiKeyDashboardInfo.populatedStateInfo.keyCountText}`}</div>
                    </div>

                    {/* GENERATE BUTTON */}
                    <div
                      className={`button-primary-red-larger ${
                        isMobile && "full-width"
                      }`}
                      style={{ height: "auto" }}
                      onClick={() => this.handleGenerateApiKey()}
                    >
                      <img src={AddIcon} alt="add icon" />
                      <div className="button-text">
                        {apiKeyDashboardInfo.generateButtontext}
                      </div>
                    </div>
                  </div>
                )}

                {errorMessage && failureArea === ACTION_AREA.DASHBOARD && (
                  <div className="vulavula-paragraph error-message-color">
                    {errorMessage}
                  </div>
                )}
              </div>

              {/* FILTERS */}
              {!isMobile && (
                <div className="filter-outer-container">
                  <div className="filter-inner-container">
                    {/* SEARCH FILTER */}
                    {!hideFilters && (
                      <div className="search-container">
                        <img src={SearchIcon} alt="search icon" />
                        <input
                          className="vulavula-paragraph search-input"
                          name="search"
                          placeholder="Search"
                          style={{ border: "none" }}
                          onInput={this.handleSearch}
                        />
                      </div>
                    )}

                    {/* FILTER BUTTON */}
                    {!hideFilters && (
                      <div className="button-primary-white-large">
                        <img src={FilterIcon} alt="filter icon" />
                        <div className="filter-button-text">
                          {apiKeyDashboardInfo.filterButtonText}
                        </div>
                      </div>
                    )}

                    {/* PAGE COUNT */}
                    {!isMobile && (
                      <div
                        className="count-container"
                        style={
                          hideFilters ? { width: "98%" } : { width: "43%" }
                        }
                      >
                        <div className="vulavula-paragraph">{`${
                          apiKeyDashboardInfo.showingText
                        } ${currentPage + 1}-${pageInfo?.pagesCount} of ${
                          pageInfo?.pagesCount
                        }`}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* KEYS TABLE */}
              {isMobile ? this.getMobileAPITable() : this.getDesktopAPITable()}

              {/* PAGE MANAGER */}
              <div className="page-manager-container">
                {/* RECENT BUTTON */}
                <div
                  className={
                    pageInfo.pagesCount > 1 && currentPage !== 0
                      ? "button-primary-white-large"
                      : "button-primary-white-no-border-large"
                  }
                  style={
                    pageInfo.pagesCount > 1 && currentPage !== 0
                      ? { cursor: "pointer" }
                      : { cursor: "default", height: "auto" }
                  }
                  onClick={
                    pageInfo.pagesCount > 1 && currentPage !== 0
                      ? () => this.resetPageAndFilters()
                      : undefined
                  }
                >
                  <div
                    className="small-button-text"
                    style={{
                      color:
                        pageInfo.pagesCount > 1 && currentPage !== 0
                          ? "#3A2730"
                          : "#D1D1D1",
                    }}
                  >
                    {apiKeyDashboardInfo.recentButtonText}
                  </div>
                </div>

                {/* PAGE NUMBERS */}
                <div className="page-numbers-container">
                  {this.renderPageNumbers(pageInfo?.pagesCount)}
                </div>

                {/* NEXT BUTTON */}
                <div
                  className={
                    pageInfo.pagesCount > 1 &&
                    currentPage !== pageInfo.pagesCount - 1
                      ? "button-primary-white-large"
                      : "api-white-button"
                  }
                  style={
                    pageInfo.pagesCount > 1 &&
                    currentPage !== pageInfo.pagesCount - 1
                      ? { cursor: "pointer" }
                      : { cursor: "default" }
                  }
                  onClick={
                    pageInfo.pagesCount > 1 &&
                    currentPage !== pageInfo.pagesCount - 1
                      ? () => this.setState({ currentPage: currentPage + 1 })
                      : undefined
                  }
                >
                  <div
                    className="small-button-text"
                    style={{
                      color:
                        pageInfo.pagesCount > 1 &&
                        currentPage !== pageInfo.pagesCount - 1
                          ? "#3A2730"
                          : "#D1D1D1",
                    }}
                  >
                    {apiKeyDashboardInfo.nextButtonText}
                  </div>

                  <ChevronRightIcon
                    stroke={
                      pageInfo.pagesCount > 1 &&
                      currentPage !== pageInfo.pagesCount - 1
                        ? "#3A2730"
                        : "#B0B0B0"
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="default-mobile-outer-container">
              {isMobile && (
                <div className="default-container">
                  {/* TITLES */}
                  <div className="top-container">
                    {/* TITLES */}
                    <div className="titles-container">
                      <div className="vulavula-card-heading">
                        {apiKeyDashboardInfo.populatedStateInfo.title}
                      </div>
                      <div className="vulavula-paragraph">
                        {apiKeyDashboardInfo.populatedStateInfo.subtitle}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className={
                  isMobile ? "border-default-container" : "default-container"
                }
              >
                <div className="titles-container">
                  <div className="vulavula-card-heading">
                    {apiKeyDashboardInfo.defaultStateInfo.title}
                  </div>
                  <div
                    className="vulavula-paragraph"
                    dangerouslySetInnerHTML={{
                      __html: apiKeyDashboardInfo.defaultStateInfo.subtitle,
                    }}
                  />
                </div>

                <img
                  src={apiKeyDashboardInfo.defaultStateInfo.icon}
                  alt="default state icon"
                />

                <div
                  className="button-primary-red-larger"
                  style={{ height: "auto" }}
                  onClick={() => this.handleGenerateApiKey()}
                >
                  <div className="button-text">
                    {apiKeyDashboardInfo.generateButtontext}
                  </div>
                </div>

                {errorMessage && failureArea === ACTION_AREA.DASHBOARD && (
                  <div className="vulavula-paragraph error-message-color">
                    {errorMessage}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ApiDashboard.propType = {
  globalState: PropTypes.object,
  navigate: PropTypes.func,
  dispatch: PropTypes.func,
};

const ApiDashboardWithEffect = (props) => {
  const nav = props.navigate;
  useEffect(() => {
    // Check if the user is logged in
    const sessionToken = getCookie("sessionToken");
    if (sessionToken === null || sessionToken === "") {
      nav(HOME);
    }
  }, [nav]);

  return <ApiDashboard {...props} />;
};

export default ApiDashboardWithEffect;
