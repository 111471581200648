import { appConfig } from "../../config";
import {
  ACTION_AREA
} from "../../utils/constants"
import { vulaApi, handleErrorMessage } from "./apiHelper";


export const triggerSentimentAnalysis = async (
  encodedText,
  config,
  dispatch
) => {
  try {
    const jsonData = JSON.stringify({
      encoded_text: encodedText,
    });

    const resp = await vulaApi.post(
      `${appConfig.VULAVULA_SENTIMENT_ANALYSIS_API_BASE_ENDPOINT}/${appConfig.VULAVULA_PROCESS_ENDPOINT}`,
      jsonData,
      config
    );

    if (resp.status !== 200) {
      handleErrorMessage("", 500, dispatch, ACTION_AREA.SENTIMENT_ANALYSIS);
      return null;
    }

    localStorage.setItem("upload_id", resp.data.id);

    return resp.data;
  } catch (err) {
    handleErrorMessage(
      err.response.data.detail,
      err.response.status,
      dispatch,
      ACTION_AREA.SENTIMENT_ANALYSIS
    );
    return null;
  }
};