import React, { Component } from "react";
import "./CustomTextAreaCard.css";
import "../../styles/type-scale-system.css";
import PropTypes from "prop-types";
import { ReactComponent as ChipDot } from "../../icons/chip-dot.svg";
import { ReactComponent as PositiveSentiment } from "../../icons/postive-sentiment.svg"
import { ReactComponent as NeutralSentiment } from "../../icons/neutral-sentiment.svg"
import { ReactComponent as NegativeSentiment } from "../../icons/negative-sentiment.svg"

import RatingModal from "../rating/RatingModal";
import { SENTIMENT, MIXED_SENTIMENT_TEXT, SENTIMENT_ANALYSIS } from "../../utils/constants";

class CustomTextAreaCard extends Component {
  constructor(props) {
    super(props);

    const { cardContent, globalState } = this.props;
    const { isMobile } = globalState;
    this.state = {
      processing: cardContent.processing,
      doneProcessing: cardContent.doneProcessing,
      isRating: cardContent.isRating,
      isMobile: isMobile,
    };
  }

  componentDidUpdate(prevProps) {
    const { cardContent } = this.props;

    if (prevProps.cardContent !== cardContent) {
      this.setState({
        processing: cardContent.processing,
        doneProcessing: cardContent.doneProcessing,
        isRating: cardContent.isRating,
      });
    }
  }

  handleSetProcessing() {
    this.setState({ processing: true });
    this.props.handleUpdateProcessing();
  }

  render() {
    let { cardContent } = this.props;
    const { processing, doneProcessing, isRating, isMobile } = this.state;

    return (
      <div className="text-card-inner-content">
        {/* CARD TITLE */}
        <div className={"vulavula-card-heading"}>{cardContent.title}</div>

        {window.location.pathname === SENTIMENT_ANALYSIS && (
          cardContent.resultChips.length > 1 && (cardContent.resultChips[0].entityName === SENTIMENT.POSITIVE || cardContent.resultChips[0].entityName === SENTIMENT.NEGATIVE || cardContent.resultChips[0].entityName === SENTIMENT.NEUTRAL) ? (
            <>
              <div
                className="chip"
                style={{
                  background: "#F6F6F6",
                  borderColor: "#4F4F4F",
                }}
              >
                <ChipDot fill="#4F4F4F" />
                <div
                  className="vulavula-chip-text"
                  style={{ color: "#4F4F4F" }}
                >
                  {MIXED_SENTIMENT_TEXT}
                </div>
              </div>

              <div className="text-card-chip-container">
                {cardContent.resultChips.map((details, i) => {
                  return (
                    <div
                      className="chip"
                      key={i}
                      style={{
                        background: details.colors.lightColor,
                        borderColor: details.colors.darkColor,
                      }}
                    >
                      <ChipDot fill={details.colors.darkColor} />
                      <div
                        className="vulavula-chip-text"
                        style={{ color: details.colors.darkColor }}
                      >
                        {`${details.entityName}`}
                      </div>
                      {
                        details.entityName === SENTIMENT.POSITIVE ? <PositiveSentiment /> : details.entityName === SENTIMENT.NEGATIVE ? <NegativeSentiment /> : details.entityName === SENTIMENT.NEUTRAL ? <NeutralSentiment /> : null
                      }
                    </div>
                  );
                })}
              </div>
            </>
          ) : cardContent.resultChips.length === 1 && (
            <div
              className="chip"
              style={{
                background: cardContent.resultChips[0].colors.lightColor,
                borderColor: cardContent.resultChips[0].colors.darkColor,
              }}
            >
              <ChipDot fill={cardContent.resultChips[0].colors.darkColor} />
              <div
                className="vulavula-chip-text"
                style={{ color: cardContent.resultChips[0].colors.darkColor }}
              >
                {cardContent.resultChips[0].entityName}
              </div>
              {
                cardContent.resultChips[0].entityName === SENTIMENT.POSITIVE ? <PositiveSentiment /> : cardContent.resultChips[0].entityName === SENTIMENT.NEGATIVE ? <NegativeSentiment /> : cardContent.resultChips[0].entityName === SENTIMENT.NEUTRAL ? <NeutralSentiment /> : null
              }
            </div>)
        )
        }
        {/* RESULTS CHIPS */}
        {window.location.pathname !== SENTIMENT_ANALYSIS && cardContent.resultChips.length > 0 && (
          <div className="text-card-chip-container">
            {cardContent.resultChips.map((details, i) => {
              return (
                <div
                  className="chip"
                  key={i}
                  style={{
                    background: details.colors.lightColor,
                    borderColor: details.colors.darkColor,
                  }}
                >
                  <ChipDot fill={details.colors.darkColor} />
                  <div
                    className="vulavula-chip-text"
                    style={{ color: details.colors.darkColor }}
                  >
                    {`${details.wordCount} x ${details.entityName}`}
                  </div>

                </div>
              );
            })}
          </div>
        )}

        {/* INPUT */}
        <div id="results-container" className="text-input-outer-container">
          {cardContent.encodedResults ? (
            <div
              contentEditable={false}
              className="vulavula-paragraph hyphenate text-input-inner-container"
              dangerouslySetInnerHTML={{
                __html: cardContent.encodedResults,
              }}
            />
          ) : (
            <textarea
              id="results"
              className="vulavula-paragraph hyphenate text-input-inner-container"
              rows={1}
              type="text"
              placeholder={cardContent.placeholderText}
              value={cardContent.entityInputValue}
              onChange={this.props.handleInputUpdate}
              spellCheck={false}
            />
          )}
        </div>

        {/* ACTION BUTTON */}
        {!processing && !doneProcessing ? (
          <div className="detect-container">
            <div
              className={
                cardContent.entityInputValue === ""
                  ? "button-primary-white-no-border-large"
                  : "button-primary-red-larger"
              }
              onClick={
                cardContent.entityInputValue !== ""
                  ? () => {
                    this.handleSetProcessing();
                    this.props.handleInputProcess();
                  }
                  : undefined
              }
            >
              <div
                className="button-text"
                style={
                  cardContent.entityInputValue === ""
                    ? { color: "#D1D1D1" }
                    : {}
                }
              >
                {cardContent.buttonText}
              </div>
            </div>
          </div>
        ) : (
          <div className="results-complete-buttons-container">
            {/* TRY AGAIN BUTTON */}
            <div
              className={`result-button ${isMobile && "full-width"}`}
              style={{
                background: "#CC4A2B",
                border: "1px solid #CC4A2B",
              }}
              onClick={this.props.handleClearStates}
            >
              <div className="result-button-text" style={{ color: "white" }}>
                {cardContent.tryAgainButtonText}
              </div>
            </div>

            {/* RATE US BUTTON */}
            <div
              className={`result-button ${isMobile && "full-width"}`}
              style={{
                color: "#3A2730",
                border: "1px solid #3a2730",
              }}
              onClick={this.props.setIsRating}
            >
              <div
                className="result-button-text"
                style={{
                  color: "#3A2730",
                }}
              >
                {cardContent.rateUsButtonText}
              </div>
            </div>
          </div>
        )}

        {/* RATING MODAL */}
        {isRating && (
          <RatingModal
            modalInfo={cardContent.modalInfo}
            handleClearStates={this.props.handleClearStates}
            dispatch={this.props.dispatch}
            globalState={this.props.globalState}
          />
        )}
      </div>
    );
  }
}

CustomTextAreaCard.propTypes = {
  dispatch: PropTypes.func,
  globalState: PropTypes.object,
  cardContent: PropTypes.object,
  handleInputUpdate: PropTypes.func,
  handleInputProcess: PropTypes.func,
  handleClearStates: PropTypes.func,
  handleUpdateProcessing: PropTypes.func,
  setIsRating: PropTypes.func,
};

export default CustomTextAreaCard;
