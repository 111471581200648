import React, { Component } from 'react';
import Proptypes from 'prop-types';
import "../../styles/type-scale-system.css";
import "./Paragraph.css";

class Paragraph extends Component {
    render() {
        let {text = "", styleClassName = ""} = this.props || {};
         
        return (
            <div className='paragraph-container'>
                {text !== "" ?
                    <div className={styleClassName.toString()} dangerouslySetInnerHTML={{__html: text}} />
                    : ""
                }
            </div>
        );
    }
}

Paragraph.propTypes = {
    text: Proptypes.string,
    styleClassName: Proptypes.string,
}

export default Paragraph;