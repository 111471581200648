import {FORGOT_PASSWORD, SIGNIN} from "../../utils/constants";
import {DataMissing} from "../common";
import {useNavigate} from "react-router-dom";
import {passwordResetMissingDetailsInfo} from "../../cms/generalCMS";

export const PasswordResetMissingDetails = ({message}) => {
    const navigate = useNavigate();

    const getMissingEmailDetails = () => {
        const missingMessage = message ? message : passwordResetMissingDetailsInfo.defaultMessage;
        const primaryButton = {
            label: passwordResetMissingDetailsInfo.primaryButtonText,
            onAction: () => navigate(FORGOT_PASSWORD),
            disabled: false
        };

        const secondaryButton = {
            label: passwordResetMissingDetailsInfo.secondaryButtonText,
            onAction: () => navigate(SIGNIN),
            disabled: false
        };

        return {primaryButton, secondaryButton, message: missingMessage}
    }

    const details = getMissingEmailDetails()

    return (
        <DataMissing
            message={details.message}
            primaryButton={details.primaryButton}
            secondaryButton={details.secondaryButton}
        />
    )
}